export const messages = {
    "cs": {
      "text-logo": "Logo",
      "text-heading": "Nadpis",
      "404-heading": "Chybový kód: {code}",
      "404-sub-heading": "Jejda! Nastala chyba nebo stránka neexistuje",
      "404-back-home": "Návrat na úvodní stránku",
      "nav-menu-category": "Kategorie",
      "nav-menu-offers": "Nabídky",
      "nav-menu-requests": "Poptávky",
      "nav-menu-auctions": "Aukce",
      "nav-menu-inspiration": "Inspirace",
      "nav-menu-news": "Přečtěte si",
      "nav-menu-my-favorites": "Moje oblíbené",
      "nav-menu-faq": "Nápověda",
      "nav-menu-contact": "Kontakt",
      "nav-home": "Úvod",
      "text-homepage": "Úvodní stránka",
      "text-all-ads": "Všechny inzeráty",
      "text-all-inspirations": "Všechny inspirace",
      "text-all-news": "Všechny články",
      "text-faq": "Nejčastější dotazy",
      "text-read-news": "Přečtěte si",
      "text-why-submit-ad": "Proč inzerovat u nás",
      "text-in-progress": "Připravuje se...",
      "text-contact": "Kontakt",
      "user-avatar": "uživatelský avatar",
      "text-edit-profile": "Upravit profil",
      "text-edit-main-image": "Upravit úvodní obrázek",
      "auth-menu-profile": "Upravit profil",
      "auth-menu-settings-notifications": "Notifikace",
      "auth-menu-my-orders": "Moje nákupy",
      "auth-menu-my-sales": "Moje prodeje",
      "auth-menu-waiting-orders": "Čekající objednávky",
      "auth-menu-my-ads": "Moje inzeráty",
      "auth-menu-logout": "Odhlásit se",
      "join-button": "Přihlásit",
      "text-log-in-to-show-page": "Přihlaste se pro zobrazení obsahu stránky",
      "change-locale": "Přepnout jazyk",
      "text-search": "Hledat",
      "common:text-search": "Hledat",
      "text-fish": "Ryby",
      "text-ads": "Inzeráty",
      "text-browse-ads": "Procházet inzeráty",
      "text-report-ad": "Nahlásit inzerát",
      "text-reporting-ad": "Nahlášení inzerátu",
      "text-reporting-details": "Důvod nahlášení",
      "text-report-inspiration": "Nahlásit inspiraci",
      "text-reporting-inspiration": "Nahlášení inspirace",
      "text-submit-report": "Odeslat",
      "text-my-ads": "Moje aktivní inzeráty",
      "text-my-offers": "Moje aktivní nabídky",
      "text-my-requests": "Moje aktivní poptávky",
      "text-my-auctions": "Moje aktivní aukce",
      "text-my-inspiration": "Moje inspirace",
      "text-my-favorites": "Moje oblíbené",
      "text-google-play": "Google Play",
      "text-apple-store": "Apple Store",
      "text-favorite": "Oblíbené",
      "text-favorite-ads": "Oblíbené inzeráty",
      "text-favorite-inspirations": "Oblíbené inspirace",
      "text-favorite-news": "Oblíbené články",
      "text-inspiration": "Inspirace",
      "text-latest-inspiration": "Nejnovější inspirace",
      "text-latest-inspiration-subtitle": "Pochlubte se svými fotkami a inspirujte ostatní!",
      "text-latest-news": "Přečtěte si",
      "text-latest-news-subtitle": "Zajímavosti ze světa akvaristiky a vodních živočichů",
      "text-latest-ads": "Nejnovější inzeráty",
      "text-latest-ads-subtitle": "Ponořte se do akvaristiky a podívejte se, co je u nás nového!",
      "text-latest-reference": "Zkušenosti našich uživatelů",
      "text-latest-reference-subtitle": "Co říkají o ForAquarist milovníci akvaristiky?",
      "text-recommend": "Nabídnout",
      "text-recommendation-message": "Možná vás bude zajímat inzerát:",
      "text-recommended-ads": "Mohlo by vás zajímat",
      "text-recommended-ads-subtitle": "Vybrali jsme nejzajímavější nabídky",
      "text-recommended-my-product": "Nabídněte svůj inzerát",
      "text-recommended-my-product-subtitle-1": "Jako odpověď na poptávku můžete nabídnout jeden ze svých inzerátů. Pokud žádný takový nemáte, vytvořte nový",
      "text-recommended-my-product-subtitle-2": "zde",
      "text-recommended-my-product-subtitle-3": "a pak odpovězte na poptávku.",
      "text-recommended-my-product-result-1": "K inzerátu poptávky",
      "text-recommended-my-product-result-2": "byl přidán komentář s vaší nabídkou",
      "text-show-message": "Zobrazit komentář",
      "text-mobile-app": "Mobilní aplikace",
      "text-mobile-app-title": "Vyzkoušejte aplikaci ForAquarist!",
      "text-mobile-app-subtitle": "Moderní tržiště pro prodej, nákup a inzerci akvaristiky vždy po ruce",
      "text-mobile-app-instructions": "Aplikaci ForAquarist si můžete stáhnout na těchto odkazech",
      "text-could-interest-you": "Mohlo by vás zajímat",
      "text-for-aquarist": "For Aquarist",
      "text-burger-menu": "Menu",
      "text-close": "Zavřít",
      "text-clear": "Odstranit",
      "text-or": "Nebo",
      "text-allow-geolocation-here": "Povolte sdílení vaší aktuální polohy zde",
      "text-allow-geolocation": "Pro zobrazení vzdálenosti prosím povolte sdílení vaší aktuální polohy",
      "text-gdpr": "GDPR",
      "text-privacy-policy": "zásadami ochrany osobních údajů",
      "text-login-auth-message": "Pro přidání do košíku se prosím přihlaste nebo",
      "text-login-auth-message-link-title": "zaregistrujte",
      "text-register-auth-message": "Zaregistrujte se, abyste mohli nakupovat, přidávat inzeráty a využívat spoustu dalších funkcí.",
      "text-register-succes-message": "Děkujeme za registraci.<br><br>Zkontrolujte si, prosím, e‑mailovou schránku, kam jsme Vám poslali e‑mail s aktivačním odkazem.",
      "text-add-supplier-info": "Přidat další kontaktní údaje",
      "text-offer": "Nabídka",
      "text-request": "Poptávka",
      "text-auction": "Aukce",
      "text-added-to-cart": "Přidáno do košíku",
      "text-in-cart": "V košíku",
      "text-minus": "mínus",
      "text-plus": "plus",
      "common:text-read-more": "Číst více",
      "text-show-more": "Zobrazit více",
      "text-show-all": "Zobrazit vše",
      "text-previous": "Předešlé",
      "text-next": "Další",
      "common:text-less": "Méně",
      "common:text-item": "položek,položka,položky",
      "text-item-header": "Položka",
      "text-quantity-header": "Počet",
      "text-price-header": "Cena",
      "text-add-ad": "Přidat inzerát",
      "text-add-ad-hint-title": "Pokud toho nabízíte více, vytvořte raději více inzerátů – lépe je pak najdou ostatní při vyhledávání podle kategorií. Uložený inzerát můžete nejen upravovat, ale také snadno kopírovat přes tlačítko v pravém horním rohu na detailu inzerátu.",
      "text-ad-name-placeholder": "Obvykle české nebo latinské jméno, případně název zboží",
      "text-ad-content-placeholder": "Popis nabízeného včetně informací o možné dopravě. Můžete uvést i doplňující informace k chovu, o nabízeném druhu apod.",
      "text-add-new-ad": "Vložit nový inzerát",
      "text-add-photo": "Přidat fotku",
      "text-add-inspiration": "Přidat inspiraci",
      "text-copy-ad": "Kopírovat inzerát",
      "text-ad-delete-title": "Opravdu smazat inzerát?",
      "text-want-to-buy": "Chci koupit",
      "text-to-offer": "Nabídnout",
      "text-cart": "Košík",
      "text-shopping-cart": "Nákupní košík",
      "text-add-to-favorites": "Přidat do Moje oblíbené",
      "text-remove-from-favorites": "Odebrat z Moje oblíbené",
      "text-pieces-available": "dostupných kusů,dostupný kus,dostupné kusy",
      "text-pieces-required": "požadovaných kusů,požadovaný kus,požadované kusy",
      "text-details": "Podrobnosti",
      "text-comments": "Komentáře",
      "text-order": "Objednávka",
      "text-order-detail": "Detail nákupu",
      "text-rating": "Hodnocení",
      "text-added-rating": "Vloženo hodnocení:",
      "text-order-buyer-rating": "Vaše hodnocení nákupu",
      "text-order-seller-rating": "Vaše hodnocení objednávky",
      "text-order-review": "Slovní hodnocení",
      "text-update-order-review": "Upravit hodnocení",
      "text-order-review-waiting": "Uživatel {name} již ohodnotil tuto objednávku. Ohodnoťte tuto objednávku a jeho hodnocení se zobrazí.",
      "text-phone": "Telefon",
      "text-cart-date": "Datum košíku",
      "text-order-date": "Datum obj.",
      "text-unfinished-cart": "Rozpracovaný košík",
      "text-unfinished-cart-details": "Detaily rozpracovaného košíku",
      "text-order-details": "Detaily objednávky",
      "text-location": "Lokalita",
      "text-distance": "Vzdálenost",
      "text-address": "Adresa",
      "text-insert-my-info": "Vložit mé údaje",
      "text-insert-billing-address": "Vložit fakturační adresu",
      "text-insert-delivery-address": "Vložit doručovací adresu",
      "text-insert-email-address": "Vložit e-mailovou adresu",
      "text-insert-phone-number": "Vložit telefonní číslo",
      "text-upload-highlight": "Nahrajte obrázky",
      "text-upload-message": "kliknutím nebo přetažením.",
      "text-img-format": "PNG, JPG",
      "text-delivery-address": "Doručovací adresa",
      "text-submit-order": "Odeslat objednávku",
      "text-submit-order-note-1": "Odeslání objednávky není závazné, ale je nezbytné, abyste se poté v chatu mohli s prodávajícím domluvit ohledně platby, doručení, apod.",
      "text-submit-order-note-2": "Pokud se nedohodnete, má možnost Vaši objednávku odmítnout.",
      "text-submit-order-note-3": "Objednávka je pro obě strany závazná až po přijetí objednávky prodávajícím.",
      "text-cancel-order": "Zrušit objednávku",
      "text-abandonment-reason": "Důvod zrušení",
      "placeholder-abandonment-note": "Budeme rádi, když nám napíšete důvod zrušení objednávky.",
      "text-shipping": "Doprava",
      "text-billing-data": "Fakturační údaje",
      "text-billing-address": "Fakturační adresa",
      "text-billing-and-delivery-address": "Fakturační a doručovací adresa",
      "text-payment-fee": "Poplatek za platbu",
      "text-delivery-fee": "Poplatek za doručení",
      "text-items-count": "Položek",
      "text-total-price": "Celková cena",
      "text-total": "Celkem",
      "text-out-stock": "Není skladem",
      "text-product-status-closed": "Inzerát je ukončený",
      "text-insert-image": "Vložit obrázek",
      "text-insert-profile-image": "Nahrát profilovou fotku",
      "text-product-unavailable-title": "Detail nelze zobrazit, inzerát není dostupný.",
      "text-product-unavailable-subtitle": "Inzerát byl buď smazán, skryt, zboží už není k dispozici, nebo je chyba v odkazu.",
      "text-product-image": "Obrázek",
      "text-product-image-edit": "Editace obrázku",
      "text-supplier-unavailable-title": "Detail prodejce nelze zobrazit.",
      "text-supplier-unavailable-subtitle": "Profil prodejce byl buď smazán, skryt, nebo je chyba v odkazu.",
      "text-supplier-image-edit": "Editace obrázku",
      "text-supplier-image-delete-title": "Opravdu smazat obrázek?",
      "text-inspiration-unavailable-title": "Detail nelze zobrazit, inspirace není dostupná.",
      "text-inspiration-unavailable-subtitle": "Inspirace byla buď smazána, skryta, nebo je chyba v odkazu.",
      "text-inspiration-edit": "Editace inspirace",
      "text-news-unavailable-title": "Detail nelze zobrazit, článek není dostupný.",
      "text-news-unavailable-subtitle": "Článek byl buď smazán, skryt, nebo je chyba v odkazu.",
      "text-reference-unavailable-title": "Detail nelze zobrazit, reference není dostupná.",
      "text-reference-unavailable-subtitle": "Reference byla buď smazána, skryta, nebo je chyba v odkazu.",
      "text-ad-edit": "Editace inzerátu",
      "text-load-more": "Načíst další",
      "text-availability": "Dostupnost",
      "text-category": "Kategorie",
      "text-category-subtitle": "Co hledáte?",
      "text-home": "Domů",
      "text-user": "Uživatel",
      "text-edit": "Upravit",
      "text-ad-title": "Název inzerátu",
      "text-title": "Název",
      "text-firstName": "Jméno",
      "text-lastName": "Příjmení",
      "text-namePrefix": "Titul",
      "text-nameSuffix": "Titul za jménem",
      "text-company": "Název firmy",
      "text-companyPerson": "Kontaktní osoba",
      "text-companyNo": "IČO",
      "text-companyNo-required": "IČO je vyžadováno",
      "text-companyVatNo": "DIČ",
      "text-street": "Ulice",
      "text-streetNo": "Číslo",
      "text-houseNo": "Č. domu",
      "text-city": "Město",
      "text-zip": "PSČ",
      "text-state": "Stát",
      "text-country": "Země",
      "text-load-from-ares": "Načíst údaje z rejstříku ARES",
      "text-add-company-info": "Zadat firemní údaje",
      "text-use-delivery-address": "Použít fakturační adresu jako doručovací",
      "text-gallery": "Galerie",
      "text-gallery-info-title": "Obrázky, které si nahrajete do Galerie, jsou zobrazeny v detailu vašeho profilu, kde si je mohou prohlídnout i ostatní uživatelé.",
      "text-add-new-photo": "Přidat novou inspiraci",
      "text-new-variant": "Nová varianta",
      "text-add-new-variant": "Přidat novou variantu",
      "text-add-copy-variant": "Přidat kopii varianty",
      "text-add-parameter": "Přidat parametr",
      "text-parameters": "Parametry",
      "text-edit-ad": "Upravit inzerát",
      "text-edit-photo": "Upravit inspiraci",
      "text-edit-variant": "Upravit variantu",
      "text-delete-variant": "Smazat variantu",
      "text-set-as-main-image": "Nastavit jako hlavní obrázek",
      "text-change-password": "Změnit heslo",
      "profile-sidebar-profile": "Profil",
      "profile-sidebar-settings-notifications": "Notifikace",
      "profile-sidebar-my-orders": "Moje nákupy",
      "profile-sidebar-my-sales": "Moje prodeje",
      "profile-sidebar-waiting-orders": "Čekající objednávky",
      "profile-sidebar-help": "Nápověda",
      "profile-sidebar-contact": "Napište nám",
      "text-contact-us": "Napište nám",
      "password-successful": "Heslo úspěšně změněno",
      "password-successful-please-login": "Heslo bylo úspěšně změněno, můžete se přihlásit",
      "error-credential-wrong": "Přihlašovací údaje jsou nesprávné!",
      "login-helper": "Přihlaste se pomocí e-mailu a hesla",
      "error-email-format": "Zadejte platnou emailovou adresu",
      "error-email-required": "Zadejte svoji e-mailovou adresu",
      "error-phone-required": "Zadejte své telefonní číslo",
      "error-phone-numeric": "Zadejte číselné telefonní číslo",
      "error-password-required": "Zadejte své heslo",
      "error-password-repeat-required": "Zopakujte své heslo",
      "error-subject-required": "Zadejte nadpis zprávy",
      "error-description-required": "Řekněte nám o tom více",
      "error-ad-type-required": "Typ inzerátu je vyžadován",
      "error-ad-name-required": "Název inzerátu je vyžadován",
      "error-ad-description-required": "Popis inzerátu je vyžadován",
      "error-ad-category-required": "Kategorie inzerátu je vyžadována",
      "error-ad-availability-required": "Dostupnost je vyžadována",
      "error-ad-price-required": "Cena je vyžadována",
      "error-stock-count-required": "Počet kusů je vyžadován",
      "error-in-stock-count-required": "Dostupnost je “{name}”, nastavte proto prosím počet kusů větší než 0.",
      "error-ad-vat-rate-required": "Sazba DPH je vyžadována",
      "error-ad-parameter-required": "Tento parametr je vyžadován",
      "error-content-image-required": "Obrázek je vyžadován",
      "error-content-name-required": "Název fotky je vyžadován",
      "text-in-progress-now": "Právě probíhá...",
      "text-activate-account": "Aktivace uživatelského účtu",
      "text-account-activated": "Uživatelský účet byl aktivován",
      "text-account-not-activated": "Uživatelský účet zatím nebyl aktivován.",
      "text-activate-account-sent": "Odkaz na aktivaci uživatelského účtu byl znovu zaslán na e-mail.",
      "text-send-activate-account": "Zaslat aktivační odkaz e-mailem",
      "text-delete-account-title": "Smazání uživatelského účtu",
      "text-confirm-delete-account": "To nás mrzí, opravdu si přejete smazat svůj uživatelský účet? Pokud ano, pošleme vám odkaz pro potvrzení smazání uživatelského účtu na do vaší e-mailové schránky.",
      "text-send-email-link": "Odeslat odkaz na e‑mail",
      "text-confirm-delete-account-title": "Potvrďte smazání uživatelského účtu",
      "text-confirm-delete-account-button": "Ano, chci smazat svůj uživatelský účet",
      "text-account-deleted": "Uživatelský účet byl smazán",
      "text-login": "Přihlásit se",
      "text-no-account": "Nemáte ještě uživatelský účet?",
      "text-already-account": "Již máte účet?",
      "text-register": "Registrovat",
      "text-register-here": "Zaregistrujte se zde",
      "text-old-password": "Staré heslo",
      "text-new-password": "Nové heslo",
      "text-confirm-password": "Potvrdit heslo",
      "error.old-password.invalid": "Staré heslo není platné",
      "error-old-password-required": "Staré heslo je povinné",
      "error-new-password-required": "Zadejte nové heslo",
      "error-confirm-password": "Prosím, potvrďte heslo",
      "error-match-passwords": "Hesla by měla být stejná",
      "error-name-required": "Zadejte své jméno",
      "error-surname-required": "Zadejte své příjmení",
      "forgot-password-email-helper": "Zadejte prosím váš přihlašovací e-mail. Pošleme vám na něj odkaz pro obnovu hesla.",
      "forgot-password-token-helper": "Zadejte heslo, které chcete použít pro přihlášení",
      "text-check-email-for-token": "Do schránky jsme vám odeslali e-mail s odkazem pro obnovení hesla, zkontrolujte prosím i složku Nevyžádáná pošta (spam).",
      "text-reset-password": "Obnovit heslo",
      "text-back": "Zpět",
      "text-back-to": "Zpět na",
      "text-forgot-password": "Zapomenuté heslo?",
      "text-follow-us": "Sledujte nás",
      "text-questions-comments": "Otázky, komentáře nebo obavy?",
      "text-questions-and-comments": "Otázky a komentáře",
      "text-name": "Jméno",
      "text-surname": "Příjmení",
      "text-password": "Heslo",
      "text-write-email": "Napsat email",
      "text-email": "E-mail",
      "text-subject": "Předmět",
      "text-description": "Popis",
      "text-about": "Informace o vás",
      "text-about-info": "Představte se ostatním, můžete napsat čemu se věnujete, na jaké druhy se zaměřujete, o co se zajímáte.",
      "text-delivery": "Informace o nabízené dopravě",
      "text-delivery-info": "Jakou formu předání nebo dopravy nabízíte? Např. osobní předání, Zásilkovna, apod.",
      "text-offered-delivery": "Nabízená doprava",
      "text-variant-content": "Popis",
      "text-variant-discount": "Popis slevy",
      "text-variant-package": "Popis balení",
      "text-use": "Použít",
      "text-save": "Uložit",
      "text-save-for-later": "Uložit na později",
      "text-save-and-publish": "Uložit a zveřejnit",
      "text-submit-email": "Odeslat e-mail",
      "text-loading": "Načítání...",
      "common:text-loading": "Načítání...",
      "text-signing-out": "Odhlášení...",
      "text-no-result-found": "Žádný výsledek nenalezen",
      "text-not-found": "Bohužel, aktuálně zde nic není",
      "text-no-option-found": "Možnost nenalezena",
      "text-add-option": "Přidat možnost",
      "text-no-filtered-found": "Pro zadaný filtr se nic nenašlo. Upravte filtr a zkuste to znovu.",
      "text-no-my-ads": "Nemáte ještě žádný vložený inzerát, ale můžete to jednoduše napravit.",
      "text-continue": "Pokračovat",
      "text-continue-to-homepage": "Pokračovat na úvodní stránku",
      "text-continue-in-mobile-app": "Pokračovat v mobilní aplikaci",
      "text-continue-shopping": "Pokračovat v nákupu",
      "text-proceed-order": "Dokončit objednávku",
      "text-unavailable": "Nedostupné",
      "text-do-not-remove": "Neodebrat",
      "text-remove-item": "Odebrat zboží",
      "text-remove-item-from-cart": "Odebrat \"{name}\" z košíku?",
      "text-cancel": "Zrušit",
      "text-create-copy": "Vytvořit kopii",
      "text-delete": "Smazat",
      "text-delete-account": "Smazat účet",
      "text-delete-avatar": "Smazat profilovou fotku",
      "text-filter": "Filtr",
      "text-clear-filters": "Zrušit filtr",
      "text-voice-search": "Hlasové vyhledávání",
      "text-sort-by": "Seřadit podle",
      "text-sort-by-newest": "Od nejnovějšího",
      "text-sort-by-oldest": "Od nejstaršího",
      "text-sort-by-cheapest": "Od nejlevnějšího",
      "text-sort-by-expensive": "Od nejdražšího",
      "text-sort-by-position": "Doporučeno",
      "text-your-order": "Vaše objednávka",
      "text-no-products": "V košíku zatím nic není",
      "text-search-label": "Vyhledávání...",
      "common:text-search-placeholder": "Napište, co hledáte",
      "common:text-search-ad-placeholder": "Napište, jaký inzerát hledáte",
      "error-country-required": "Země je vyžadována",
      "error-city-required": "Město je vyžadováno",
      "error-valid-city-required": "Město musí být jiné než Heslo",
      "error-zip-required": "PSČ je vyžadováno",
      "error-street-required": "Adresa ulice je vyžadována",
      "error-street-no-required": "Číslo je vyžadováno",
      "error-no-orders": "Zatím jste si nic neobjednali",
      "error-no-sales": "Zatím si od vás nikdo nic neobjednal",
      "error-no-carts": "Zatím si od vás nikdo nic nepřidal do košíku",
      "change-password": "Změnit heslo",
      "text-submit": "Odeslat",
      "text-show-profile": "Zobrazit profil",
      "profile-update-successful": "Profil úspěšně aktualizován",
      "profile-update-failed": "Aktualizace profilu/fotky selhala",
      "profile-address-update-failed": "Aktualizace adresy profilu selhala",
      "text-no-contact": "Nebyl nalezen žádný kontakt",
      "text-status": "Stav",
      "text-view": "Zobrazit",
      "text-supplier": "Prodejce",
      "text-requester": "Poptávající",
      "text-safe-shopping-with-us": "U nás se nemusíte bát nakupovat",
      "text-facebook": "Facebook",
      "text-x-twitter": "X (Twitter)",
      "text-more-info": "Více informací",
      "text-price": "Cena",
      "text-price-note": "(pokud nevyplníte, nastaví se Cena dohodou)",
      "text-negotiable": "Dohodou",
      "text-price-negotiable": "Cena dohodou",
      "text-some-items-have-price-negotiable": "Některé položky mají cenu dohodou",
      "text-vat-rate": "Sazba DPH",
      "text-stock-count": "Počet kusů",
      "text-gtin": "EAN",
      "text-code-internal": "Interní kód",
      "text-code-supplier": "Kód prodejce",
      "text-add-more-ads-link": "Přidejte více inzerátů",
      "text-add-more-ads-title": "a určitě se někdo brzy ozve.",
      "text-no-orders-title-1": "Zatím jste si nic neobjednali, podívejte se, zda něco nemáte",
      "text-no-orders-title-2": "v košíku.",
      "text-no-orders-subtitle-1": "Nebo si prohlédněte",
      "text-no-orders-subtitle-2": "všechny inzeráty,",
      "text-no-orders-subtitle-3": "kde určitě najdete něco zajímavého.",
      "text-no-order-selected": "Prosím vyberte objednávku, kterou chcete zobrazit",
      "text-no-order-details": "Je nám líto, tato objednávka nebyla nalezena :(",
      "text-no-unfinished-found": "Je nám líto, nenalezen žádný rozpracovaný košík :(",
      "text-no-unfinished-selected": "Prosím vyberte rozpracovaný košík, který chcete zobrazit",
      "text-no-unfinished-details": "Je nám líto, tento rozpracovaný košík nebyl nalezen :(",
      "heading-aquarist-main": "Komunitní tržiště pro akvaristy",
      "subheading-aquarist-main": "Inzeráty, inspirace, komentáře a zajímavé články",
      "heading-aquarist-main-2": "Vše pro vaše akvárium",
      "subheading-aquarist-main-2": "Najděte chovatele ve svém okolí a nakupujte jako na e&#8209;shopu",
      "heading-aquarist-main-5": "Přečtěte si",
      "subheading-aquarist-main-5": "Novinky a zajímavé články<br>ze světa akvaristiky",
      "heading-aquarist-category": "Nabídky pro vaše akvárium",
      "subheading-aquarist-category": "Najděte chovatele ve svém okolí<br>a nakupujte jako na e&#8209;shopu",
      "heading-aquarist-ads": "Nabídky pro vaše akvárium",
      "subheading-aquarist-ads": "Najděte chovatele ve svém okolí<br>a nakupujte jako na e&#8209;shopu",
      "heading-aquarist-my-ads": "Moje inzeráty",
      "subheading-aquarist-my-ads": "Upravte, zveřejněte nebo skryjte své inzeráty",
      "heading-aquarist-offers": "Nabídky pro vaše akvárium",
      "subheading-aquarist-offers": "Najděte chovatele ve svém okolí<br>a nakupujte jako na e&#8209;shopu",
      "heading-aquarist-requests": "Poptávky",
      "subheading-aquarist-requests": "Zadejte si poptávku a počkejte na nabídku",
      "heading-aquarist-auctions": "Aukce",
      "subheading-aquarist-auctions": "Nakupujte v aukci a získejte nejlepší cenu",
      "heading-aquarist-favorites": "Moje oblíbené inzeráty a inspirace",
      "subheading-aquarist-favorites": "Prohlédněte si své oblíbené inzeráty a inspirace",
      "heading-aquarist-inspiration": "Inspirace",
      "subheading-aquarist-inspiration": "Pochlubte se svými fotkami a inspirujte ostatní",
      "heading-aquarist-news": "Přečtěte si",
      "subheading-aquarist-news": "Novinky a zajímavé články ze světa akvaristiky",
      "heading-aquarist-reference": "Reference",
      "subheading-aquarist-reference": "Reference uživatelů",
      "button-cancel": "Zrušit",
      "button-delete": "Smazat",
      "delete-item-confirm": "Potvrdit smazání této položky",
      "common:text-forgot-password": "Zapomněli jste heslo?",
      "see-more": "Více informací",
      "text-more": "Více",
      "activate-account.error.unknown": "Nastala chyba. Zkuste to prosím jindy.",
      "activate-account.error.unauthorized": "Nastala chyba ověření přístupu.",
      "activate-account.error.already-set": "Účet byl přes tento odkaz již aktivován.",
      "activate-account.error.forbidden": "Pro aktivaci účtu se nejdříve odhlaste.",
      "activate-account.error.not-found": "Uživatelský účet nebyl nalezen.",
      "delete-account.error.unknown": "Nastala chyba. Zkuste to prosím jindy.",
      "delete-account.error.already-set": "Účet byl přes tento odkaz již smazán.",
      "delete-account.error.not-found": "Uživatelský účet nebyl nalezen.",
      "forgot-password.error.unknown": "Nastala chyba. Zkuste to prosím znovu.",
      "forgot-password.error.already-set": "Odkaz pro změnu hesla byl již dříve použit, pokud své heslo neznáte, použijte ",
      "forgot-password.error.already-set.link.label": "Zapomenuté heslo",
      "forgot-password.error.forbidden": "Změna hesla není povolena pro návštěvníky.",
      "forgot-password.error.not-found": "Pro zadaný email nebyl nalezen žádný uživatel.",
      "error.unauthorized": "Nedostupné pro nepřihlášené uživatele.",
      "error.customer-detail.load": "Načtení detailu uživatele selhalo.",
      "error.register.unknown": "Nastala chyba. Zkuste to prosím jindy.",
      "error.register.user-exists": "Uživatel s tímto e-mailem je již registrován. Pokud jste to vy a nepamatujete si heslo, použijte ",
      "error.register.user-exists.link.label": "Zapomenuté heslo",
      "error.cart-detail.load": "Načtení detailu košíku selhalo",
      "error.cart-detail.load.comments": "Načtení komentářů ke košíku selhalo",
      "error.cart-detail.add.comment": "Přidání komentáře selhalo",
      "error.news-detail.load": "Načtení detailu obsahu selhalo",
      "error.reference-detail.load": "Načtení detailu obsahu selhalo",
      "error.content-detail.load": "Načtení detailu obsahu selhalo",
      "error.content-detail.favorite": "Změna oblíbeného obsahu selhala",
      "error.content-detail.likes": "Změna líbí se u obsahu selhala",
      "error.content-detail.load.comments": "Načtení komentářů k obsahu selhalo",
      "error.content-detail.add.comment": "Přidání komentáře selhalo",
      "error.product-detail.load": "Načtení detailu produktu selhalo",
      "error.product-detail.favorite": "Změna oblíbeného produktu selhala",
      "error.product-detail.likes": "Změna líbí se u produktu selhala",
      "error.product-detail.load.comments": "Načtení komentářů k produktu selhalo",
      "error.product-detail.add.comment": "Přidání komentáře selhalo",
      "error.recommend-product.add.comment": "Přidání nabídky selhalo",
      "error.product-list.load": "Načtení seznamu produktů selhalo",
      "error.carts.load": "Načtení košíků selhalo",
      "error.cart.add.item": "Přidání do nákupního košíku selhalo",
      "error.cart.update.item": "Aktualizace nákupního košíku selhala",
      "error.cart.remove.item": "Odebrání z nákupního košíku selhalo",
      "error.cart.load": "Načtení košíku selhalo",
      "error.cart.checkout.order": "Dokončení objednávky v košíku selhalo",
      "error.cart.cancel.order": "Zrušení objednávky v košíku selhalo",
      "error.cart.consent.false": "Prosím souhlaste s obchodními podmínkami",
      "error.register.consent.conditions.false": "Pro registraci prosím souhlaste s obchodními podmínkami",
      "error.register.consent.privacy.false": "Pro registraci prosím souhlaste se zásadami ochrany osobních údajů",
      "error.orders.load": "Načtení objednávek selhalo",
      "error.order-detail.load": "Načtení detailu objednávky selhalo",
      "error.supplier.create": "Vytvoření prodejce selhalo",
      "error.auth.me.load": "Načtení uživatele selhalo",
      "error.product.create": "Vytvoření inzerátu selhalo",
      "error.product.update": "Uložení inzerátu selhalo",
      "error.product.update.status": "Změna stavu produktu selhala",
      "error.variant.image.create": "Přidání obrázku k inzerátu selhalo",
      "error.variant.image.update": "Úprava obrázku z inzerátu selhala",
      "error.variant.image.delete": "Smazání obrázku z inzerátu selhalo",
      "error.inspiration.create": "Přidání obrázku selhalo",
      "error.inspiration.update": "Úprava obrázku selhala",
      "error.inspiration.update.status": "Změna stavu obrázku selhala",
      "error.inspiration.delete": "Smazání obrázku selhalo",
      "error.supplier.image.update": "Úprava obrázku selhala",
      "error.supplier.image.delete": "Smazání obrázku selhalo",
      "error.supplier.rating.load": "Načtení hodnocení prodejce selhalo",
      "error.contact-form.submit": "Odeslání kontaktního formuláře selhalo",
      "error.report-ad-form.submit": "Odeslání nahlášení inzerátu selhalo",
      "error.report-content-form.submit": "Odeslání nahlášení inspirace selhalo",
      "error.filter.maxKmFromHome.check": "Zadejte ještě prosím místo nebo PSČ, případně povolte sdílení polohy.",
      "error.filter.maxKmFromLocation.check": "Zadejte ještě prosím místo nebo PSČ, případně povolte sdílení polohy.",
      "error.filter.maxKm.check": "Zadejte ještě prosím místo nebo PSČ, případně povolte sdílení polohy.",
      "error.geolocation.denied": "Sdílení aktuální polohy bylo zamítnuto. Pro použití tohoto filtru je potřeba povolit sdílení své polohy v prohlížeči.",
      "error.order.rating.create": "Uložení hodnocení selhalo",
      "error.setting.notification.email.update": "Uložení nastavení notifikačních emailů selhalo",
      "success.setting.notification.email.update": "Nastavení notifikačních emailů bylo upraveno",
      "success.geolocation.accepted": "Sdílení aktuální polohy bylo povoleno",
      "success.delete-account-link.sent": "Odkaz na smazání uživatelského konta byl odeslán e-mailem",
      "success.cart.checkout.order": "Objednávka v košíku vytvořena",
      "success.cart.cancel.order": "Objednávka v košíku zrušena",
      "success.product.create": "Inzerát byl založen",
      "success.product.update": "Inzerát byl upraven",
      "success.product.delete": "Inzerát byl smazán",
      "success.variant.image.update": "Obrázek byl upraven",
      "success.variant.image.delete": "Obrázek byl smazán",
      "success.inspiration.create": "Inspirace byla vytvořena",
      "success.inspiration.update": "Inspirace byla upravena",
      "success.inspiration.delete": "Inspirace byla smazána",
      "success.supplier.image.update": "Obrázek byl upraven",
      "success.supplier.image.delete": "Obrázek byl smazán",
      "success.supplier.blocked.user": "Uživatel byl zablokován",
      "success.contact-form.submit": "Zpráva z kontaktního formuláře byla odeslána",
      "success.report-ad-form.submit": "Nahlášení inzerátu bylo odesláno",
      "success.report-content-form.submit": "Nahlášení inspirace bylo odesláno",
      "success.recommend-product.submit": "Nabídka byla doporučena",
      "success.order.rating.create": "Hodnocení bylo uloženo",
      "success.order.rating.update": "Hodnocení bylo upraveno",
      "success.order.status.update": "Stav objednávky byl upraven",
      "text-views": "Počet zobrazení",
      "text-likes": "To se mi líbí",
      "text-unit-km": "km",
      "text-unit-pc": "ks",
      "text-add-comment": "Přidat komentář",
      "text-ask-or-add-comment": "Zeptejte se nebo přidejte komentář k inzerátu",
      "text-send": "Odeslat",
      "text-agree-with": "Souhlasím s",
      "text-agree-withs": "Souhlasím se",
      "text-terms-privacy-policy": "zásadami ochrany osobních údajů",
      "text-terms-and-conditions": "Obchodní podmínky",
      "text-with-terms-and-conditions": "obchodními podmínkami",
      "text-receiving-marketing": "marketingovou komunikací",
      "text-order-status-new": "Čeká na přijetí prodejcem",
      "text-order-status-accepted": "Objednáno",
      "text-order-status-refused": "Odmítnuto",
      "text-order-status-waiting": "Čeká na vyřízení",
      "text-order-status-paid": "Zaplaceno",
      "text-order-status-pickup": "K vyzvednutí",
      "text-order-status-processed": "Zpracováno",
      "text-order-status-returned": "Vráceno",
      "text-order-status-sent": "Odesláno",
      "text-order-status-undelivered": "Nedoručeno",
      "text-order-status-canceled": "Zrušeno",
      "text-order-status-completed": "Dokončeno",
      "text-order-status-deleted": "Smazáno",
      "text-accept-order": "Přijmout objednávku",
      "text-accept-order-number": "Přijmout objednávku #{number}",
      "text-confirm-accept-order-title": "Přijetím objednávky potvrzujete to, že objednávku vyřídíte a zboží odešlete, tak jak bylo objednáno, nebo jak jste se dohodli se zákazníkem.",
      "text-refuse": "Odmítnout",
      "text-refuse-order": "Odmítnout objednávku",
      "text-refuse-order-number": "Odmítnout objednávku #{number}",
      "text-confirm-refuse-order-title": "Opravdu chcete objednávku odmítnout?",
      "text-block-user": "Zablokovat kupujícího",
      "text-block-user-name": "Zablokovat kupujícího {name}",
      "text-confirm-block-user-title": "Opravdu chcete zablokovat kupujícího?",
      "text-blocked-user": "Zablokovaný uživatel",
      "text-blocked-users": "Blokovaní uživatelé",
      "text-blocked-no-users": "Zatím jste žádné uživatele nezablokovali",
      "text-add-to-cart-blocked-notice": "Není možné od tohoto prodávajícího nakupovat, protože si vás zablokoval.",
      "text-rate-order-blocked-notice": "Není možné tohoto prodávajícího hodnotit, protože si vás zablokoval.",
      "text-add-message-blocked-notice": "Není možné přidat komentář tomuto prodávajícímu, protože si vás zablokoval.",
      "text-cart-status-unfinished": "Nedokončený",
      "common:text-unread-message": "zpráv,zpráva,zprávy",
      "error-some-fields-required": "Prosím vyplňte povinné položky",
      "text-status-draft": "Koncept",
      "text-status-waiting": "Čekající",
      "text-status-active": "Aktivní",
      "text-status-hidden": "Skrytý",
      "text-hide": "Skrýt",
      "text-distance-from-location": "Vzdálenost od místa",
      "text-distance-name-or-zip": "název místa nebo PSČ",
      "text-distance-name-or-zip-note": "Začněte psát a vyberte z našeptávače:",
      "text-distance-name-or-zip-error": "Napište část názvu místa a poté vyberte z našeptávače.",
      "text-distance-select": "vyberte vzdálenost",
      "text-distance-near-km": "do {number} km",
      "text-distance-from-my-location": "Vzdálenost od vaší polohy",
      "text-max-distance-in-km": "Max. vzdálenost v km",
      "text-notifications": "Notifikace",
      "text-application": "Aplikace",
      "text-unverified-user": "Neověřený e-mail",
      "text-unverified-buyer": "Neověřený e-mail",
      "text-unverified-seller": "Neověřený e-mail",
      "text-verified-user": "Ověřený e-mail",
      "text-verified-buyer": "Ověřený e-mail",
      "text-verified-seller": "Ověřený e-mail",
      "text-unknown-user": "Neznámý e-mail",
      "text-unknown-buyer": "Neznámý e-mail",
      "text-unknown-seller": "Neznámý e-mail",
      "text-verified-user-required-for-ad-publishing": "Pro zveřejnění inzerátu je potřeba aktivovat svůj uživatelský účet odkazem z e-mailu.",
      "text-save-ad-and-publish-later": "Uložte inzerát na později a proveďte zveřejnění inzerátu po aktivaci uživatelského účtu.",
      "text-one-active-cart-hint-title": "Klikněte na Dokončit objednávku a přejděte do dalšího kroku pro upřesnění a odeslání objednávky.",
      "text-more-active-carts-hint-title": "Máte vybráno zboží od více prodejců, proto níže vidíte více košíků. Klikněte na Dokončit objednávku a přejděte do dalšího kroku pro upřesnění a odeslání objednávky.",
      "text-checkout-address-hint-title": "Zadejte svoje kontaktní údaje pro dodání objednávky.",
      "text-checkout-messages-hint-title": "Přidáním komentáře můžete navrhnout požadovaný způsob dopravy a platby, případně jinak upřesnit objednávku nebo položit dotaz.",
      "text-orders-hint-title": "V konverzaci pod položkami objednávky se s prodávajícím domluvte na způsobu dodání a platby. Nezapomeňte nakonec prodávajícího ohodnotit.",
      "text-orders-hint-subtitle": "Jakmile vám prodávající napíše novou zprávu, pošleme vám upozornění e-mailem a notifikací prohlížeče.",
      "text-sales-hint-title": "V konverzaci pod položkami objednávky se s kupujícím domluvte na způsobu dodání a platby. Pokud se dohodnete a budete objednávku realizovat, klikněte na tlačítko Přijmout objednávku. Pokud se nedohodnete, zvolte Odmítnout objednávku. Nezapomeňte nakonec kupujícího ohodnotit.",
      "text-sales-hint-subtitle": "Jakmile vám kupující napíše novou zprávu, pošleme vám upozornění e-mailem a notifikací prohlížeče.",
      "text-chat-with-seller": "Napište si s prodávajícím",
      "text-chat-with-buyer": "Napište si s kupujícím",
      "text-chat-hint-title": "Domluvte se spolu na způsobu předání a úhrady. Napravo v řádku konverzace je ikonka pro vložení obrázku nebo vašich kontaktních údajů.",
      "text-order-list-hint-title": "Pro aktualizaci počtu zpráv a označení přečtených musíte stránku znovu načíst (např. klávesou F5 nebo ikonou pro obnovení v prohlížeči).",
      "text-is-read": "Přečteno",
      "text-cookie-consent-popup": "Tento web používá k poskytování služeb, personalizaci reklam a analýze návštěvnosti soubory cookie.",
      "text-cookie-consent-button-accept": "Povolit vše",
      "text-cookie-consent-button-reject": "Zamítnout",
      "text-cookie-consent-button-configure": "Nastavit možnosti",
      "text-cookie-consent-button-save": "Uložit možnosti",
      "text-cookie-consent-authorization-default": "Funkční (nezbytné) cookies jsou nutné pro správné fungování stránek a služeb na našich internetových stránkách.",
      "text-cookie-consent-authorization-analytics": "Analytické cookies se používají pro analýzu a statistiku provozu na internetových stránkách a neslouží k identifikaci uživatelů.",
      "text-cookie-consent-authorization-advertising": "Marketingové cookies pro cílenou reklamu a marketing zaznamenávají vaši návštěvu na internetových stránkách a odkazy, které jste použili. To vám umožňuje nabídnout taková obchodní sdělení, která by vás mohla zajímat.",
      "text-rules-of-use": "Pravidla používání",
      "text-share": "Sdílet",
      "text-share-ad": "Sdílet inzerát",
      "text-copy-to-clipboard": "Zkopírovat do schránky",
      "text-copied-to-clipboard": "Odkaz zkopírován do schránky",
      "text-email-notification-settings": "Nastavení e-mailových notifikací",
      "text-send-notifications-by-email": "Přeji si dostávat e-maily s upozorněním na následující události:",
      "text-if-seller": "Pokud prodávám",
      "text-if-buyer": "Pokud nakupuji",
      "text-if-other": "Obecné",
      "text-settings-send-eshop-order-created": "nová objednávka mého inzerovaného zboží",
      "text-settings-send-eshop-cart-message-buyer": "nová zpráva od kupujícího",
      "text-settings-send-eshop-cart-message-supplier": "nová zpráva od prodejce",
      "text-settings-send-eshop-product-follower": "nový inzerát od sledovaného uživatele",
      "text-settings-send-eshop-product-variant-message": "nový komentář u inzerátu",
      "text-settings-send-eshop-order-rating-created": "nové hodnocení od jiného uživatele",
      "text-settings-send-eshop-product-variant-auction-created": "sledovaný uživatel vytvořil novou aukci",
      "text-settings-send-eshop-product-variant-auction-started": "začala aukce od sledovaného uživatele",
      "text-settings-send-eshop-product-variant-auction-new-bid": "byl přidán příhoz do aukce, které se účastníte",
      "text-settings-send-eshop-product-variant-auction-finished": "byla ukončena aukce, kterou jste vytvořili",
      "text-settings-send-eshop-product-variant-auction-winner": "byl jste vybrán jako vítěz aukce",
      "text-follow-user": "Sledovat uživatele",
      "text-follow-user-hint": "Po zapnutí Sledování uživatele budete dostávat notifikace o jeho nových inzerátech.",
      "text-following-user": "Sledujete uživatele",
      "text-following-users": "Sledovaní uživatelé",
      "text-following-no-users": "Zatím nikoho nesledujete.",
      "text-starting-bid": "Vyvolávací cena",
      "text-start-at": "Začít od",
      "text-finish-at": "Ukončit v",
      "text-auction-starting-in": "Aukce začíná za",
      "text-auction-finishing-in": "Aukce končí za",
      "text-reserve-price": "Minimální prodejní cena",
      "text-min-increment": "Minimální příhoz",
      "text-pick-winner-type": "Způsob výběru vítěze",
      "text-automatically": "Automaticky",
      "text-manually": "Manuálně",
      "error-auction-starting-bid-required": "Vyvolávací cena je vyžadována",
      "error-auction-start-at-required": "Začít od je vyžadováno",
      "error-auction-finish-at-required": "Ukončit v je vyžadováno",
      "error-auction-finish-at-less-than-start-at": "Datum konce musí být větší než datum začátku",
      "text-countdown-days": "D",
      "text-countdown-hours": "H",
      "text-countdown-minutes": "M",
      "text-countdown-seconds": "S",
      "text-auction-finished": "Aukce ukončena",
      "text-placing-bid": "Můj příhoz",
      "text-place-bid": "Přihodit",
      "success.auction-bid.create": "Příhoz {amount} {sign} byl úspěšně odeslán",
      "error.auction-bid.create": "Odeslání příhozu {amount} {sign} selhalo",
      "error.auction-bid.minimal": "Příhoz musí být větší než {amount} {sign}",
      "error.auction-bid.load": "Načtení příhozů selhalo",
      "text-last-auction-bids": "Poslední příhozy",
      "text-pick-auction-winner": "Výběr vítěze aukce",
      "text-auction-winner": "Vítěz aukce",
      "text-auction-closed-duplicate-to-edit": "Aukce byla ukončena, není možné dále dělat změny. Použijte tlačítko Kopírovat inzerát v pravém horním rohu pro vytvoření nové aukce.",
      "text-emoji-group-smileys-people": "Smajlíci a lidé",
      "text-emoji-group-animals-nature": "Zvířata a příroda",
      "text-emoji-group-food-drink": "Jídlo a pití",
      "text-emoji-group-activities": "Aktivity",
      "text-emoji-group-travel-places": "Cestování a místa",
      "text-emoji-group-objects": "Předměty",
      "text-emoji-group-symbols": "Symboly",
      "text-emoji-group-flags": "Vlajky"
    },
    "en": {
      "text-logo": "Logo",
      "text-heading": "Heading",
      "404-heading": "Error code: {code}",
      "404-sub-heading": "Oops! Looks like this isn't a page",
      "404-back-home": "Take me home",
      "nav-menu-category": "Category",
      "nav-menu-offers": "Offers",
      "nav-menu-requests": "Requests",
      "nav-menu-auctions": "Auctions",
      "nav-menu-inspiration": "Inspiration",
      "nav-menu-news": "News",
      "nav-menu-my-favorites": "My Favorites",
      "nav-menu-faq": "FAQ",
      "nav-menu-contact": "Contact",
      "nav-home": "Home",
      "text-homepage": "Homepage",
      "text-all-ads": "All Ads",
      "text-all-inspirations": "All Inspirations",
      "text-all-news": "All Articles",
      "text-faq": "Help",
      "text-read-news": "News",
      "text-why-submit-ad": "Why advertise with us",
      "text-in-progress": "In progress...",
      "text-contact": "Contact",
      "user-avatar": "User avatar",
      "text-edit-profile": "Edit Profile",
      "text-edit-main-image": "Edit Main Image",
      "auth-menu-profile": "Edit Profile",
      "auth-menu-settings-notifications": "Notifications",
      "auth-menu-my-orders": "My Orders",
      "auth-menu-my-sales": "My Sales",
      "auth-menu-waiting-orders": "Waiting Orders",
      "auth-menu-my-ads": "My Ads",
      "auth-menu-logout": "Logout",
      "join-button": "Join",
      "text-log-in-to-show-page": "Sign in to view page content",
      "change-locale": "Switch language",
      "text-fish": "Fish",
      "text-ads": "Ads",
      "text-browse-ads": "Browse Ads",
      "text-report-ad": "Report Ad",
      "text-reporting-ad": "Reported Ad",
      "text-reporting-details": "Reason for reporting",
      "text-report-inspiration": "Report Inspiration",
      "text-reporting-inspiration": "Reporting Inspiration",
      "text-submit-report": "Submit",
      "text-my-ads": "My Active Ads",
      "text-my-offers": "My Active Offers",
      "text-my-requests": "My Active Requests",
      "text-my-auctions": "My Active Auctions",
      "text-my-inspiration": "My Inspiration",
      "text-my-favorites": "My Favorites",
      "text-inspiration": "Inspiration",
      "text-google-play": "Google Play",
      "text-apple-store": "Apple Store",
      "text-favorite": "Favorite",
      "text-favorite-ads": "Favorite ads",
      "text-favorite-inspirations": "Favorite inspirations",
      "text-favorite-news": "Favorite articles",
      "text-latest-inspiration": "Latest inspiration",
      "text-latest-inspiration-subtitle": "Publish your photos and inspire others!",
      "text-latest-news": "News",
      "text-latest-news-subtitle": "Interesting facts from the world of aquariums and aquatic animals",
      "text-latest-ads": "Latest ads",
      "text-latest-ads-subtitle": "Let's explore aquaristic and see what's new with us!",
      "text-latest-reference": "Experiences of our users",
      "text-latest-reference-subtitle": "What do aquarium enthusiasts say about ForAquarist?",
      "text-recommend": "Recommend",
      "text-recommendation-message": "You may be interested in the ad:",
      "text-recommended-ads": "Recommended ads",
      "text-recommended-ads-subtitle": "We have selected the most interesting offers",
      "text-recommended-my-product": "Offer your advertised product",
      "text-recommended-my-product-subtitle-1": "You can offer one of your ads in response to a request. If you don't have one, create a new one",
      "text-recommended-my-product-subtitle-2": "here",
      "text-recommended-my-product-subtitle-3": "and then respond to the request.",
      "text-recommended-my-product-result-1": "To the ad request",
      "text-recommended-my-product-result-2": "a comment has been added with your offer",
      "text-show-message": "View comment",
      "text-mobile-app": "Mobile App",
      "text-mobile-app-title": "Try the ForAquarist app!",
      "text-mobile-app-subtitle": "Modern marketplace for selling, buying and inspiring always at hand",
      "text-mobile-app-instructions": "You can download the ForAquarist application from these links",
      "text-could-interest-you": "You could be interested in",
      "text-for-aquarist": "For Aquarist",
      "text-search": "Search",
      "common:text-search": "Search",
      "text-burger-menu": "Menu",
      "text-close": "Close",
      "text-clear": "Clear",
      "text-or": "Or",
      "text-allow-geolocation-here": "Allow sharing of your current location here",
      "text-allow-geolocation": "Please enable sharing of your current location to display the distance",
      "text-gdpr": "GDPR",
      "text-privacy-policy": "privacy policy",
      "text-login-auth-message": "To add to cart, please log in or",
      "text-login-auth-message-link-title": "register",
      "text-register-auth-message": "To add to cart, please fill in your name and email so that we can inform you about new products.",
      "text-register-succes-message": "Thank you for registering. Please check your email inbox where we sent you an email with a confirmation link.",
      "text-add-supplier-info": "Add additional contact information",
      "text-offer": "Offer",
      "text-request": "Request",
      "text-auction": "Auction",
      "text-added-to-cart": "Added to cart",
      "text-in-cart": "In cart",
      "text-minus": "minus",
      "text-plus": "plus",
      "common:text-read-more": "Read more",
      "text-show-more": "Show more",
      "text-show-all": "Show all",
      "text-previous": "Previous",
      "text-next": "Next",
      "common:text-less": "Less",
      "common:text-item": "Items,Item,Items",
      "text-item-header": "Item",
      "text-quantity-header": "Count",
      "text-price-header": "Price",
      "text-vat-rate": "VAT Rate",
      "text-stock-count": "Stock Count",
      "text-gtin": "EAN",
      "text-code-internal": "Internal Code",
      "text-code-supplier": "Supplier Code",
      "text-add-ad": "Add Ad",
      "text-add-ad-hint-title": "If you have more to offer, create more ads - then others will find them better when searching by category. You can not only edit the saved ad, but also easily copy it via the button in the upper right corner of the ad detail.",
      "text-ad-name-placeholder": "Usually an English or Latin name, or the name of the product",
      "text-ad-content-placeholder": "Description of what is offered, including information on possible transport. You can also provide additional information about breeding, about the species offered, etc.",
      "text-add-new-ad": "Add New Ad",
      "text-add-photo": "Add Photo",
      "text-add-inspiration": "Add Inspiration",
      "text-copy-ad": "Copy Ad",
      "text-ad-delete-title": "Do you really want to delete this ad?",
      "text-want-to-buy": "Want to buy",
      "text-to-offer": "Offer",
      "text-cart": "Cart",
      "text-shopping-cart": "Shopping Cart",
      "text-add-to-favorites": "Add to My Favorites",
      "text-remove-from-favorites": "Remove from My Favorites",
      "text-pieces-available": "pieces available,piece available,pieces available",
      "text-pieces-required": "pieces required,piece required,pieces required",
      "text-details": "Details",
      "text-comments": "Comments",
      "text-order": "Order",
      "text-order-detail": "Order Detail",
      "text-rating": "Rating",
      "text-added-rating": "Added Rating:",
      "text-order-buyer-rating": "Your Order Rating",
      "text-order-seller-rating": "Your Order Rating",
      "text-order-review": "Text Rating",
      "text-update-order-review": "Edit Rating",
      "text-order-review-waiting": "User {name} has already rated this order. Rate this order and the rating will be displayed.",
      "text-phone": "Phone",
      "text-cart-date": "Cart Date",
      "text-order-date": "Order Date",
      "text-order-details": "Order Details",
      "text-unfinished-cart": "Unfinished Cart",
      "text-unfinished-cart-details": "Unfinished Cart Details",
      "text-location": "Location",
      "text-distance": "Distance",
      "text-address": "Address",
      "text-insert-my-info": "Insert My Info",
      "text-insert-billing-address": "Insert Billing Address",
      "text-insert-delivery-address": "Insert Delivery Address",
      "text-insert-email-address": "Insert My Email Address",
      "text-insert-phone-number": "Insert My Phone Number",
      "text-upload-highlight": "Upload images",
      "text-upload-message": "by click or drag and drop",
      "text-img-format": "PNG, JPG",
      "text-delivery-address": "Delivery Address",
      "text-submit-order": "Send Order",
      "text-submit-order-note-1": "Sending the order is not binding, but it is necessary that you can then discuss payment, delivery, etc. with the seller in the chat.",
      "text-submit-order-note-2": "If you do not agree, he has the option of rejecting your order.",
      "text-submit-order-note-3": "The order is binding for both parties only after acceptance of the order by the seller.",
      "text-cancel-order": "Cancel Order",
      "text-abandonment-reason": "Abandonment Reason",
      "placeholder-abandonment-note": "We will be happy if you write us the reason for canceling the order.",
      "text-shipping": "Shipping",
      "text-billing-data": "Billing Data",
      "text-billing-address": "Billing Address",
      "text-billing-and-delivery-address": "Billing and Delivery Address",
      "text-payment-fee": "Payment Fee",
      "text-delivery-fee": "Delivery Fee",
      "text-items-count": "Item Count",
      "text-total-price": "Total Price",
      "text-total": "Total",
      "text-out-stock": "Out Of Stock",
      "text-product-status-closed": "The ad is closed",
      "text-insert-image": "Insert Image",
      "text-insert-profile-image": "Upload Profile Image",
      "text-product-unavailable-title": "The detail cannot be displayed, the ad is not available.",
      "text-product-unavailable-subtitle": "The ad has either been deleted, hidden, the product is no longer available, or there is an error in the link.",
      "text-product-image": "Image",
      "text-product-image-edit": "Edit Image",
      "text-supplier-unavailable-title": "Supplier details cannot be displayed.",
      "text-supplier-unavailable-subtitle": "The supplier's profile has either been deleted, hidden, or there is an error in the link.",
      "text-supplier-image-edit": "Edit Image",
      "text-supplier-image-delete-title": "Really delete this image?",
      "text-inspiration-unavailable-title": "Detail cannot be displayed, inspiration is not available.",
      "text-inspiration-unavailable-subtitle": "The inspiration has either been deleted, hidden, or there is an error in the link.",
      "text-inspiration-edit": "Edit Inspiration",
      "text-news-unavailable-title": "The detail cannot be displayed, the article is not available.",
      "text-news-unavailable-subtitle": "The article has either been deleted, hidden, or there is an error in the link.",
      "text-reference-unavailable-title": "Detail cannot be displayed, reference is not available.",
      "text-reference-unavailable-subtitle": "The reference has either been deleted, hidden, or there is an error in the link.",
      "text-ad-edit": "Edit Ad",
      "text-load-more": "Load More",
      "text-availability": "Availability",
      "text-category": "Category",
      "text-category-subtitle": "What you're looking for?",
      "text-home": "Home",
      "text-user": "User",
      "text-edit": "Edit",
      "text-ad-title": "Ad Name",
      "text-title": "Title",
      "text-firstName": "Name",
      "text-lastName": "Surname",
      "text-namePrefix": "Title",
      "text-nameSuffix": "After Title",
      "text-company": "Company Name",
      "text-companyPerson": "Contact Person",
      "text-companyNo": "Company No",
      "text-companyVatNo": "Company VAT",
      "text-street": "Street",
      "text-streetNo": "Street No",
      "text-houseNo": "House No",
      "text-city": "City",
      "text-zip": "ZIP",
      "text-state": "State",
      "text-country": "Country",
      "text-load-from-ares": "Read from ARES registry",
      "text-add-company-info": "Add Company Info",
      "text-use-delivery-address": "Use Billing Address As Delivery Address",
      "text-gallery": "Gallery",
      "text-gallery-info-title": "The images you upload to the Gallery are displayed in the details of your profile, where other users can also view them.",
      "error-ad-type-required": "Ad type is required",
      "error-ad-name-required": "Ad name is requeired",
      "error-ad-description-required": "Ad description is required",
      "error-ad-category-required": "Ad category is required",
      "error-ad-availability-required": "Ad availability is required",
      "error-ad-price-required": "Ad price is required",
      "error-stock-count-required": "The number of pieces is required",
      "error-in-stock-count-required": "Availability is \"{name}\", so please set the number of pieces greater than 0.",
      "error-ad-vat-rate-required": "VAT Rate is required",
      "error-ad-parameter-required": "This parameter is required",
      "error-content-image-required": "Photo is required",
      "error-content-name-required": "Photo name is required",
      "text-add-new-photo": "Add New Photo",
      "text-new-variant": "New Variant",
      "text-add-new-variant": "Add New Variant",
      "text-add-copy-variant": "Add Variant Copy",
      "text-add-parameter": "Add Parameter",
      "text-parameters": "Parameters",
      "text-edit-ad": "Edit Ad",
      "text-edit-photo": "Edit Photo",
      "text-edit-variant": "Edit Variant",
      "text-delete-variant": "Delete Variant",
      "text-set-as-main-image": "Set as Main Image",
      "text-change-password": "Change Password",
      "profile-sidebar-profile": "Profile",
      "profile-sidebar-settings-notifications": "Notifications",
      "profile-sidebar-my-orders": "My Orders",
      "profile-sidebar-my-sales": "My Sales",
      "profile-sidebar-waiting-orders": "Waiting Orders",
      "profile-sidebar-help": "Help",
      "profile-sidebar-contact": "Contact us",
      "profile-sidebar-account-delete": "Delete account",
      "text-contact-us": "Contact us",
      "password-successful": "Password changed successfully",
      "password-successful-please-login": "The password was successfully changed, you can log in",
      "error-credential-wrong": "The credentials are wrong!",
      "login-helper": "Login with your email & password",
      "error-email-format": "The provided email address format is not valid",
      "error-email-required": "You must need to provide your email address",
      "error-phone-required": "You must need to provide your phone number",
      "error-phone-numeric": "Enter numeric phone number",
      "error-password-required": "You need to provide your password",
      "error-subject-required": "What subject do you want to discuss with us?",
      "error-description-required": "Tell us more about it",
      "text-in-progress-now": "In progress now...",
      "text-activate-account": "Activate account",
      "text-account-activated": "User account was activated",
      "text-account-not-activated": "The user account has not yet been activated.",
      "text-activate-account-sent": "A link to activate your user account has been sent to your email.",
      "text-send-activate-account": "Send an activation link by email",
      "text-delete-account-title": "Delete user account?",
      "text-confirm-delete-account": "We're sorry, are you sure you want to delete your user account? If so, we will send you a link to confirm the deletion of the user account at to your email inbox.",
      "text-send-email-link": "Send link to email",
      "text-confirm-delete-account-title": "Confirm the deletion of the user account",
      "text-confirm-delete-account-button": "Yes, I want to delete my user account",
      "text-account-deleted": "User account was deleted",
      "text-login": "Login",
      "text-no-account": "Don't have any account?",
      "text-already-account": "Already have an account?",
      "text-register": "Register",
      "text-register-here": "Register here",
      "text-old-password": "Old Password",
      "text-new-password": "New Password",
      "text-confirm-password": "Confirm Password",
      "error.old-password.invalid": "Invalid Old Password",
      "error-old-password-required": "Old Password is Required!",
      "error-new-password-required": "Password is Required!",
      "error-confirm-password": "Please confirm password!",
      "error-match-passwords": "Passwords should match!",
      "error-name-required": "You need to provide your name",
      "error-surname-required": "You need to provide your last name",
      "forgot-password-email-helper": "Enter your email for sending you a link to reset your password",
      "forgot-password-token-helper": "Enter the new password you want to use to log in",
      "text-check-email-for-token": "Click on the link we sent you via email",
      "text-reset-password": "Reset Password",
      "text-back": "Back",
      "text-back-to": "Back to",
      "text-forgot-password": "Forgotten password?",
      "text-follow-us": "Follow Us",
      "text-questions-comments": "Questions, Comments, Or Concerns?",
      "text-questions-and-comments": "Questions And Comments",
      "text-name": "Name",
      "text-surname": "Surname",
      "text-password": "Password",
      "text-write-email": "Write an email",
      "text-email": "Email",
      "text-subject": "Subject",
      "text-description": "Description",
      "text-about": "Info about you",
      "text-about-info": "Introduce yourself to others, what is your focus, what you are interested in.",
      "text-delivery": "Information about the transport offered",
      "text-delivery-info": "What form of delivery do you offer? E.g. personal delivery, post packet, etc.",
      "text-offered-delivery": "Offered delivery",
      "text-variant-content": "Product variant description",
      "text-variant-discount": "Discount description",
      "text-variant-package": "Package description",
      "text-use": "Use",
      "text-save": "Save",
      "text-save-for-later": "Save for later",
      "text-save-and-publish": "Save and Publish",
      "text-submit-email": "Submit Email",
      "text-loading": "Loading...",
      "common:text-loading": "Loading...",
      "text-signing-out": "Signing out...",
      "text-no-result-found": "No Result Found",
      "text-not-found": "Sorry, No Product Found",
      "text-no-option-found": "Option not found",
      "text-add-option": "Add option",
      "text-no-filtered-found": "Nothing was found for the specified filter. Adjust the filter and try again.",
      "text-no-my-ads": "You don't have an ad posted yet, but you can easily fix that.",
      "text-continue": "Continue",
      "text-continue-to-homepage": "Continue to the home page",
      "text-continue-in-mobile-app": "Continue in the mobile application",
      "text-continue-shopping": "Continue Shopping",
      "text-proceed-order": "Proceed to Order",
      "text-unavailable": "Unavailable",
      "text-do-not-remove": "Do not remove",
      "text-remove-item": "Remove item",
      "text-remove-item-from-cart": "Remove \"{name}\" from cart?",
      "text-cancel": "Cancel",
      "text-create-copy": "Create Copy",
      "text-delete": "Delete",
      "text-delete-account": "Delete account",
      "text-delete-avatar": "Delete profile photo",
      "text-filter": "Filter",
      "text-clear-filters": "Clear filter",
      "text-voice-search": "Voice search",
      "text-sort-by": "Sort by",
      "text-sort-by-newest": "Newest",
      "text-sort-by-oldest": "Oldest",
      "text-sort-by-cheapest": "Cheapest",
      "text-sort-by-expensive": "Expensive",
      "text-sort-by-position": "Recommended",
      "text-your-order": "Your Order",
      "text-no-products": "There is nothing in the cart yet",
      "text-search-label": "Search...",
      "common:text-search-placeholder": "Search your products from here",
      "common:text-search-ad-placeholder": "Write what ad you are looking for",
      "error-country-required": "Country is required",
      "error-city-required": "City is required",
      "error-valid-city-required": "City must be different from Password",
      "error-zip-required": "ZIP is required",
      "error-street-required": "Street Address is required",
      "error-street-no-required": "Street No. is required",
      "error-no-orders": "You didn't order anything yet",
      "error-no-sales": "No one has ordered anything from you yet",
      "error-no-carts": "No one has added anything to your cart yet",
      "change-password": "Change Password",
      "text-submit": "Submit",
      "text-show-profile": "View seller profile",
      "profile-update-successful": "Profile Updated Successfully",
      "profile-update-failed": "Profile/Photo Update Failed",
      "profile-address-update-failed": "Profile Address Update Failed",
      "text-no-contact": "No contact found",
      "text-status": "Status",
      "text-view": "View",
      "text-supplier": "Supplier",
      "text-requester": "Requester",
      "text-safe-shopping-with-us": "Shopping with us is save",
      "text-facebook": "Facebook",
      "text-x-twitter": "X (Twitter)",
      "text-more-info": "More Info",
      "text-price": "Price",
      "text-price-note": "(if not filled, it will be set as negotiable)",
      "text-negotiable": "Negotiable",
      "text-price-negotiable": "Negotiable Price",
      "text-some-items-have-price-negotiable": "Some items have negotiable price",
      "text-add-more-ads-link": "Add more ads",
      "text-add-more-ads-title": "and surely someone will call soon.",
      "text-no-orders-title-1": "You haven't ordered anything yet, check if you have anything",
      "text-no-orders-title-2": "in your cart.",
      "text-no-orders-subtitle-1": "Or look through",
      "text-no-orders-subtitle-2": "all the ads,",
      "text-no-orders-subtitle-3": "where you're sure to find something interesting.",
      "text-no-order-selected": "Please select the order you want to view",
      "text-no-order-details": "Sorry, this order was not found :(",
      "text-no-unfinished-found": "Sorry, No Unfinished Order Found :(",
      "text-no-unfinished-selected": "Please select unfinished order you want to view",
      "text-no-unfinished-details": "Sorry, this unfinished order was not found :(",
      "heading-aquarist-main": "Community marketplace for aquarists",
      "subheading-aquarist-main": "Advertisements, inspiration, comments and interesting articles",
      "heading-aquarist-main-2": "Everything for your aquarium",
      "subheading-aquarist-main-2": "Find a breeder in your area and shop as in an e-shop",
      "heading-aquarist-main-5": "To read",
      "subheading-aquarist-main-5": "News and interesting articles from the world of aquariums",
      "heading-aquarist-category": "Offers for your aquarium",
      "subheading-aquarist-category": "Find a breeder in your area and shop as in an e-shop",
      "heading-aquarist-ads": "Offers for your aquarium",
      "subheading-aquarist-ads": "Find a breeder in your area and shop as in an e-shop",
      "heading-aquarist-my-ads": "My ads",
      "subheading-aquarist-my-ads": "Edit, publish or hide your ads",
      "heading-aquarist-offers": "Offers for your aquarium",
      "subheading-aquarist-offers": "Find a breeder in your area and shop as in an e-shop",
      "heading-aquarist-requests": "Requests",
      "subheading-aquarist-requests": "Enter your request and wait for the offer",
      "heading-aquarist-auctions": "Auctions",
      "subheading-aquarist-auctions": "Shop in auctions and get the best price",
      "heading-aquarist-favorites": "My favorite ads and inspiration",
      "subheading-aquarist-favorites": "Check out your favorite ads and inspiration",
      "heading-aquarist-inspiration": "Inspiration",
      "subheading-aquarist-inspiration": "Show off your photos and inspire others",
      "heading-aquarist-news": "To read",
      "subheading-aquarist-news": "News and interesting articles from the world of aquariums",
      "heading-aquarist-reference": "References",
      "subheading-aquarist-reference": "User references",
      "button-cancel": "Cancel",
      "button-delete": "Delete",
      "delete-item-confirm": "Confirm deleting this item",
      "common:text-forgot-password": "Forgot password?",
      "see-more": "See more",
      "text-more": "More",
      "activate-account.error.unknown": "An error occurred. Please try again later.",
      "activate-account.error.unauthorized": "An access authentication error occurred.",
      "activate-account.error.already-set": "The account has already been activated via this link.",
      "activate-account.error.forbidden": "Please logout to activate account.",
      "activate-account.error.not-found": "User account not found.",
      "delete-account.error.unknown": "An error occurred. Please try again later.",
      "delete-account.error.already-set": "The account has already been deleted via this link.",
      "delete-account.error.not-found": "User account not found.",
      "forgot-password.error.unknown": "An error occurred. Please try again later.",
      "forgot-password.error.already-set": "The link to change your password has been used before, if you don't know your password, use ",
      "forgot-password.error.already-set.link.label": "Forgotten password",
      "forgot-password.error.forbidden": "Password change not allowed for visitor.",
      "forgot-password.error.not-found": "No user was found for provided email.",
      "error.unauthorized": "Service not available for not logged in user.",
      "error.customer-detail.load": "Failed to load user detail.",
      "error.register.unknown": "An error occurred. Please try another time.",
      "error.register.user-exists": "The user with this email is already registered. If this is you and you don't remember your password, use ",
      "error.register.user-exists.link.label": "Forgotten password",
      "error.cart-detail.load": "Cart detail load failed",
      "error.cart-detail.load.comments": "Cart comments load failed",
      "error.cart-detail.add.comment": "Adding comment failed",
      "error.news-detail.load": "Content detail load failed",
      "error.reference-detail.load": "Content detail load failed",
      "error.content-detail.load": "Content detail load failed",
      "error.content-detail.likes": "Content like change failed",
      "error.content-detail.load.comments": "Content comments load failed",
      "error.content-detail.add.comment": "Adding comment failed",
      "error.product-detail.load": "Product detail load failed",
      "error.product-detail.favorite": "Product favorite change failed",
      "error.product-detail.likes": "Product like change failed",
      "error.product-detail.load.comments": "Product comments load failed",
      "error.product-detail.add.comment": "Adding comment failed",
      "error.product-list.load": "Product list load failed",
      "error.recommend-product.add.comment": "Adding recommendation failed",
      "error.carts.load": "Loading carts failed",
      "error.cart.add.item": "Adding to shopping cart failed",
      "error.cart.update.item": "Updating shopping cart failed",
      "error.cart.remove.item": "Removing from shopping cart failed",
      "error.cart.load": "Loading cart failed",
      "error.cart.checkout.order": "Checkout cart order failed",
      "error.cart.cancel.order": "Canceling cart order failed",
      "error.cart.consent.false": "Please agree with terms and conditions",
      "error.register.consent.conditions.false": "Please agree with terms and conditions to register",
      "error.register.consent.privacy.false": "Please agree with privacy policy to register",
      "error.orders.load": "Loading orders failed",
      "error.order-detail.load": "Loading order detail failed",
      "error.supplier.create": "Supplier create failed",
      "error.auth.me.load": "Use info load failed",
      "error.product.create": "Product create failed",
      "error.product.update": "Product update failed",
      "error.product.update.status": "Product status update failed",
      "error.product.delete": "Product delete failed",
      "error.variant.image.create": "Product variant image create failed",
      "error.variant.image.update": "Product variant image update failed",
      "error.variant.image.delete": "Product variant image delete failed",
      "error.inspiration.create": "Inspiration create failed",
      "error.inspiration.update": "Inspiration update failed",
      "error.inspiration.update.status": "Inspiration status update failed",
      "error.inspiration.delete": "Inspiration delete failed",
      "error.supplier.image.update": "Supplier image update failed",
      "error.supplier.image.delete": "Supplier image delete failed",
      "error.supplier.rating.load": "Failed to load seller rating",
      "error.contact-form.submit": "Contact form sending failed",
      "error.report-ad-form.submit": "Suspicious ad report sending failed",
      "error.report-content-form.submit": "Suspicious inspiration report sending failed",
      "error.filter.maxKmFromHome.check": "Please also enter a city or zip code, or enable location sharing.",
      "error.filter.maxKmFromLocation.check": "Please also enter a city or zip code, or enable location sharing.",
      "error.filter.maxKm.check": "Please also enter a city or zip code, or enable location sharing.",
      "error.geolocation.denied": "Current location sharing has been declined. To use this filter, you need to allow sharing your location in your browser.",
      "error.order.rating.create": "Rating create failed",
      "error.setting.notification.email.update": "Failed to save notification email settings",
      "success.setting.notification.email.update": "Notification email settings have been modified",
      "success.geolocation.accepted": "Sharing your current location has been enabled",
      "success.delete-account-link.sent": "The link to delete the user account was sent by email",
      "success.cart.checkout.order": "Cart order created",
      "success.cart.cancel.order": "Cart order canceled",
      "success.product.create": "Ad was created",
      "success.product.update": "Ad was updated",
      "success.product.delete": "Ad was deleted",
      "success.variant.image.update": "Product variant image was updated",
      "success.variant.image.delete": "Product variant image was deleted",
      "success.inspiration.create": "Inspiration was created",
      "success.inspiration.update": "Inspiration was updated",
      "success.inspiration.delete": "Inspiration was deleted",
      "success.supplier.image.update": "Product supplier image was updated",
      "success.supplier.image.delete": "Product supplier image was deleted",
      "success.supplier.blocked.user": "The user has been blocked",
      "success.contact-form.submit": "Contact form message was sent",
      "success.report-ad-form.submit": "Suspicious Ad Report was sent",
      "success.report-content-form.submit": "Suspicious Inspiration Report was sent",
      "success.recommend-product.submit": "Offer was recommended",
      "success.order.rating.create": "Rating was saved",
      "success.order.rating.update": "Rating was updated",
      "success.order.status.update": "Order status has been updated",
      "text-likes": "Likes",
      "text-views": "View Count",
      "text-unit-km": "km",
      "text-unit-pc": "pc",
      "text-add-comment": "Add comment",
      "text-ask-or-add-comment": "Ask a question or add a comment to the ad",
      "text-send": "Send",
      "text-agree-with": "I agree with",
      "text-agree-withs": "I agree with",
      "text-terms-privacy-policy": "privacy policy",
      "text-terms-and-conditions": "Terms and Conditions",
      "text-with-terms-and-conditions": "terms and conditions",
      "text-receiving-marketing": "receiving marketing",
      "text-order-status-new": "Awaiting acceptance by the seller",
      "text-order-status-accepted": "Ordered",
      "text-order-status-refused": "Rejected",
      "text-order-status-waiting": "Awaiting processing",
      "text-order-status-paid": "Paid",
      "text-order-status-pickup": "Pickup",
      "text-order-status-processed": "Processed",
      "text-order-status-returned": "Returned",
      "text-order-status-sent": "Sent",
      "text-order-status-undelivered": "Undelivered",
      "text-order-status-canceled": "Canceled",
      "text-order-status-completed": "Completed",
      "text-order-status-deleted": "Deleted",
      "text-accept-order": "Accept Order",
      "text-accept-order-number": "Accept Order #{number}",
      "text-confirm-accept-order-title": "By accepting the order, you confirm that you will process the order and ship the goods as ordered or as you agreed with the customer.",
      "text-refuse": "Refuse",
      "text-refuse-order": "Refuse Order",
      "text-refuse-order-number": "Refuse Order #{number}",
      "text-confirm-refuse-order-title": "Are you sure you want to refuse the order?",
      "text-block-user": "Block buyer",
      "text-block-user-name": "Block buyer {name}",
      "text-confirm-block-user-title": "Are you sure you want to block the buyer?",
      "text-blocked-user": "Blocked user",
      "text-blocked-users": "Blocked users",
      "text-blocked-no-users": "You have not blocked any users yet",
      "text-add-to-cart-blocked-notice": "It is not possible to purchase from this seller because they have blocked you.",
      "text-rate-order-blocked-notice": "It is not possible to rate this seller because they have blocked you.",
      "text-add-message-blocked-notice": "It is not possible to add a comment to this seller because they have blocked you.",
      "text-cart-status-unfinished": "Unfinished",
      "common:text-unread-message": "messages,message,messages",
      "error-some-fields-required": "Prosím fill required fields",
      "text-status-draft": "Draft",
      "text-status-waiting": "Waiting",
      "text-status-active": "Active",
      "text-status-hidden": "Hidden",
      "text-hide": "Hide",
      "text-distance-from-location": "Distance from location",
      "text-distance-name-or-zip": "place name or zip code",
      "text-distance-name-or-zip-note": "Start typing and select from suggestions:",
      "text-distance-name-or-zip-error": "Enter part of the place name and then select from the suggestions.",
      "text-distance-select": "select the distance",
      "text-distance-near-km": "area up to {number} km",
      "text-distance-from-my-location": "Distance from your location",
      "text-max-distance-in-km": "Max. distance in km",
      "text-notifications": "Notifications",
      "text-application": "Application",
      "text-unverified-user": "Unverified email",
      "text-unverified-buyer": "Unverified email",
      "text-unverified-seller": "Unverified email",
      "text-verified-user": "Verified email",
      "text-verified-buyer": "Verified email",
      "text-verified-seller": "Verified email",
      "text-unknown-user": "Unknown email",
      "text-unknown-buyer": "Unknown email",
      "text-unknown-seller": "Unknown email",
      "text-verified-user-required-for-ad-publishing": "To publish an ad, you need to activate your user account with a link from your e-mail.",
      "text-save-ad-and-publish-later": "Save the ad for later and publish after activating the user account.",
      "text-one-active-cart-hint-title": "Click Proceed to Order to continue to the next step to complete and submit your order.",
      "text-more-active-carts-hint-title": "You have selected goods from multiple sellers, so you see multiple baskets below. Click Proceed to Order to continue to the next step to complete and submit your order.",
      "text-checkout-address-hint-title": "Enter your contact details for order delivery. ",
      "text-checkout-messages-hint-title": "By adding a comment, you can suggest the desired method of transport and payment, or otherwise specify the order or ask a question.",
      "text-orders-hint-title": "In the conversation under the order items, agree with the seller on the method of delivery and payment. Don't forget to rate the seller.",
      "text-orders-hint-subtitle": "As soon as the seller writes you a new message, we will send you a notification by email and browser notification.",
      "text-sales-hint-title": "In the conversation under the order items, agree with the buyer on the method of delivery and payment. If you agree and place the order, click the Accept order button. If you do not agree, choose Reject the order. Don't forget to rate the buyer at the end.",
      "text-sales-hint-subtitle": "As soon as the buyer writes you a new message, we send you an email and notification.",
      "text-chat-with-seller": "Write to the seller",
      "text-chat-with-buyer": "Write to the buyer",
      "text-chat-hint-title": "Agree on the method of delivery and payment. At the right of the conversation line is an icon for inserting a picture or your contact information.",
      "text-order-list-hint-title": "To update the number of messages and the read mark, you need to reload the page (for example, with the F5 key or the refresh icon in the browser).",
      "text-is-read": "Read",
      "text-cookie-consent-popup": "This website uses cookies to provide services, personalize ads and analyze traffic.",
      "text-cookie-consent-button-accept": "Allow all",
      "text-cookie-consent-button-reject": "Reject",
      "text-cookie-consent-button-configure": "Set options",
      "text-cookie-consent-button-save": "Save options",
      "text-cookie-consent-authorization-default": "Functional (necessary) cookies are necessary for the proper functioning of the pages and services on our website.",
      "text-cookie-consent-authorization-analytics": "Analytical cookies are used for analysis and statistics of traffic on the website and do not serve to identify users.",
      "text-cookie-consent-authorization-advertising": "Marketing cookies for targeted advertising and marketing record your visit to the website and the links you used. This allows you to offer commercial communications that may be of interest to you.",
      "text-rules-of-use": "Rules of use",
      "text-share": "Share",
      "text-share-ad": "Share ad",
      "text-copy-to-clipboard": "Copy to clipboard",
      "text-copied-to-clipboard": "Link copied to clipboard",
      "text-email-notification-settings": "Email Notification Settings",
      "text-send-notifications-by-email": "I wish to receive e-mails notifying me of the following events:",
      "text-if-seller": "If I sell",
      "text-if-buyer": "If I shop",
      "text-if-other": "General",
      "text-settings-send-eshop-order-created": "new order of my advertised item",
      "text-settings-send-eshop-cart-message-buyer": "new message from buyer",
      "text-settings-send-eshop-cart-message-supplier": "new message from seller",
      "text-settings-send-eshop-product-follower": "new ad from a tracked user",
      "text-settings-send-eshop-product-variant-message": "new comment on the advertisement",
      "text-settings-send-eshop-order-rating-created": "new rating from another user",
      "text-settings-send-eshop-product-variant-auction-created": "a watched user has created a new auction",
      "text-settings-send-eshop-product-variant-auction-started": "an auction has started from a watched user",
      "text-settings-send-eshop-product-variant-auction-new-bid": "a bid has been added to the auction you are participating in",
      "text-settings-send-eshop-product-variant-auction-finished": "the auction you created has been finished",
      "text-settings-send-eshop-product-variant-auction-winner": "you have been selected as the auction winner",
      "text-follow-user": "Follow user",
      "text-follow-user-hint": "After enabling User Following, you will receive notifications about their new listings.",
      "text-following-user": "Following user",
      "text-following-users": "Followed users",
      "text-following-no-users": "You are not following anyone yet.",
      "text-starting-bid": "Starting Bid",
      "text-start-at": "Start At",
      "text-finish-at": "Finish At",
      "text-auction-starting-in": "Auction starting in",
      "text-auction-finishing-in": "Auction finishing in",
      "text-reserve-price": "Minimum selling price",
      "text-min-increment": "Minimum Increment",
      "text-pick-winner-type": "Pick Winner Type",
      "text-automatically": "Automatically",
      "text-manually": "Manually",
      "error-auction-starting-bid-required": "Starting Bid is required",
      "error-auction-start-at-required": "Start At is required",
      "error-auction-finish-at-required": "Finish At is required",
      "error-auction-finish-at-less-than-start-at": "The end date must be greater than the start date",
      "text-countdown-days": "D",
      "text-countdown-hours": "H",
      "text-countdown-minutes": "M",
      "text-countdown-seconds": "S",
      "text-auction-finished": "Auction finished",
      "text-placing-bid": "My bid",
      "text-place-bid": "Place bid",
      "success.auction-bid.create": "Bid of {amount} {sign} was successfully placed",
      "error.auction-bid.create": "Failed to place bid of {amount} {sign}",
      "error.auction-bid.minimal": "Bid must be greater than {amount} {sign}",
      "error.auction-bid.load": "Failed to load bids",
      "text-last-auction-bids": "Last bids",
      "text-pick-auction-winner": "Selecting auction winner",
      "text-auction-winner": "Auction winner",
      "text-auction-closed-duplicate-to-edit": "The auction has ended, no further changes can be made. Use the Copy Listing button in the top right corner to create a new auction.",
      "text-emoji-group-smileys-people": "Smiles & People",
      "text-emoji-group-animals-nature": "Animals & Nature",
      "text-emoji-group-food-drink": "Food & Drink",
      "text-emoji-group-activities": "Activities",
      "text-emoji-group-travel-places": "Travel places",
      "text-emoji-group-objects": "Objects",
      "text-emoji-group-symbols": "Symbols",
      "text-emoji-group-flags": "Flags"
    },
    "sk": {
      "text-logo": "Logo",
      "text-heading": "Nadpis",
      "404-heading": "Chybový kód: {code}",
      "404-sub-heading": "Íha! Vyzerá to, že táto stránka neexistuje",
      "404-back-home": "Návrat na úvodnú stránku",
      "nav-menu-category": "Kategórie",
      "nav-menu-offers": "Ponuky",
      "nav-menu-requests": "Dopyty",
      "nav-menu-auctions": "Aukcie",
      "nav-menu-inspiration": "Inšpirácie",
      "nav-menu-news": "Prečítajte si",
      "nav-menu-my-favorites": "Moje obľúbené",
      "nav-menu-faq": "Pomoc",
      "nav-menu-contact": "Kontakt",
      "nav-home": "Úvod",
      "text-homepage": "Úvodná stránka",
      "text-all-ads": "Všetky inzeráty",
      "text-all-inspirations": "Všetky inšpirácie",
      "text-all-news": "Všetky články",
      "text-faq": "Najčastejšie otázky",
      "text-read-news": "Prečítajte si",
      "text-why-submit-ad": "Prečo inzerovať u nás",
      "text-in-progress": "Pripravuje sa...",
      "text-contact": "Kontakt",
      "user-avatar": "užívateľský avatar",
      "text-edit-profile": "Upraviť profil",
      "text-edit-main-image": "Upraviť úvodný obrázok",
      "auth-menu-profile": "Upraviť profil",
      "auth-menu-settings-notifications": "Notifikácie",
      "auth-menu-my-orders": "Moje nákupy",
      "auth-menu-my-sales": "Moje predaje",
      "auth-menu-waiting-orders": "Čakajúce objednávky",
      "auth-menu-my-ads": "Moje inzeráty",
      "auth-menu-logout": "Odhlásiť sa",
      "join-button": "Prihlásiť",
      "text-log-in-to-show-page": "Prihláste sa pre zobrazenie obsahu stránky",
      "change-locale": "Prepnúť jazyk",
      "text-search": "Hľadať",
      "common:text-search": "Hľadať",
      "text-fish": "Ryby",
      "text-ads": "Inzeráty",
      "text-browse-ads": "Prehľadávať inzeráty",
      "text-report-ad": "Nahlásiť inzerát",
      "text-reporting-ad": "Nahlásenie inzerátu",
      "text-reporting-details": "Dôvod nahlásenia",
      "text-report-inspiration": "Nahlásiť inšpiráciu",
      "text-reporting-inspiration": "Nahlásenie inšpirácie",
      "text-submit-report": "Odoslať",
      "text-my-ads": "Moje aktívne inzeráty",
      "text-my-offers": "Moje aktívne ponuky",
      "text-my-requests": "Moje aktívne dopyty",
      "text-my-auctions": "Moje aktívne aukcie",
      "text-my-inspiration": "Moja inšpirácia",
      "text-my-favorites": "Moje obľúbené",
      "text-google-play": "Google Play",
      "text-apple-store": "Apple Store",
      "text-favorite": "Obľúbené",
      "text-favorite-ads": "Obľúbené inzeráty",
      "text-favorite-inspirations": "Obľúbené inšpirácie",
      "text-favorite-news": "Obľúbené články",
      "text-inspiration": "Inšpirácia",
      "text-latest-inspiration": "Najnovšia inšpirácia",
      "text-latest-inspiration-subtitle": "Poďte sa pochváliť svojimi fotkami a inšpirovať ostatných!",
      "text-latest-news": "Prečítajte si",
      "text-latest-news-subtitle": "Zaujímavosti zo sveta akvaristiky a vodných živočíchov",
      "text-latest-ads": "Najnovšie inzeráty",
      "text-latest-ads-subtitle": "Ponorte sa do akvaristiky a pozrite sa, čo je u nás nové!",
      "text-latest-reference": "Skúsenosti našich užívateľov",
      "text-latest-reference-subtitle": "Čo hovoria o ForAquarist milovníci akvaristiky?",
      "text-recommend": "Odporučiť",
      "text-recommendation-message": "Možno vás bude zaujímať inzerát:",
      "text-recommended-ads": "Mohlo by vás zaujímať",
      "text-recommended-ads-subtitle": "Vybrali sme najzaujímavejšie ponuky",
      "text-recommended-my-product": "Ponúknite váš inzerát",
      "text-recommended-my-product-subtitle-1": "Ako odpoveď na dopyt môžete ponúknuť jeden zo svojich inzerátov. Ak žiadny taký nemáte, vytvorte nový",
      "text-recommended-my-product-subtitle-2": "tu",
      "text-recommended-my-product-subtitle-3": "a potom odpovedzte na dopyt.",
      "text-recommended-my-product-result-1": "K inzerátu dopytu",
      "text-recommended-my-product-result-2": "bol pridaný komentár s vašou ponukou",
      "text-show-message": "Zobraziť komentár",
      "text-mobile-app": "Mobilná aplikácia",
      "text-mobile-app-title": "Vyskúšajte aplikáciu ForAquarist!",
      "text-mobile-app-subtitle": "Moderné trhovisko na predaj, nákup a inzerciu akvaristiky vždy po ruke",
      "text-mobile-app-instructions": "Aplikáciu ForAquarist si môžete stiahnuť na týchto odkazoch",
      "text-could-interest-you": "Mohlo by vás zaujímať",
      "text-for-aquarist": "For Aquarist",
      "text-burger-menu": "Menu",
      "text-close": "Zavrieť",
      "text-clear": "Odstrániť",
      "text-or": "Alebo",
      "text-allow-geolocation-here": "Povolte zdieľanie vašej aktuálnej polohy tu",
      "text-allow-geolocation": "Pre zobrazenie vzdialenosti prosím povolte zdieľanie vašej aktuálnej polohy",
      "text-gdpr": "GDPR",
      "text-privacy-policy": "zasadami ochrany osobných údajov",
      "text-login-auth-message": "Pre pridanie do košíka sa prosím prihláste alebo",
      "text-login-auth-message-link-title": "zaregistrujte",
      "text-register-auth-message": "Zaregistrujte sa, aby ste mohli nakupovať, pridávať inzeráty a využívať množstvo ďalších funkcií.",
      "text-register-succes-message": "Ďakujeme za registráciu.<br><br>Skontrolujte si, prosím, e-mailovú schránku, kam sme Vám poslali e-mail s aktivačným odkazom.",
      "text-add-supplier-info": "Pridať ďalšie kontaktné údaje",
      "text-offer": "Ponuka",
      "text-request": "Dopyt",
      "text-auction": "Aukcia",
      "text-added-to-cart": "Pridané do košíka",
      "text-in-cart": "V košíku",
      "text-minus": "mínus",
      "text-plus": "plus",
      "common:text-read-more": "Čítať viac",
      "text-show-more": "Zobraziť viac",
      "text-show-all": "Zobraziť všetko",
      "text-previous": "Predchádzajúce",
      "text-next": "Ďalšie",
      "common:text-less": "Menej",
      "common:text-item": "položiek,položka,položky",
      "text-item-header": "Položka",
      "text-quantity-header": "Počet",
      "text-price-header": "Cena",
      "text-add-ad": "Pridať inzerát",
      "text-add-ad-hint-title": "Pokiaľ toho ponúkate viac, vytvorte radšej viac inzerátov – lepšie ich potom nájdu ostatní pri vyhľadávaní podľa kategórií. Uložený inzerát môžete nielen upravovať, ale aj ľahko kopírovať cez tlačidlo v pravom hornom rohu na detaile inzerátu.",
      "text-ad-name-placeholder": "Obvykle slovenské alebo latinské meno, prípadne názov tovaru",
      "text-ad-content-placeholder": "Popis ponúkaného vrátane informácií o možnej doprave. Môžete uviesť aj doplňujúce informácie k chovu, o ponúkanom druhu a pod.",
      "text-add-new-ad": "Vložiť nový inzerát",
      "text-add-photo": "Pridať fotku",
      "text-add-inspiration": "Pridať inšpiráciu",
      "text-copy-ad": "Kopírovať inzerát",
      "text-ad-delete-title": "Naozaj zmazať inzerát?",
      "text-want-to-buy": "Chcem kúpiť",
      "text-to-offer": "Ponúknuť",
      "text-cart": "Košík",
      "text-shopping-cart": "Nákupný košík",
      "text-add-to-favorites": "Pridať do Moje obľúbené",
      "text-remove-from-favorites": "Odobrať z Moje obľúbené",
      "text-pieces-available": "dostupných kusov,dostupný kus,dostupné kusy",
      "text-pieces-required": "požadovaných kusov,požadovaný kus,požadované kusy",
      "text-details": "Podrobnosti",
      "text-comments": "Komentáre",
      "text-order": "Objednávka",
      "text-order-detail": "Detail nákupu",
      "text-rating": "Hodnotenie",
      "text-added-rating": "Vložené hodnotenie:",
      "text-order-buyer-rating": "Vaše hodnotenie nákupu",
      "text-order-seller-rating": "Vaše hodnotenie objednávky",
      "text-order-review": "Slovné hodnotenie",
      "text-update-order-review": "Upraviť hodnotenie",
      "text-order-review-waiting": "Používateľ {name} už ohodnotil túto objednávku. Ohodnoťte túto objednávku a jeho hodnotenie sa zobrazí.",
      "text-phone": "Telefón",
      "text-cart-date": "Dátum košíka",
      "text-order-date": "Dátum obj.",
      "text-unfinished-cart": "Rozpracovaný košík",
      "text-unfinished-cart-details": "Detaily rozpracovaného košíka",
      "text-order-details": "Detaily objednávky",
      "text-location": "Lokalita",
      "text-distance": "Vzdialenosť",
      "text-address": "Adresa",
      "text-insert-my-info": "Vložiť moje údaje",
      "text-insert-billing-address": "Vložiť fakturačnú adresu",
      "text-insert-delivery-address": "Vložiť doručovaciu adresu",
      "text-insert-email-address": "Vložiť e-mailovú adresu",
      "text-insert-phone-number": "Vložiť telefónne číslo",
      "text-upload-highlight": "Nahrajte obrázky",
      "text-upload-message": "kliknutím alebo pretiahnutím.",
      "text-img-format": "PNG, JPG",
      "text-delivery-address": "Doručovacia adresa",
      "text-submit-order": "Odoslať objednávku",
      "text-submit-order-note-1": "Odoslanie objednávky nie je záväzné, ale je nevyhnutné, aby ste sa potom v chate mohli s predávajúcim dohodnúť ohľadom platby, doručenia, a pod.",
      "text-submit-order-note-2": "Pokiaľ sa nedohodnete, má možnosť Vašu objednávku odmietnuť.",
      "text-submit-order-note-3": "Objednávka je pre obe strany záväzná až po prijatí objednávky predávajúcim.",
      "text-cancel-order": "Zrušiť objednávku",
      "text-abandonment-reason": "Dôvod zrušenia",
      "placeholder-abandonment-note": "Budeme radi, keď nám napíšete dôvod zrušenia objednávky.",
      "text-shipping": "Doprava",
      "text-billing-data": "Fakturačné údaje",
      "text-billing-address": "Fakturačná adresa",
      "text-billing-and-delivery-address": "Fakturačná a doručovacia adresa",
      "text-payment-fee": "Poplatok za platbu",
      "text-delivery-fee": "Poplatok za doručenie",
      "text-items-count": "Položiek",
      "text-total-price": "Celková cena",
      "text-total": "Celkom",
      "text-out-stock": "Nie je na sklade",
      "text-product-status-closed": "Inzerát je ukončený",
      "text-insert-image": "Vložiť obrázok",
      "text-insert-profile-image": "Nahrať profilovú fotku",
      "text-product-unavailable-title": "Detail nie je možné zobraziť, inzerát nie je dostupný.",
      "text-product-unavailable-subtitle": "Inzerát bol buď zmazaný, skrytý, tovar už nie je k dispozícii, alebo je chyba v odkaze.",
      "text-product-image": "Obrázok",
      "text-product-image-edit": "Editácia obrázku",
      "text-supplier-unavailable-title": "Detaily predajcu nie je možné zobraziť.",
      "text-supplier-unavailable-subtitle": "Profil predajcu bol buď vymazaný, skrytý, alebo je chyba v odkaze.",
      "text-supplier-image-edit": "Editácia obrázku",
      "text-supplier-image-delete-title": "Naozaj zmazať obrázok?",
      "text-inspiration-unavailable-title": "Detail nie je možné zobraziť, inšpirácia nie je dostupná.",
      "text-inspiration-unavailable-subtitle": "Inšpirácia bola buď zmazaná, skrytá, alebo je chyba v odkaze.",
      "text-inspiration-edit": "Editácia inšpirácie",
      "text-news-unavailable-title": "Detail nie je možné zobraziť, článok nie je dostupný.",
      "text-news-unavailable-subtitle": "Článok bol buď zmazaný, skrytý, alebo je chyba v odkaze.",
      "text-reference-unavailable-title": "Detail nie je možné zobraziť, referencia nie je dostupná.",
      "text-reference-unavailable-subtitle": "Referencia bola buď zmazaná, skrytá, alebo je chyba v odkaze.",
      "text-ad-edit": "Editácia inzerátu",
      "text-load-more": "Načítať ďalšie",
      "text-availability": "Dostupnosť",
      "text-category": "Kategória",
      "text-category-subtitle": "Čo hľadáte?",
      "text-home": "Domov",
      "text-user": "Používateľ",
      "text-edit": "Upraviť",
      "text-ad-title": "Názov inzerátu",
      "text-title": "Názov",
      "text-firstName": "Meno",
      "text-lastName": "Priezvisko",
      "text-namePrefix": "Titul",
      "text-nameSuffix": "Titul za menom",
      "text-company": "Názov firmy",
      "text-companyPerson": "Kontaktná osoba",
      "text-companyNo": "IČO",
      "text-companyNo-required": "IČO je vyžadované",
      "text-companyVatNo": "DIČ",
      "text-street": "Ulica",
      "text-streetNo": "Číslo",
      "text-houseNo": "Č. domu",
      "text-city": "Mesto",
      "text-zip": "PSČ",
      "text-state": "Štát",
      "text-country": "Krajina",
      "text-load-from-ares": "Načítať údaje z registra ARES",
      "text-add-company-info": "Zadať firemné údaje",
      "text-use-delivery-address": "Použiť fakturačnú adresu ako doručovaciu",
      "text-gallery": "Galéria",
      "text-gallery-info-title": "Obrázky, ktoré si nahrajete do Galérie, sú zobrazené v detaile vášho profilu, kde si ich môžu prezrieť aj ostatní užívatelia.",
      "text-add-new-photo": "Pridať novú inšpiráciu",
      "text-new-variant": "Nová varianta",
      "text-add-new-variant": "Pridať novú variantu",
      "text-add-copy-variant": "Pridať kópiu varianty",
      "text-add-parameter": "Pridať parameter",
      "text-parameters": "Parametre",
      "text-edit-ad": "Upraviť inzerát",
      "text-edit-photo": "Upraviť inšpiráciu",
      "text-edit-variant": "Upraviť variantu",
      "text-delete-variant": "Zmazať variantu",
      "text-set-as-main-image": "Nastaviť ako hlavný obrázok",
      "text-change-password": "Zmeniť heslo",
      "profile-sidebar-profile": "Profil",
      "profile-sidebar-settings-notifications": "Notifikácie",
      "profile-sidebar-my-orders": "Moje nákupy",
      "profile-sidebar-my-sales": "Moje predaje",
      "profile-sidebar-waiting-orders": "Čakajúce objednávky",
      "profile-sidebar-help": "Pomoc",
      "profile-sidebar-contact": "Napíšte nám",
      "text-contact-us": "Napíšte nám",
      "password-successful": "Heslo úspešne zmenené",
      "password-successful-please-login": "Heslo bylo úspešne zmenené, môžete sa prihlásiť",
      "error-credential-wrong": "Prihlasovacie údaje sú nesprávne!",
      "login-helper": "Prihláste sa pomocou e-mailu a hesla",
      "error-email-format": "Zadajte platnú emailovú adresu",
      "error-email-required": "Zadajte svoju e-mailovú adresu",
      "error-phone-required": "Zadajte svoje telefónne číslo",
      "error-phone-numeric": "Zadajte číselné telefónne číslo",
      "error-password-required": "Zadajte svoje heslo",
      "error-password-repeat-required": "Zopakujte svoje heslo",
      "error-subject-required": "Zadajte nadpis správy",
      "error-description-required": "Povedzte nám o tom viac",
      "error-ad-type-required": "Typ inzerátu je vyžadovaný",
      "error-ad-name-required": "Názov inzerátu je vyžadovaný",
      "error-ad-description-required": "Popis inzerátu je vyžadovaný",
      "error-ad-category-required": "Kategória inzerátu je vyžadovaná",
      "error-ad-availability-required": "Dostupnosť je vyžadovaná",
      "error-ad-price-required": "Cena je vyžadovaná",
      "error-stock-count-required": "Počet kusov je vyžadovaný",
      "error-in-stock-count-required": "Dostupnosť je „{name}“, nastavte preto prosím počet kusov väčší ako 0.",
      "error-ad-vat-rate-required": "Sadzba DPH je vyžadovaná",
      "error-ad-parameter-required": "Tento parameter je vyžadovaný",
      "error-content-image-required": "Obrázok je vyžadovaný",
      "error-content-name-required": "Názov fotky je vyžadovaný",
      "text-in-progress-now": "Práve prebieha...",
      "text-activate-account": "Aktivácia používateľského účtu",
      "text-account-activated": "Používateľský účet bol aktivovaný",
      "text-account-not-activated": "Používateľský účet zatiaľ nebol aktivovaný.",
      "text-activate-account-sent": "Odkaz na aktiváciu používateľského účtu bol znovu zaslaný na e-mail.",
      "text-send-activate-account": "Zaslať aktivačný odkaz e-mailom",
      "text-delete-account-title": "Zmazanie používateľského účtu",
      "text-confirm-delete-account": "To nás mrzí, naozaj si želáte zmazať svoj používateľský účet? Ak áno, pošleme vám odkaz pre potvrdenie zmazania používateľského účtu do vašej e-mailovej schránky.",
      "text-send-email-link": "Odoslať odkaz na e-mail",
      "text-confirm-delete-account-title": "Potvrďte zmazanie používateľského účtu",
      "text-confirm-delete-account-button": "Áno, chcem zmazať svoj používateľský účet",
      "text-account-deleted": "Používateľský účet bol zmazaný",
      "text-login": "Prihlásiť sa",
      "text-no-account": "Ešte nemáte používateľský účet?",
      "text-already-account": "Už máte účet?",
      "text-register": "Registrovať",
      "text-register-here": "Zaregistrujte sa tu",
      "text-old-password": "Staré heslo",
      "text-new-password": "Nové heslo",
      "text-confirm-password": "Potvrdiť heslo",
      "error.old-password.invalid": "Staré heslo nie je platné",
      "error-old-password-required": "Staré heslo je povinné",
      "error-new-password-required": "Zadajte nové heslo",
      "error-confirm-password": "Prosím, potvrďte heslo",
      "error-match-passwords": "Heslá by mali byť rovnaké",
      "error-name-required": "Zadajte svoje meno",
      "error-surname-required": "Zadajte svoje priezvisko",
      "forgot-password-email-helper": "Zadajte prosím váš prihlasovací e-mail. Pošleme vám naň odkaz pre obnovu hesla.",
      "forgot-password-token-helper": "Zadajte heslo, ktoré chcete použiť pre prihlásenie",
      "text-check-email-for-token": "Do schránky sme vám odoslali e-mail s odkazom pre obnovu hesla, skontrolujte prosím aj priečinok Nevyžiadaná pošta (spam).",
      "text-reset-password": "Obnoviť heslo",
      "text-back": "Späť",
      "text-back-to": "Späť na",
      "text-forgot-password": "Zabudnuté heslo?",
      "text-follow-us": "Sledujte nás",
      "text-questions-comments": "Otázky, komentáre alebo obavy?",
      "text-questions-and-comments": "Otázky a komentáre",
      "text-name": "Meno",
      "text-surname": "Priezvisko",
      "text-password": "Heslo",
      "text-write-email": "Napísať email",
      "text-email": "E-mail",
      "text-subject": "Predmet",
      "text-description": "Popis",
      "text-about": "Informácie o vás",
      "text-about-info": "Predstavte sa ostatným, môžete napísať, čomu sa venujete, na aké druhy sa zameriavate, čo vás zaujíma.",
      "text-delivery": "Informácie o ponúkanej doprave",
      "text-delivery-info": "Akú formu odovzdania alebo dopravy ponúkate? Napr. osobné odovzdanie, Zásielkovňa, atď.",
      "text-offered-delivery": "Ponúkaná doprava",
      "text-variant-content": "Popis",
      "text-variant-discount": "Popis zľavy",
      "text-variant-package": "Popis balenia",
      "text-use": "Použiť",
      "text-save": "Uložiť",
      "text-save-for-later": "Uložiť na neskôr",
      "text-save-and-publish": "Uložiť a zverejniť",
      "text-submit-email": "Odoslať e-mail",
      "text-loading": "Načítavanie...",
      "common:text-loading": "Načítavanie...",
      "text-signing-out": "Odhlásenie...",
      "text-no-result-found": "Nebol nájdený žiadny výsledok",
      "text-not-found": "Bohužiaľ, aktuálne tu nič nie je",
      "text-no-option-found": "Možnosť nenájdená",
      "text-add-option": "Pridať možnosť",
      "text-no-filtered-found": "Pre zadaný filter sa nič nenašlo. Upravte filter a skúste to znova.",
      "text-no-my-ads": "Ešte nemáte žiadny vložený inzerát, ale môžete to jednoducho napraviť.",
      "text-continue": "Pokračovať",
      "text-continue-to-homepage": "Pokračovať na úvodnú stránku",
      "text-continue-in-mobile-app": "Pokračovať v mobilnej aplikácii",
      "text-continue-shopping": "Pokračovať v nákupe",
      "text-proceed-order": "Dokončiť objednávku",
      "text-unavailable": "Nedostupné",
      "text-do-not-remove": "Neodstraňovať",
      "text-remove-item": "Odstrániť tovar",
      "text-remove-item-from-cart": "Odstrániť \"{name}\" z košíka?",
      "text-cancel": "Zrušiť",
      "text-create-copy": "Vytvoriť kópiu",
      "text-delete": "Zmazať",
      "text-delete-account": "Zmazať účet",
      "text-delete-avatar": "Zmazať profilovú fotku",
      "text-filter": "Filter",
      "text-clear-filters": "Zrušiť filter",
      "text-voice-search": "Hlasové vyhľadávanie",
      "text-sort-by": "Zoradiť podľa",
      "text-sort-by-newest": "Od najnovšieho",
      "text-sort-by-oldest": "Od najstaršieho",
      "text-sort-by-cheapest": "Od najlacnejšieho",
      "text-sort-by-expensive": "Od najdrahšieho",
      "text-sort-by-position": "Odporúčané",
      "text-your-order": "Vaša objednávka",
      "text-no-products": "V košíku zatiaľ nič nie je",
      "text-search-label": "Vyhľadávanie...",
      "common:text-search-placeholder": "Napíšte, čo hľadáte",
      "common:text-search-ad-placeholder": "Napíšte, aký inzerát hľadáte",
      "error-country-required": "Krajina je vyžadovaná",
      "error-city-required": "Mesto je vyžadované",
      "error-valid-city-required": "Mesto musí byť iné ako Heslo",
      "error-zip-required": "PSČ je vyžadované",
      "error-street-required": "Adresa ulice je vyžadovaná",
      "error-street-no-required": "Číslo domu je vyžadované",
      "error-no-orders": "Zatiaľ ste si nič neobjednali",
      "error-no-sales": "Zatiaľ si od vás nikto nič neobjednal",
      "error-no-carts": "Zatiaľ si od vás nikto nič nepridal do košíku",
      "change-password": "Zmeniť heslo",
      "text-submit": "Odoslať",
      "text-show-profile": "Zobraziť profil",
      "profile-update-successful": "Profil úspešne aktualizovaný",
      "profile-update-failed": "Aktualizácia profilu/fotky zlyhala",
      "profile-address-update-failed": "Aktualizácia adresy profilu zlyhala",
      "text-no-contact": "Nebol nájdený žiadny kontakt",
      "text-status": "Stav",
      "text-view": "Zobraziť",
      "text-supplier": "Predajca",
      "text-requester": "Dopytovateľ",
      "text-safe-shopping-with-us": "U nás sa nemusíte báť nakupovať",
      "text-facebook": "Facebook",
      "text-x-twitter": "X (Twitter)",
      "text-more-info": "Viac informácií",
      "text-price": "Cena",
      "text-price-note": "(ak nevyplníte, nastaví sa Cena dohodou)",
      "text-negotiable": "Dohodou",
      "text-price-negotiable": "Cena dohodou",
      "text-some-items-have-price-negotiable": "Niektoré položky majú cenu dohodou",
      "text-vat-rate": "Sadzba DPH",
      "text-stock-count": "Počet kusov",
      "text-gtin": "EAN",
      "text-code-internal": "Interný kód",
      "text-code-supplier": "Kód predajcu",
      "text-add-more-ads-link": "Pridajte viac inzerátov",
      "text-add-more-ads-title": "a určite sa niekto čoskoro ozve.",
      "text-no-orders-title-1": "Zatiaľ ste si nič neobjednali, pozrite sa, či niečo nemáte",
      "text-no-orders-title-2": "v košíku.",
      "text-no-orders-subtitle-1": "Alebo si prezrite",
      "text-no-orders-subtitle-2": "všetky inzeráty,",
      "text-no-orders-subtitle-3": "kde určite nájdete niečo zaujímavé.",
      "text-no-order-selected": "Prosím, vyberte objednávku, ktorú chcete zobraziť",
      "text-no-order-details": "Je nám ľúto, táto objednávka nebola nájdená :(",
      "text-no-unfinished-found": "Je nám ľúto, nebol nájdený žiadny rozpracovaný košík :(",
      "text-no-unfinished-selected": "Prosím, vyberte rozpracovaný košík, ktorý chcete zobraziť",
      "text-no-unfinished-details": "Je nám ľúto, tento rozpracovaný košík nebol nájdený :(",
      "heading-aquarist-main": "Komunitné trhovisko pre akvaristov",
      "subheading-aquarist-main": "Inzeráty, inšpirácie, komentáre a zaujímavé články",
      "heading-aquarist-main-2": "Všetko pre vaše akvárium",
      "subheading-aquarist-main-2": "Nájdite chovateľa vo svojom okolí a nakupujte ako na e&#8209;shope",
      "heading-aquarist-main-5": "Prečítajte si",
      "subheading-aquarist-main-5": "Novinky a zaujímavé články<br>zo sveta akvaristiky",
      "heading-aquarist-category": "Ponuky pre vaše akvárium",
      "subheading-aquarist-category": "Nájdite chovateľa vo svojom okolí<br>a nakupujte ako na e&#8209;shope",
      "heading-aquarist-ads": "Ponuky pre vaše akvárium",
      "subheading-aquarist-ads": "Nájdite chovateľa vo svojom okolí<br>a nakupujte ako na e&#8209;shope",
      "heading-aquarist-my-ads": "Moje inzeráty",
      "subheading-aquarist-my-ads": "Upravte, zverejnite alebo skryte svoje inzeráty",
      "heading-aquarist-offers": "Ponuky pre vaše akvárium",
      "subheading-aquarist-offers": "Nájdite chovateľa vo svojom okolí<br>a nakupujte ako na e&#8209;shope",
      "heading-aquarist-requests": "Dopyty",
      "subheading-aquarist-requests": "Zadajte si dopyt a počkajte na ponuku",
      "heading-aquarist-auctions": "Aukcie",
      "subheading-aquarist-auctions": "Nakupujte v aukcii a získajte najlepšiu cenu",
      "heading-aquarist-favorites": "Moje obľúbené inzeráty a inšpirácie",
      "subheading-aquarist-favorites": "Prezrite si svoje obľúbené inzeráty a inšpirácie",
      "heading-aquarist-inspiration": "Inšpirácia",
      "subheading-aquarist-inspiration": "Pochváľte sa svojimi fotkami a inšpirujte ostatných",
      "heading-aquarist-news": "Prečítajte si",
      "subheading-aquarist-news": "Novinky a zaujímavé články zo sveta akvaristiky",
      "heading-aquarist-reference": "Referencie",
      "subheading-aquarist-reference": "Referencie užívateľov",
      "button-cancel": "Zrušiť",
      "button-delete": "Zmazať",
      "delete-item-confirm": "Potvrdiť zmazanie tejto položky",
      "common:text-forgot-password": "Zabudli ste heslo?",
      "see-more": "Viac informácií",
      "text-more": "Viac",
      "activate-account.error.unknown": "Nastala chyba. Skúste to prosím neskôr.",
      "activate-account.error.unauthorized": "Nastala chyba overenia prístupu.",
      "activate-account.error.already-set": "Účet bol cez tento odkaz už aktivovaný.",
      "activate-account.error.forbidden": "Pre aktiváciu účtu sa najprv odhláste.",
      "activate-account.error.not-found": "Užívateľský účet nebol nájdený.",
      "delete-account.error.unknown": "Nastala chyba. Skúste to prosím neskôr.",
      "delete-account.error.already-set": "Účet bol cez tento odkaz už zmazaný.",
      "delete-account.error.not-found": "Užívateľský účet nebol nájdený.",
      "forgot-password.error.unknown": "Nastala chyba. Skúste to prosím znova.",
      "forgot-password.error.already-set": "Odkaz na zmenu hesla bol už predtým použitý, ak si svoje heslo nepamatáte, použite ",
      "forgot-password.error.already-set.link.label": "Zabudnuté heslo",
      "forgot-password.error.forbidden": "Zmena hesla nie je povolená pre návštevníkov.",
      "forgot-password.error.not-found": "Pre zadaný email nebol nájdený žiaden užívateľ.",
      "error.unauthorized": "Nedostupné pre neprihlásených užívateľov.",
      "error.customer-detail.load": "Načítanie detailu používateľa zlyhalo.",
      "error.register.unknown": "Nastala chyba. Skúste to prosím neskôr.",
      "error.register.user-exists": "Užívateľ s týmto e-mailom je už registrovaný. Ak ste to vy a nepamätáte si heslo, použite ",
      "error.register.user-exists.link.label": "Zabudnuté heslo",
      "error.cart-detail.load": "Načítanie detailu košíka zlyhalo",
      "error.cart-detail.load.comments": "Načítanie komentárov u košíku zlyhalo",
      "error.cart-detail.add.comment": "Pridanie komentára zlyhalo",
      "error.news-detail.load": "Načítanie detailu obsahu zlyhalo",
      "error.reference-detail.load": "Načítanie detailu obsahu zlyhalo",
      "error.content-detail.load": "Načítanie detailu obsahu zlyhalo",
      "error.content-detail.favorite": "Zmena obľúbeného obsahu zlyhala",
      "error.content-detail.likes": "Zmena páči sa u obsahu zlyhala",
      "error.content-detail.load.comments": "Načítanie komentárov u obsahu zlyhalo",
      "error.content-detail.add.comment": "Pridanie komentára zlyhalo",
      "error.product-detail.load": "Načítanie detailu produktu zlyhalo",
      "error.product-detail.favorite": "Zmena obľúbeného produktu zlyhala",
      "error.product-detail.likes": "Zmena páči sa u produktu zlyhala",
      "error.product-detail.load.comments": "Načítanie komentárov u produktu zlyhalo",
      "error.product-detail.add.comment": "Pridanie komentára zlyhalo",
      "error.recommend-product.add.comment": "Pridanie ponuky zlyhalo",
      "error.product-list.load": "Načítanie zoznamu produktov zlyhalo",
      "error.carts.load": "Načítanie košíkov zlyhalo",
      "error.cart.add.item": "Pridanie do nákupného košíka zlyhalo",
      "error.cart.update.item": "Aktualizácia nákupného košíka zlyhala",
      "error.cart.remove.item": "Odstránenie z nákupného košíka zlyhalo",
      "error.cart.load": "Načítanie košíka zlyhalo",
      "error.cart.checkout.order": "Dokončenie objednávky v košíku zlyhalo",
      "error.cart.cancel.order": "Zrušenie objednávky v košíku zlyhalo",
      "error.cart.consent.false": "Prosím, súhlaste s obchodnými podmienkami",
      "error.register.consent.conditions.false": "Pre registráciu prosím súhlaste s obchodnými podmienkami",
      "error.register.consent.privacy.false": "Pre registráciu prosím súhlaste so zásadami ochrany osobných údajov",
      "error.orders.load": "Načítanie objednávok zlyhalo",
      "error.order-detail.load": "Načítanie detailu objednávky zlyhalo",
      "error.supplier.create": "Vytvorenie predajcu zlyhalo",
      "error.auth.me.load": "Načítanie užívateľa zlyhalo",
      "error.product.create": "Vytvorenie inzerátu zlyhalo",
      "error.product.update": "Uloženie inzerátu zlyhalo",
      "error.product.update.status": "Zmena stavu produktu zlyhala",
      "error.variant.image.create": "Vytvorenie obrázku k inzerátu zlyhalo",
      "error.variant.image.update": "Úprava obrázka z inzerátu zlyhala",
      "error.variant.image.delete": "Vymazanie obrázka z inzerátu zlyhalo",
      "error.inspiration.create": "Pridanie obrázka zlyhalo",
      "error.inspiration.update": "Úprava obrázka zlyhala",
      "error.inspiration.update.status": "Zmena stavu obrázka zlyhala",
      "error.inspiration.delete": "Vymazanie obrázka zlyhalo",
      "error.supplier.image.update": "Úprava obrázka zlyhala",
      "error.supplier.image.delete": "Vymazanie obrázka zlyhalo",
      "error.supplier.rating.load": "Načítanie hodnotenia predajcu zlyhalo",
      "error.contact-form.submit": "Odoslanie kontaktného formulára zlyhalo",
      "error.report-ad-form.submit": "Odoslanie nahlásenia inzerátu zlyhalo",
      "error.report-content-form.submit": "Odoslanie nahlásenia inšpirácie zlyhalo",
      "error.filter.maxKmFromHome.check": "Zadajte ešte prosím miesto alebo PSČ, prípadne povoľte zdieľanie polohy.",
      "error.filter.maxKmFromLocation.check": "Zadajte ešte prosím miesto alebo PSČ, prípadne povoľte zdieľanie polohy.",
      "error.filter.maxKm.check": "Zadajte ešte prosím miesto alebo PSČ, prípadne povoľte zdieľanie polohy.",
      "error.geolocation.denied": "Zdieľanie aktuálnej polohy bolo zamietnuté. Pre použitie tohto filtra je potrebné povoliť zdieľanie svojej polohy v prehliadači.",
      "error.order.rating.create": "Uloženie hodnotenia zlyhalo",
      "error.setting.notification.email.update": "Uloženie nastavenia notifikačných emailov zlyhalo",
      "success.setting.notification.email.update": "Nastavenie notifikačných emailov bolo upravené",
      "success.geolocation.accepted": "Zdieľanie aktuálnej polohy bolo povolené",
      "success.delete-account-link.sent": "Odkaz na vymazanie užívateľského konta bol odoslaný e-mailom",
      "success.cart.checkout.order": "Objednávka v košíku bola vytvorená",
      "success.cart.cancel.order": "Objednávka v košíku bola zrušená",
      "success.product.create": "Inzerát bol založený",
      "success.product.update": "Inzerát bol upravený",
      "success.product.delete": "Inzerát bol vymazaný",
      "success.variant.image.update": "Obrázok bol upravený",
      "success.variant.image.delete": "Obrázok bol vymazaný",
      "success.inspiration.create": "Inšpirácia bola vytvorená",
      "success.inspiration.update": "Inšpirácia bola upravená",
      "success.inspiration.delete": "Inšpirácia bola odstránená",
      "success.supplier.image.update": "Obrázok bol upravený",
      "success.supplier.image.delete": "Obrázok bol odstránený",
      "success.supplier.blocked.user": "Užívateľ bol zablokovaný",
      "success.contact-form.submit": "Správa z kontaktného formulára bola odoslaná",
      "success.report-ad-form.submit": "Nahlásenie inzerátu bolo odoslané",
      "success.report-content-form.submit": "Nahlásenie inšpirácie bolo odoslané",
      "success.recommend-product.submit": "Ponuka bola odporúčaná",
      "success.order.rating.create": "Hodnotenie bolo uložené",
      "success.order.rating.update": "Hodnotenie bolo upravené",
      "success.order.status.update": "Stav objednávky bol upravený",
      "text-views": "Počet zobrazení",
      "text-likes": "Páči sa mi to",
      "text-unit-km": "km",
      "text-unit-pc": "ks",
      "text-add-comment": "Pridať komentár",
      "text-ask-or-add-comment": "Opýtajte sa alebo pridajte komentár k inzerátu",
      "text-send": "Odoslať",
      "text-agree-with": "Súhlasím s",
      "text-agree-withs": "Súhlasím s",
      "text-terms-privacy-policy": "zásadami ochrany osobných údajov",
      "text-terms-and-conditions": "Obchodné podmienky",
      "text-with-terms-and-conditions": "obchodnými podmienkami",
      "text-receiving-marketing": "marketingovou komunikáciou",
      "text-order-status-new": "Čaká na prijatie predajcom",
      "text-order-status-accepted": "Objednané",
      "text-order-status-refused": "Odmietnuté",
      "text-order-status-waiting": "Čaká na vybavenie",
      "text-order-status-paid": "Zaplatené",
      "text-order-status-pickup": "Na vyzdvihnutie",
      "text-order-status-processed": "Spracované",
      "text-order-status-returned": "Vrátené",
      "text-order-status-sent": "Odoslané",
      "text-order-status-undelivered": "Nedoručené",
      "text-order-status-canceled": "Zrušené",
      "text-order-status-completed": "Dokončené",
      "text-order-status-deleted": "Vymazané",
      "text-accept-order": "Prijať objednávku",
      "text-accept-order-number": "Prijať objednávku #{number}",
      "text-confirm-accept-order-title": "Prijaťím objednávky potvrdzujete to, že objednávku vybavíte a tovar odošlete, tak ako bolo objednané, alebo ako ste sa dohodli so zákazníkom.",
      "text-refuse": "Odmietnuť",
      "text-refuse-order": "Odmietnuť objednávku",
      "text-refuse-order-number": "Odmietnuť objednávku #{number}",
      "text-confirm-refuse-order-title": "Naozaj chcete objednávku odmietnuť?",
      "text-block-user": "Zablokovať kupujúceho",
      "text-block-user-name": "Zablokovať kupujúceho {name}",
      "text-confirm-block-user-title": "Naozaj chcete zablokovať kupujúceho?",
      "text-blocked-user": "Zablokovaný užívateľ",
      "text-blocked-users": "Blokovaní užívatelia",
      "text-blocked-no-users": "Zatiaľ ste žiadnych užívateľov nezablokovali",
      "text-add-to-cart-blocked-notice": "Nie je možné nakupovať od tohto predajcu, pretože vás zablokoval.",
      "text-rate-order-blocked-notice": "Nie je možné hodnotiť tohto predajcu, pretože vás zablokoval.",
      "text-add-message-blocked-notice": "Nie je možné pridať komentár tomuto predajcovi, pretože vás zablokoval.",
      "text-cart-status-unfinished": "Nedokončený",
      "common:text-unread-message": "správ,správa,správy",
      "error-some-fields-required": "Prosím vyplňte povinné položky",
      "text-status-draft": "Koncept",
      "text-status-waiting": "Čakajúci",
      "text-status-active": "Aktívny",
      "text-status-hidden": "Skrytý",
      "text-hide": "Skryť",
      "text-distance-from-location": "Vzdialenosť od miesta",
      "text-distance-name-or-zip": "názov miesta alebo PSČ",
      "text-distance-name-or-zip-note": "Začnite písať a vyberte z ponúkaných možností:",
      "text-distance-name-or-zip-error": "Napíšte časť názvu miesta a potom vyberte z ponúkaných možností.",
      "text-distance-select": "vyberte vzdialenosť",
      "text-distance-near-km": "vo vzdialenosti do {number} km",
      "text-distance-from-my-location": "Vzdialenosť od vašej polohy",
      "text-max-distance-in-km": "Max. vzdialenosť v km",
      "text-notifications": "Notifikácie",
      "text-application": "Aplikácia",
      "text-unverified-user": "Neoverený e-mail",
      "text-unverified-buyer": "Neoverený e-mail",
      "text-unverified-seller": "Neoverený e-mail",
      "text-verified-user": "Overený e-mail",
      "text-verified-buyer": "Overený e-mail",
      "text-verified-seller": "Overený e-mail",
      "text-unknown-user": "Neznámy e-mail",
      "text-unknown-buyer": "Neznámy e-mail",
      "text-unknown-seller": "Neznámy e-mail",
      "text-verified-user-required-for-ad-publishing": "Pre zverejnenie inzerátu je potrebné aktivovať svoj užívateľský účet odkazom z e-mailu.",
      "text-save-ad-and-publish-later": "Uložte inzerát na neskôr a vykonajte zverejnenie inzerátu po aktivácii užívateľského účtu.",
      "text-one-active-cart-hint-title": "Kliknite na Dokončiť objednávku a prejdite do ďalšieho kroku pre upresnenie a odoslanie objednávky.",
      "text-more-active-carts-hint-title": "Máte vybraný tovar od viacerých predajcov, preto nižšie vidíte viac košíkov. Kliknite na Dokončiť objednávku a prejdite do ďalšieho kroku pre upresnenie a odoslanie objednávky.",
      "text-checkout-address-hint-title": "Zadajte svoje kontaktné údaje pre doručenie objednávky.",
      "text-checkout-messages-hint-title": "Pridaním komentára môžete navrhnúť požadovaný spôsob dopravy a platby, prípadne inak upresniť objednávku alebo položiť otázku.",
      "text-orders-hint-title": "V konverzácii pod položkami objednávky sa s predávajúcim dohodnite na spôsobe dodania a platby. Nezabudnite nakoniec predávajúceho ohodnotiť.",
      "text-orders-hint-subtitle": "Hneď ako vám predávajúci napíše novú správu, pošleme vám upozornenie e-mailom a notifikáciou prehliadača.",
      "text-sales-hint-title": "V konverzácii pod položkami objednávky sa s kupujúcim dohodnite na spôsobe dodania a platby. Ak sa dohodnete a budete objednávku realizovať, kliknite na tlačidlo Prijať objednávku. Ak sa nedohodnete, zvoľte Odmietnuť objednávku. Nezabudnite nakoniec kupujúceho ohodnotiť.",
      "text-sales-hint-subtitle": "Hneď ako vám kupujúci napíše novú správu, pošleme vám upozornenie e-mailom a notifikáciou prehliadača.",
      "text-chat-with-seller": "Napíšte si s predávajúcim",
      "text-chat-with-buyer": "Napíšte si s kupujúcim",
      "text-chat-hint-title": "Dohodnite sa spolu na spôsobe odovzdania a úhrady. Napravo v riadku konverzácie je ikonka pre vloženie obrázku alebo vašich kontaktných údajov.",
      "text-order-list-hint-title": "Pre aktualizáciu počtu správ a označení prečítaných musíte stránku znovu načítať (napr. klávesom F5 alebo ikonou pre obnovenie v prehliadači).",
      "text-is-read": "Prečítané",
      "text-cookie-consent-popup": "Táto webstránka používa na poskytovanie služieb, personalizáciu reklám a analýzu návštevnosti súbory cookie.",
      "text-cookie-consent-button-accept": "Povoliť všetko",
      "text-cookie-consent-button-reject": "Odmietnuť",
      "text-cookie-consent-button-configure": "Nastaviť možnosti",
      "text-cookie-consent-button-save": "Uložiť možnosti",
      "text-cookie-consent-authorization-default": "Funkčné (nezbytné) cookies sú potrebné pre správne fungovanie stránok a služieb na našich internetových stránkach.",
      "text-cookie-consent-authorization-analytics": "Analytické cookies sa používajú na analýzu a štatistiku prevádzky na internetových stránkach a neslúžia k identifikácii používateľov.",
      "text-cookie-consent-authorization-advertising": "Marketingové cookies pre cielenú reklamu a marketing zaznamenávajú vašu návštevu na internetových stránkach a odkazy, ktoré ste použili. To vám umožňuje ponúknuť také obchodné oznámenia, ktoré by vás mohli zaujímať.",
      "text-rules-of-use": "Pravidlá používania",
      "text-share": "Zdieľať",
      "text-share-ad": "Zdieľať inzerát",
      "text-copy-to-clipboard": "Skopírovať do schránky",
      "text-copied-to-clipboard": "Odkaz skopírovaný do schránky",
      "text-email-notification-settings": "Nastavenie e-mailových notifikácií",
      "text-send-notifications-by-email": "Želám si dostávať e-maily s upozornením na nasledujúce udalosti:",
      "text-if-seller": "Ak predávam",
      "text-if-buyer": "Ak nakupujem",
      "text-if-other": "Všeobecné",
      "text-settings-send-eshop-order-created": "nová objednávka môjho inzerovaného tovaru",
      "text-settings-send-eshop-cart-message-buyer": "nová správa od kupujúceho",
      "text-settings-send-eshop-cart-message-supplier": "nová správa od predajcu",
      "text-settings-send-eshop-product-follower": "nový inzerát od sledovaného užívateľa",
      "text-settings-send-eshop-product-variant-message": "nový komentár pri inzeráte",
      "text-settings-send-eshop-order-rating-created": "nové hodnotenie od iného používateľa",
      "text-settings-send-eshop-product-variant-auction-created": "sledovaný používateľ vytvoril novú aukciu",
      "text-settings-send-eshop-product-variant-auction-started": "začala aukcia od sledovaného používateľa",
      "text-settings-send-eshop-product-variant-auction-new-bid": "bol pridaný príhoz do aukcie, ktorej sa zúčastňujete",
      "text-settings-send-eshop-product-variant-auction-finished": "bola ukončená aukcia, ktorú ste vytvorili",
      "text-settings-send-eshop-product-variant-auction-winner": "boli ste vybraný ako víťaz aukcie",
      "text-follow-user": "Sledovať používateľa",
      "text-follow-user-hint": "Po zapnutí Sledovania používateľa budete dostávať upozornenia o jeho nových inzerátoch.",
      "text-following-user": "Sledujete požívateľa",
      "text-following-users": "Sledovaní uživatelé",
      "text-following-no-users": "Zatiaľ nikoho nesledujete.",
      "text-starting-bid": "Vyvolávacia cena",
      "text-start-at": "Začať od",
      "text-finish-at": "Ukončiť v",
      "text-auction-starting-in": "Aukcia začína za",
      "text-auction-finishing-in": "Aukcia končí za",
      "text-reserve-price": "Minimálna predajná cena",
      "text-min-increment": "Minimálny prírastok",
      "text-pick-winner-type": "Spôsob výberu víťaza",
      "text-automatically": "Automaticky",
      "text-manually": "Manuálne",
      "error-auction-starting-bid-required": "Vyvolávacia cena je povinná",
      "error-auction-start-at-required": "Začať od je povinné",
      "error-auction-finish-at-required": "Ukončiť v je povinné",
      "error-auction-finish-at-less-than-start-at": "Dátum konca musí byť väčší ako dátum začiatku",
      "text-countdown-days": "D",
      "text-countdown-hours": "H",
      "text-countdown-minutes": "M",
      "text-countdown-seconds": "S",
      "text-auction-finished": "Aukcia ukončená",
      "text-placing-bid": "Môj príhoz",
      "text-place-bid": "Prihodiť",
      "success.auction-bid.create": "Príhoz {amount} {sign} bol úspešne odoslaný",
      "error.auction-bid.create": "Odoslanie príhozu {amount} {sign} zlyhalo",
      "error.auction-bid.minimal": "Príhoz musí byť väčší ako {amount} {sign}",
      "error.auction-bid.load": "Načítanie príhozov zlyhalo",
      "text-last-auction-bids": "Posledné príhozy",
      "text-pick-auction-winner": "Výber víťaza aukcie",
      "text-auction-winner": "Víťaz aukcie",
      "text-auction-closed-duplicate-to-edit": "Aukcia bola ukončená, nie je možné vykonať ďalšie zmeny. Použite tlačidlo Kopírovať inzerát v pravom hornom rohu pre vytvorenie novej aukcie.",
      "text-emoji-group-smileys-people": "Smajlíky a ľudia",
      "text-emoji-group-animals-nature": "Zvieratá a príroda",
      "text-emoji-group-food-drink": "Jedlo a nápoje",
      "text-emoji-group-activities": "Aktivity",
      "text-emoji-group-travel-places": "Cestovanie a miesta",
      "text-emoji-group-objects": "Predmety",
      "text-emoji-group-symbols": "Symboly",
      "text-emoji-group-flags": "Vlajky"
    },
    "pl": {
      "text-logo": "Logo",
      "text-heading": "Nagłówek",
      "404-heading": "Kod błędu: {code}",
      "404-sub-heading": "Ups! Wystąpił błąd lub strona nie istnieje",
      "404-back-home": "Powrót do strony głównej",
      "nav-menu-category": "Kategorie",
      "nav-menu-offers": "Oferty",
      "nav-menu-requests": "Zapytania ofertowe",
      "nav-menu-auctions": "Aukcje",
      "nav-menu-inspiration": "Inspiracje",
      "nav-menu-news": "Przeczytaj",
      "nav-menu-my-favorites": "Moje ulubione",
      "nav-menu-faq": "Pomoc",
      "nav-menu-contact": "Kontakt",
      "nav-home": "Wstęp",
      "text-homepage": "Strona główna",
      "text-all-ads": "Wszystkie ogłoszenia",
      "text-all-inspirations": "Wszystkie inspiracje",
      "text-all-news": "Wszystkie artykuły",
      "text-faq": "Najczęściej zadawane pytania",
      "text-read-news": "Przeczytaj",
      "text-why-submit-ad": "Dlaczego warto dodawać ogłoszenia",
      "text-in-progress": "Przygotowuje się...",
      "text-contact": "Kontakt",
      "user-avatar": "awatar użytkownika",
      "text-edit-profile": "Edytuj profil",
      "text-edit-main-image": "Edytuj główny obraz",
      "auth-menu-profile": "Edytuj profil",
      "auth-menu-settings-notifications": "Powiadomienie",
      "auth-menu-my-orders": "Moje zamówienia",
      "auth-menu-my-sales": "Moje sprzedaże",
      "auth-menu-waiting-orders": "Oczekujące zamówienia",
      "auth-menu-my-ads": "Moje ogłoszenia",
      "auth-menu-logout": "Wyloguj się",
      "join-button": "Zaloguj sie",
      "text-log-in-to-show-page": "Zaloguj się, aby zobaczyć zawartość strony",
      "change-locale": "Zmień język",
      "text-search": "Szukaj",
      "common:text-search": "Szukaj",
      "text-fish": "Ryby",
      "text-ads": "Ogłoszenia",
      "text-browse-ads": "Przeglądaj ogłoszenia",
      "text-report-ad": "Zgłoś ogłoszenie",
      "text-reporting-ad": "Zgłaszanie ogłoszenia",
      "text-reporting-details": "Powód zgłoszenia",
      "text-report-inspiration": "Zgłoś inspirację",
      "text-reporting-inspiration": "Zgłaszanie inspiracji",
      "text-submit-report": "Wyślij",
      "text-my-ads": "Moje aktywne ogłoszenia",
      "text-my-offers": "Moje aktywne oferty",
      "text-my-requests": "Moje aktywne zapytania",
      "text-my-auctions": "Moje aktywne aukcje",
      "text-my-inspiration": "Moja inspiracja",
      "text-my-favorites": "Moje ulubione",
      "text-google-play": "Google Play",
      "text-apple-store": "Apple Store",
      "text-favorite": "Ulubione",
      "text-favorite-ads": "Ulubione ogłoszenia",
      "text-favorite-inspirations": "Ulubione inspiracje",
      "text-favorite-news": "Ulubione artykuły",
      "text-inspiration": "Inspiracja",
      "text-latest-inspiration": "Najnowsze inspiracje",
      "text-latest-inspiration-subtitle": "Pochwal się swoimi zdjęciami i inspiruj innych!",
      "text-latest-news": "Czytać",
      "text-latest-news-subtitle": "Ciekawostki ze świata akwariów i zwierząt wodnych",
      "text-latest-ads": "Najnowsze ogłoszenia",
      "text-latest-ads-subtitle": "Zanurz się w akwarystyce i zobacz, co nowego u nas!",
      "text-latest-reference": "Doświadczenia naszych użytkowników",
      "text-latest-reference-subtitle": "Co miłośnicy akwarystyki mówią o ForAquarist?",
      "text-recommend": "Poleć",
      "text-recommendation-message": "Może zainteresuje Cię ogłoszenie:",
      "text-recommended-ads": "To może Cię zainteresować",
      "text-recommended-ads-subtitle": "Wybraliśmy najciekawsze oferty",
      "text-recommended-my-product": "Zaproponuj swoje ogłoszenie",
      "text-recommended-my-product-subtitle-1": "W odpowiedzi na zapytanie możesz zaoferować jedno ze swoich ogłoszeń. Jeśli nie masz żadnego, utwórz nowe",
      "text-recommended-my-product-subtitle-2": "tutaj",
      "text-recommended-my-product-subtitle-3": "a następnie odpowiedz na zapytanie.",
      "text-recommended-my-product-result-1": "Do ogłoszenia z zapytaniem",
      "text-recommended-my-product-result-2": "dodano komentarz z Twoją ofertą",
      "text-show-message": "Pokaż komentarz",
      "text-mobile-app": "Aplikacja mobilna",
      "text-mobile-app-title": "Wypróbuj aplikację ForAquarist!",
      "text-mobile-app-subtitle": "Nowoczesny rynek sprzedaży, kupna i ogłoszeń produktów akwarystycznych zawsze pod ręką",
      "text-mobile-app-instructions": "Aplikację ForAquarist możesz pobrać pod tymi linkami",
      "text-could-interest-you": "To może Cię zainteresować",
      "text-for-aquarist": "For Aquarist",
      "text-burger-menu": "Menu",
      "text-close": "Zamknij",
      "text-clear": "Wyczyść",
      "text-or": "Lub",
      "text-allow-geolocation-here": "Zezwól na udostępnianie Twojej aktualnej lokalizacji tutaj",
      "text-allow-geolocation": "Aby wyświetlić odległość, proszę zezwolić na udostępnianie Twojej aktualnej lokalizacji",
      "text-gdpr": "RODO",
      "text-privacy-policy": "zasady ochrony danych osobowych",
      "text-login-auth-message": "Aby dodać do koszyka, proszę się zalogować lub",
      "text-login-auth-message-link-title": "zarejestruj się",
      "text-register-auth-message": "Zarejestruj się, aby móc robić zakupy, dodawać ogłoszenia i korzystać z wielu innych funkcji.",
      "text-register-succes-message": "Dziękujemy za rejestrację.<br><br>Proszę sprawdzić swoją skrzynkę e-mailową, gdzie wysłaliśmy link aktywacyjny.",
      "text-add-supplier-info": "Dodaj dodatkowe informacje kontaktowe",
      "text-offer": "Oferta",
      "text-request": "Zapotrzebowanie",
      "text-auction": "Aukcja",
      "text-added-to-cart": "Dodano do koszyka",
      "text-in-cart": "W koszyku",
      "text-minus": "minus",
      "text-plus": "plus",
      "common:text-read-more": "Czytaj więcej",
      "text-show-more": "Pokaż więcej",
      "text-show-all": "Pokaż wszystko",
      "text-previous": "Poprzednie",
      "text-next": "Następne",
      "common:text-less": "Mniej",
      "common:text-item": "przedmiotów,przedmiot,przedmioty",
      "text-item-header": "Pozycja",
      "text-quantity-header": "Ilość",
      "text-price-header": "Cena",
      "text-add-ad": "Dodaj ogłoszenie",
      "text-add-ad-hint-title": "Jeśli masz więcej do zaoferowania, utwórz więcej ogłoszeń - wtedy inni będą je lepiej szukać, szukając według kategorii. Zapisane ogłoszenie możesz nie tylko edytować, ale także łatwo je skopiować za pomocą przycisku znajdującego się w prawym górnym rogu szczegółów ogłoszenia.",
      "text-ad-name-placeholder": "Zwykle jest to nazwa polska lub łacińska lub nazwa produktu",
      "text-ad-content-placeholder": "Opis oferty, w tym informacje na temat możliwego transportu. Można także podać dodatkowe informacje dotyczące hodowli, oferowanego gatunku itp.",
      "text-add-new-ad": "Wstaw nowe ogłoszenie",
      "text-add-photo": "Dodaj zdjęcie",
      "text-add-inspiration": "Dodaj inspirację",
      "text-copy-ad": "Kopiuj ogłoszenie",
      "text-ad-delete-title": "Naprawdę usunąć ogłoszenie?",
      "text-want-to-buy": "Chcę kupić",
      "text-to-offer": "Zaoferować",
      "text-cart": "Koszyk",
      "text-shopping-cart": "Koszyk zakupowy",
      "text-add-to-favorites": "Dodaj do Ulubionych",
      "text-remove-from-favorites": "Usuń z Ulubionych",
      "text-pieces-available": "dostępnych sztuk,dostępna sztuka,dostępne sztuki",
      "text-pieces-required": "wymaganych sztuk,wymagana sztuka,wymagane sztuki",
      "text-details": "Szczegóły",
      "text-comments": "Komentarze",
      "text-order": "Zamówienie",
      "text-order-detail": "Szczegóły zamówienia",
      "text-rating": "Ocena",
      "text-added-rating": "Dodano ocenę:",
      "text-order-buyer-rating": "Twoja ocena zakupu",
      "text-order-seller-rating": "Twoja ocena zamówienia",
      "text-order-review": "Recenzja słowna",
      "text-update-order-review": "Zaktualizuj recenzję",
      "text-order-review-waiting": "Użytkownik {name} już ocenił to zamówienie. Oceń to zamówienie, a jego recenzja się pojawi.",
      "text-phone": "Telefon",
      "text-cart-date": "Data koszyka",
      "text-order-date": "Data zam.",
      "text-unfinished-cart": "Niedokończony koszyk",
      "text-unfinished-cart-details": "Szczegóły niedokończonego koszyka",
      "text-order-details": "Szczegóły zamówienia",
      "text-location": "Lokalizacja",
      "text-distance": "Dystans",
      "text-address": "Adres",
      "text-insert-my-info": "Wprowadź moje dane",
      "text-insert-billing-address": "Wprowadź adres rozliczeniowy",
      "text-insert-delivery-address": "Wprowadź adres dostawy",
      "text-insert-email-address": "Wprowadź adres e-mail",
      "text-insert-phone-number": "Wprowadź numer telefonu",
      "text-upload-highlight": "Prześlij zdjęcia",
      "text-upload-message": "kliknij lub przeciągnij.",
      "text-img-format": "PNG, JPG",
      "text-delivery-address": "Adres dostawy",
      "text-submit-order": "Złóż zamówienie",
      "text-submit-order-note-1": "Wysłanie zamówienia nie jest wiążące, ale konieczne jest, aby móc następnie omówić płatność, dostawę itp. ze sprzedawcą na czacie.",
      "text-submit-order-note-2": "Jeśli nie wyrażasz zgody, ma on możliwość odrzucenia Twojego zamówienia.",
      "text-submit-order-note-3": "Zamówienie jest wiążące dla obu stron dopiero po przyjęciu zamówienia przez Sprzedawcę.",
      "text-cancel-order": "Anuluj zamówienie",
      "text-abandonment-reason": "Powód rezygnacji",
      "placeholder-abandonment-note": "Będzie nam miło, jeśli napiszesz nam powód anulowania zamówienia.",
      "text-shipping": "Dostawa",
      "text-billing-data": "Dane rozliczeniowe",
      "text-billing-address": "Adres rozliczeniowy",
      "text-billing-and-delivery-address": "Adres rozliczeniowy i dostawy",
      "text-payment-fee": "Opłata za płatność",
      "text-delivery-fee": "Opłata za dostawę",
      "text-items-count": "Ilość przedmiotów",
      "text-total-price": "Cena całkowita",
      "text-total": "Razem",
      "text-out-stock": "Brak na stanie",
      "text-product-status-closed": "Ogłoszenie jest zakończone",
      "text-insert-image": "Wstaw zdjęcie",
      "text-insert-profile-image": "Załaduj zdjęcie profilowe",
      "text-product-unavailable-title": "Nie można wyświetlić szczegółów, oferta nie jest dostępna.",
      "text-product-unavailable-subtitle": "Ogłoszenie zostało usunięte, ukryte, produkt nie jest już dostępny lub w linku pojawił się błąd.",
      "text-product-image": "Zdjęcie",
      "text-product-image-edit": "Edycja zdjęć",
      "text-supplier-unavailable-title": "Szczegóły sprzedawcy nie mogą zostać wyświetlone.",
      "text-supplier-unavailable-subtitle": "Profil sprzedawcy został usunięty, ukryty lub wystąpił błąd w linku.",
      "text-supplier-image-edit": "Edycja zdjęć",
      "text-supplier-image-delete-title": "Naprawdę usunąć zdjęcie?",
      "text-inspiration-unavailable-title": "Szczegół nie może być wyświetlony, inspiracja nie jest dostępna.",
      "text-inspiration-unavailable-subtitle": "Inspiracja została usunięta, ukryta lub w linku wystąpił błąd.",
      "text-inspiration-edit": "Edycja inspiracji",
      "text-news-unavailable-title": "Nie można wyświetlić szczegółów, artykuł jest niedostępny.",
      "text-news-unavailable-subtitle": "Artykuł został usunięty, ukryty lub w łączu wystąpił błąd.",
      "text-reference-unavailable-title": "Szczegół nie może być wyświetlony, referencja nie jest dostępna.",
      "text-reference-unavailable-subtitle": "Referencja została usunięta, ukryta lub w linku wystąpił błąd.",
      "text-ad-edit": "Edycja ogłoszenia",
      "text-load-more": "Załaduj więcej",
      "text-availability": "Dostępność",
      "text-category": "Kategoria",
      "text-category-subtitle": "Czego szukasz?",
      "text-home": "Do domu",
      "text-user": "Użytkownik",
      "text-edit": "Edytować",
      "text-ad-title": "Nazwa ogłoszenia",
      "text-title": "Nazwa",
      "text-firstName": "Imię",
      "text-lastName": "Nazwisko",
      "text-namePrefix": "Tytuł przed nazwiskiem",
      "text-nameSuffix": "Tytuł po nazwisku",
      "text-company": "Nazwa firmy",
      "text-companyPerson": "Osoba kontaktowa",
      "text-companyNo": "REGON",
      "text-companyNo-required": "REGON jest wymagany",
      "text-companyVatNo": "NIP",
      "text-street": "Ulica",
      "text-streetNo": "Numer",
      "text-houseNo": "Nr domu",
      "text-city": "Miasto",
      "text-zip": "Kod pocztowy",
      "text-state": "Stan",
      "text-country": "Kraj",
      "text-load-from-ares": "Załaduj dane z rejestru ARES",
      "text-add-company-info": "Wprowadź dane firmy",
      "text-use-delivery-address": "Użyj adresu rozliczeniowego jako adres dostawy",
      "text-gallery": "Galeria",
      "text-gallery-info-title": "Zdjęcia przesłane do Galerii wyświetlane są w szczegółach Twojego profilu, gdzie mogą je przeglądać także inni użytkownicy.",
      "text-add-new-photo": "Dodaj nową inspirację",
      "text-new-variant": "Nowa wariant",
      "text-add-new-variant": "Dodaj nową wariantę",
      "text-add-copy-variant": "Dodaj kopię wariantu",
      "text-add-parameter": "Dodaj parametr",
      "text-parameters": "Parametry",
      "text-edit-ad": "Edytuj ogłoszenie",
      "text-edit-photo": "Edytuj inspirację",
      "text-edit-variant": "Edytuj wariant",
      "text-delete-variant": "Usuń wariant",
      "text-set-as-main-image": "Ustaw jako główne zdjęcie",
      "text-change-password": "Zmień hasło",
      "profile-sidebar-profile": "Profil",
      "profile-sidebar-settings-notifications": "Powiadomienie",
      "profile-sidebar-my-orders": "Moje zakupy",
      "profile-sidebar-my-sales": "Moje sprzedaże",
      "profile-sidebar-waiting-orders": "Oczekujące zamówienia",
      "profile-sidebar-help": "Pomoc",
      "profile-sidebar-contact": "Napisz do nas",
      "text-contact-us": "Napisz do nas",
      "password-successful": "Hasło zmienione pomyślnie",
      "password-successful-please-login": "Hasło zostało pomyślnie zmienione, możesz się zalogować",
      "error-credential-wrong": "Dane logowania są nieprawidłowe!",
      "login-helper": "Zaloguj się za pomocą adresu e-mail i hasła",
      "error-email-format": "Podaj prawidłowy adres e-mail",
      "error-email-required": "Podaj swój adres e-mail",
      "error-phone-required": "Podaj swój numer telefonu",
      "error-phone-numeric": "Podaj numer telefonu w formacie numerycznym",
      "error-password-required": "Podaj swoje hasło",
      "error-password-repeat-required": "Powtórz swoje hasło",
      "error-subject-required": "Podaj temat wiadomości",
      "error-description-required": "Opowiedz nam więcej",
      "error-ad-type-required": "Typ ogłoszenia jest wymagany",
      "error-ad-name-required": "Nazwa ogłoszenia jest wymagana",
      "error-ad-description-required": "Opis ogłoszenia jest wymagany",
      "error-ad-category-required": "Kategoria ogłoszenia jest wymagana",
      "error-ad-availability-required": "Dostępność jest wymagana",
      "error-ad-price-required": "Cena jest wymagana",
      "error-stock-count-required": "Wymagana jest liczba sztuk",
      "error-in-stock-count-required": "Dostępność to „{name}”, dlatego proszę ustawić liczbę sztuk większą niż 0.",
      "error-ad-vat-rate-required": "Stawka VAT jest wymagana",
      "error-ad-parameter-required": "Ten parametr jest wymagany",
      "error-content-image-required": "Zdjęcie jest wymagane",
      "error-content-name-required": "Nazwa zdjęcia jest wymagana",
      "text-in-progress-now": "Trwa...",
      "text-activate-account": "Aktywacja konta użytkownika",
      "text-account-activated": "Konto użytkownika zostało aktywowane",
      "text-account-not-activated": "Konto użytkownika nie zostało jeszcze aktywowane.",
      "text-activate-account-sent": "Link aktywacyjny do konta użytkownika został ponownie wysłany na e-mail.",
      "text-send-activate-account": "Wyślij link aktywacyjny e-mailem",
      "text-delete-account-title": "Usuwanie konta użytkownika",
      "text-confirm-delete-account": "Przykro nam, czy na pewno chcesz usunąć swoje konto użytkownika? Jeśli tak, wyślemy Ci link do potwierdzenia usunięcia konta na Twój adres e-mail.",
      "text-send-email-link": "Wyślij link na e-mail",
      "text-confirm-delete-account-title": "Potwierdź usunięcie konta użytkownika",
      "text-confirm-delete-account-button": "Tak, chcę usunąć moje konto użytkownika",
      "text-account-deleted": "Konto użytkownika zostało usunięte",
      "text-login": "Zaloguj się",
      "text-no-account": "Nie masz jeszcze konta użytkownika?",
      "text-already-account": "Masz już konto?",
      "text-register": "Zarejestruj się",
      "text-register-here": "Zarejestruj się tutaj",
      "text-old-password": "Stare hasło",
      "text-new-password": "Nowe hasło",
      "text-confirm-password": "Potwierdź hasło",
      "error.old-password.invalid": "Stare hasło jest nieprawidłowe",
      "error-old-password-required": "Stare hasło jest wymagane",
      "error-new-password-required": "Proszę podać nowe hasło",
      "error-confirm-password": "Proszę potwierdzić hasło",
      "error-match-passwords": "Hasła powinny być takie same",
      "error-name-required": "Proszę podać swoje imię",
      "error-surname-required": "Proszę podać swoje nazwisko",
      "forgot-password-email-helper": "Proszę wprowadzić swój e-mail rejestracyjny. Wyślemy na niego link do resetowania hasła.",
      "forgot-password-token-helper": "Wprowadź hasło, którego chcesz użyć do logowania",
      "text-check-email-for-token": "Wysłaliśmy do Ciebie e-mail z linkiem do resetowania hasła, sprawdź proszę także folder spam.",
      "text-reset-password": "Zresetuj hasło",
      "text-back": "Wstecz",
      "text-back-to": "Powrót do",
      "text-forgot-password": "Zapomniałeś hasła?",
      "text-follow-us": "Śledź nas",
      "text-questions-comments": "Pytania, komentarze lub obawy?",
      "text-questions-and-comments": "Pytania i komentarze",
      "text-name": "Imię",
      "text-surname": "Nazwisko",
      "text-password": "Hasło",
      "text-write-email": "Napisz e-mail",
      "text-email": "E-mail",
      "text-subject": "Temat",
      "text-description": "Opis",
      "text-about": "Informacje o Tobie",
      "text-about-info": "Przedstaw się innym, możesz napisać czym się zajmujesz, na jakie typy się specjalizujesz, co Cię interesuje.",
      "text-delivery": "Informacje o dostawie",
      "text-delivery-info": "Jaką formę dostawy oferujesz? Np. odbiór osobisty, paczkomaty itp.",
      "text-offered-delivery": "Oferowana dostawa",
      "text-variant-content": "Opis",
      "text-variant-discount": "Opis zniżki",
      "text-variant-package": "Opis pakowania",
      "text-use": "Użyj",
      "text-save": "Zapisz",
      "text-save-for-later": "Zapisz na później",
      "text-save-and-publish": "Zapisz i opublikuj",
      "text-submit-email": "Wyślij e-mail",
      "text-loading": "Ładowanie...",
      "common:text-loading": "Ładowanie...",
      "text-signing-out": "Wylogowanie...",
      "text-no-result-found": "Nie znaleziono wyników",
      "text-not-found": "Niestety, obecnie nic tu nie ma",
      "text-no-option-found": "Opcja nie znaleziona",
      "text-add-option": "Dodaj opcję",
      "text-no-filtered-found": "Nie znaleziono nic dla podanego filtra. Zmień filtr i spróbuj ponownie.",
      "text-no-my-ads": "Nie masz jeszcze żadnego ogłoszenia, ale możesz to łatwo naprawić.",
      "text-continue": "Kontynuuj",
      "text-continue-to-homepage": "Kontynuuj do strony głównej",
      "text-continue-in-mobile-app": "Kontynuuj w aplikacji mobilnej",
      "text-continue-shopping": "Kontynuuj zakupy",
      "text-proceed-order": "Dokończ zamówienie",
      "text-unavailable": "Niedostępne",
      "text-do-not-remove": "Nie usuwaj",
      "text-remove-item": "Usuń produkt",
      "text-remove-item-from-cart": "Usunąć \"{name}\" z koszyka?",
      "text-cancel": "Anuluj",
      "text-create-copy": "Utwórz kopię",
      "text-delete": "Usuń",
      "text-delete-account": "Usuń konto",
      "text-delete-avatar": "Usuń zdjęcie profilowe",
      "text-filter": "Filtr",
      "text-clear-filters": "Wyczyść filtr",
      "text-voice-search": "Wyszukiwanie głosowe",
      "text-sort-by": "Sortuj według",
      "text-sort-by-newest": "Od najnowszych",
      "text-sort-by-oldest": "Od najstarszych",
      "text-sort-by-cheapest": "Od najtańszych",
      "text-sort-by-expensive": "Od najdroższych",
      "text-sort-by-position": "Polecane",
      "text-your-order": "Twoje zamówienie",
      "text-no-products": "Jeszcze nic nie ma w koszyku",
      "text-search-label": "Wyszukiwanie...",
      "common:text-search-placeholder": "Napisz, czego szukasz",
      "common:text-search-ad-placeholder": "Napisz, jakiego ogłoszenia szukasz",
      "error-country-required": "Kraj jest wymagany",
      "error-city-required": "Miasto jest wymagane",
      "error-valid-city-required": "Miasto musi różnić się od hasła",
      "error-zip-required": "Kod pocztowy jest wymagany",
      "error-street-required": "Adres ulicy jest wymagany",
      "error-street-no-required": "Numer domu jest wymagany",
      "error-no-orders": "Nie złożyłeś jeszcze żadnych zamówień",
      "error-no-sales": "Nikt jeszcze nic od Ciebie nie zamówił",
      "error-no-carts": "Nikt jeszcze nic do Twojego koszyka nie dodał",
      "change-password": "Zmień hasło",
      "text-submit": "Wyślij",
      "text-show-profile": "Pokaż profil",
      "profile-update-successful": "Profil zaktualizowany pomyślnie",
      "profile-update-failed": "Aktualizacja profilu/zdjęcia nie powiodła się",
      "profile-address-update-failed": "Aktualizacja adresu w profilu nie powiodła się",
      "text-no-contact": "Nie znaleziono żadnego kontaktu",
      "text-status": "Status",
      "text-view": "Zobacz",
      "text-supplier": "Dostawca",
      "text-requester": "Zamawiający",
      "text-safe-shopping-with-us": "Bezpieczne zakupy u nas",
      "text-facebook": "Facebook",
      "text-x-twitter": "X (Twitter)",
      "text-more-info": "Więcej informacji",
      "text-price": "Cena",
      "text-price-note": "(jeśli nie wypełnisz, zostanie ustawiona Cena do uzgodnienia)",
      "text-negotiable": "Do negocjacji",
      "text-price-negotiable": "Cena do negocjacji",
      "text-some-items-have-price-negotiable": "Niektóre przedmioty mają cenę do negocjacji",
      "text-vat-rate": "Stawka VAT",
      "text-stock-count": "Liczba sztuk na stanie",
      "text-gtin": "EAN",
      "text-code-internal": "Kod wewnętrzny",
      "text-code-supplier": "Kod dostawcy",
      "text-add-more-ads-link": "Dodaj więcej ogłoszeń",
      "text-add-more-ads-title": "a na pewno ktoś się wkrótce odezwie.",
      "text-no-orders-title-1": "Nie zamówiłeś jeszcze niczego, sprawdź, czy masz coś",
      "text-no-orders-title-2": "w koszyku.",
      "text-no-orders-subtitle-1": "Lub przejrzyj",
      "text-no-orders-subtitle-2": "wszystkie ogłoszenia,",
      "text-no-orders-subtitle-3": "gdzie na pewno znajdziesz coś interesującego.",
      "text-no-order-selected": "Proszę wybrać zamówienie, które chcesz wyświetlić",
      "text-no-order-details": "Przykro nam, to zamówienie nie zostało znalezione :(",
      "text-no-unfinished-found": "Przykro nam, nie znaleziono żadnego niedokończonego koszyka :(",
      "text-no-unfinished-selected": "Proszę wybrać niedokończony koszyk, który chcesz wyświetlić",
      "text-no-unfinished-details": "Przykro nam, ten niedokończony koszyk nie został znaleziony :(",
      "heading-aquarist-main": "Społecznościowy rynek akwarystów",
      "subheading-aquarist-main": "Ogłoszenia, inspiracje, komentarze i ciekawe artykuły",
      "heading-aquarist-main-2": "Wszystko dla Twojego akwarium",
      "subheading-aquarist-main-2": "Znajdź hodowcę w swojej okolicy i rób zakupy jak w sklepie internetowym",
      "heading-aquarist-main-5": "Przeczytaj",
      "subheading-aquarist-main-5": "Nowości i ciekawe artykuły<br>ze świata akwariów",
      "heading-aquarist-category": "Oferty dla Twojego akwarium",
      "subheading-aquarist-category": "Znajdź hodowcę w swojej okolicy<br>i rób zakupy jak w sklepie internetowym",
      "heading-aquarist-ads": "Oferty dla Twojego akwarium",
      "subheading-aquarist-ads": "Znajdź hodowcę w swojej okolicy<br>i rób zakupy jak w sklepie internetowym",
      "heading-aquarist-my-ads": "Moje ogłoszenia",
      "subheading-aquarist-my-ads": "Edytuj, publikuj lub ukrywaj swoje ogłoszenia",
      "heading-aquarist-offers": "Oferty dla Twojego akwarium",
      "subheading-aquarist-offers": "Znajdź hodowcę w swojej okolicy<br>i rób zakupy jak w sklepie internetowym",
      "heading-aquarist-requests": "Zapytania ofertowe",
      "subheading-aquarist-requests": "Wpisz swoje zapytanie i poczekaj na ofertę",
      "heading-aquarist-auctions": "Aukcje",
      "subheading-aquarist-auctions": "Kupuj na aukcji i uzyskaj najlepszą cenę",
      "heading-aquarist-favorites": "Moje ulubione ogłoszenia i inspiracje",
      "subheading-aquarist-favorites": "Sprawdź swoje ulubione ogłoszenia i inspiracje",
      "heading-aquarist-inspiration": "Inspiracja",
      "subheading-aquarist-inspiration": "Pochwal się swoimi zdjęciami i inspiruj innych",
      "heading-aquarist-news": "Przeczytaj",
      "subheading-aquarist-news": "Nowości i ciekawe artykuły ze świata akwariów",
      "heading-aquarist-reference": "Referencje",
      "subheading-aquarist-reference": "Referencje użytkowników",
      "button-cancel": "Anuluj",
      "button-delete": "Usuń",
      "delete-item-confirm": "Potwierdź usunięcie tej pozycji",
      "common:text-forgot-password": "Zapomniałeś hasła?",
      "see-more": "Więcej informacji",
      "text-more": "Więcej",
      "activate-account.error.unknown": "Wystąpił błąd. Proszę spróbować później.",
      "activate-account.error.unauthorized": "Wystąpił błąd weryfikacji dostępu.",
      "activate-account.error.already-set": "Konto zostało już aktywowane za pomocą tego linku.",
      "activate-account.error.forbidden": "Aby aktywować konto, najpierw się wyloguj.",
      "activate-account.error.not-found": "Konto użytkownika nie zostało znalezione.",
      "delete-account.error.unknown": "Wystąpił błąd. Proszę spróbować później.",
      "delete-account.error.already-set": "Konto zostało już usunięte za pomocą tego linku.",
      "delete-account.error.not-found": "Konto użytkownika nie zostało znalezione.",
      "forgot-password.error.unknown": "Wystąpił błąd. Proszę spróbować ponownie.",
      "forgot-password.error.already-set": "Link do zmiany hasła został już wcześniej użyty, jeśli nie znasz swojego hasła, użyj ",
      "forgot-password.error.already-set.link.label": "Zapomniane hasło",
      "forgot-password.error.forbidden": "Zmiana hasła nie jest dozwolona dla gości.",
      "forgot-password.error.not-found": "Nie znaleziono użytkownika dla podanego e-maila.",
      "error.unauthorized": "Niedostępne dla użytkowników niezalogowanych.",
      "error.customer-detail.load": "Nie udało się załadować szczegółów użytkownika.",
      "error.register.unknown": "Wystąpił błąd. Proszę spróbować później.",
      "error.register.user-exists": "Użytkownik z tym adresem e-mail jest już zarejestrowany. Jeśli to ty i nie pamiętasz hasła, użyj ",
      "error.register.user-exists.link.label": "Zapomniane hasło",
      "error.cart-detail.load": "Ładowanie szczegółów koszyka nie powiodło się",
      "error.cart-detail.load.comments": "Ładowanie komentarzy do koszyka nie powiodło się",
      "error.cart-detail.add.comment": "Dodanie komentarza nie powiodło się",
      "error.news-detail.load": "Ładowanie szczegółów treści nie powiodło się",
      "error.reference-detail.load": "Ładowanie szczegółów treści nie powiodło się",
      "error.content-detail.load": "Ładowanie szczegółów treści nie powiodło się",
      "error.content-detail.favorite": "Zmiana ulubionych treści nie powiodła się",
      "error.content-detail.likes": "Zmiana polubień treści nie powiodła się",
      "error.content-detail.load.comments": "Ładowanie komentarzy do treści nie powiodło się",
      "error.content-detail.add.comment": "Dodanie komentarza nie powiodło się",
      "error.product-detail.load": "Ładowanie szczegółów produktu nie powiodło się",
      "error.product-detail.favorite": "Zmiana ulubionego produktu nie powiodła się",
      "error.product-detail.likes": "Zmiana polubień produktu nie powiodła się",
      "error.product-detail.load.comments": "Ładowanie komentarzy do produktu nie powiodło się",
      "error.product-detail.add.comment": "Dodanie komentarza nie powiodło się",
      "error.recommend-product.add.comment": "Dodanie oferty nie powiodło się",
      "error.product-list.load": "Ładowanie listy produktów nie powiodło się",
      "error.carts.load": "Ładowanie koszyków nie powiodło się",
      "error.cart.add.item": "Dodanie do koszyka zakupowego nie powiodło się",
      "error.cart.update.item": "Aktualizacja koszyka zakupowego nie powiodła się",
      "error.cart.remove.item": "Usunięcie z koszyka zakupowego nie powiodło się",
      "error.cart.load": "Ładowanie koszyka nie powiodło się",
      "error.cart.checkout.order": "Zakończenie zamówienia w koszyku nie powiodło się",
      "error.cart.cancel.order": "Anulowanie zamówienia w koszyku nie powiodło się",
      "error.cart.consent.false": "Proszę zaakceptować warunki handlowe",
      "error.register.consent.conditions.false": "Aby się zarejestrować, proszę zaakceptować warunki handlowe",
      "error.register.consent.privacy.false": "Aby się zarejestrować, proszę zaakceptować zasady ochrony danych osobowych",
      "error.orders.load": "Ładowanie zamówień nie powiodło się",
      "error.order-detail.load": "Ładowanie szczegółów zamówienia nie powiodło się",
      "error.supplier.create": "Tworzenie dostawcy nie powiodło się",
      "error.auth.me.load": "Ładowanie użytkownika nie powiodło się",
      "error.product.create": "Tworzenie ogłoszenia nie powiodło się",
      "error.product.update": "Zapisanie ogłoszenia nie powiodło się",
      "error.product.update.status": "Zmiana statusu produktu nie powiodła się",
      "error.variant.image.create": "Nie udało się przesłać zdjęcia do ogłoszenia",
      "error.variant.image.update": "Edycja zdjęcia z ogłoszenia nie powiodła się",
      "error.variant.image.delete": "Nie udało się usunąć zdjęcia z ogłoszenia",
      "error.inspiration.create": "Nie udało się dodać zdjęcia",
      "error.inspiration.update": "Edycja zdjęcia nie powiodła się",
      "error.inspiration.update.status": "Nie udało się zmienić statusu zdjęcia",
      "error.inspiration.delete": "Nie udało się usunąć zdjęcia",
      "error.supplier.image.update": "Edycja zdjęcia nie powiodła się",
      "error.supplier.image.delete": "Nie udało się usunąć zdjęcia",
      "error.supplier.rating.load": "Nie udało się załadować oceny sprzedawcy",
      "error.contact-form.submit": "Wysłanie formularza kontaktowego nie powiodło się",
      "error.report-ad-form.submit": "Wysłanie zgłoszenia ogłoszenia nie powiodło się",
      "error.report-content-form.submit": "Wysłanie zgłoszenia inspiracji nie powiodło się",
      "error.filter.maxKmFromHome.check": "Podaj także miasto lub kod pocztowy albo włącz udostępnianie lokalizacji.",
      "error.filter.maxKmFromLocation.check": "Podaj także miasto lub kod pocztowy albo włącz udostępnianie lokalizacji.",
      "error.filter.maxKm.check": "Podaj także miasto lub kod pocztowy albo włącz udostępnianie lokalizacji.",
      "error.geolocation.denied": "Udostępnianie bieżącej lokalizacji zostało odrzucone. Aby użyć tego filtra, należy zezwolić na udostępnianie swojej lokalizacji w przeglądarce.",
      "error.order.rating.create": "Zapisanie oceny nie powiodło się",
      "error.setting.notification.email.update": "Nie udało się zapisać ustawień e-maili z powiadomieniami",
      "success.setting.notification.email.update": "Ustawienia e-maili z powiadomieniami zostały zmodyfikowane",
      "success.geolocation.accepted": "Udostępnianie bieżącej lokalizacji zostało zezwolone",
      "success.delete-account-link.sent": "Link do usunięcia konta użytkownika został wysłany e-mailem",
      "success.cart.checkout.order": "Zamówienie w koszyku zostało utworzone",
      "success.cart.cancel.order": "Zamówienie w koszyku zostało anulowane",
      "success.product.create": "Ogłoszenie zostało utworzone",
      "success.product.update": "Ogłoszenie zostało zaktualizowane",
      "success.product.delete": "Ogłoszenie zostało usunięte",
      "success.variant.image.update": "Zdjęcie zostało zmodyfikowane",
      "success.variant.image.delete": "Zdjęcie zostało usunięte",
      "success.inspiration.create": "Inspiracja została utworzona",
      "success.inspiration.update": "Inspiracja została zaktualizowana",
      "success.inspiration.delete": "Inspiracja została usunięta",
      "success.supplier.image.update": "Zdjęcie zostało zmodyfikowane",
      "success.supplier.image.delete": "Zdjęcie zostało usunięte",
      "success.supplier.blocked.user": "Użytkownik został zablokowany",
      "success.contact-form.submit": "Wiadomość z formularza kontaktowego została wysłana",
      "success.report-ad-form.submit": "Zgłoszenie ogłoszenia zostało wysłane",
      "success.report-content-form.submit": "Zgłoszenie inspiracji zostało wysłane",
      "success.recommend-product.submit": "Produkt został zarekomendowany",
      "success.order.rating.create": "Ocena została zapisana",
      "success.order.rating.update": "Ocena została zaktualizowana",
      "success.order.status.update": "Status zamówienia został zaktualizowany",
      "text-views": "Liczba wyświetleń",
      "text-likes": "Lubię to",
      "text-unit-km": "km",
      "text-unit-pc": "szt",
      "text-add-comment": "Dodaj komentarz",
      "text-ask-or-add-comment": "Zapytaj lub dodaj komentarz do ogłoszenia",
      "text-send": "Wyślij",
      "text-agree-with": "Zgadzam się z",
      "text-agree-withs": "Zgadzam się z",
      "text-terms-privacy-policy": "zasadami ochrony danych osobowych",
      "text-terms-and-conditions": "Warunki handlowe",
      "text-with-terms-and-conditions": "warunkami handlowymi",
      "text-receiving-marketing": "komunikacją marketingową",
      "text-order-status-new": "Oczekujące na przyjęcie przez sprzedawcę",
      "text-order-status-accepted": "Zamówione",
      "text-order-status-refused": "Odrzucone",
      "text-order-status-waiting": "Oczekujące na realizację",
      "text-order-status-paid": "Zapłacone",
      "text-order-status-pickup": "Do odbioru",
      "text-order-status-processed": "Przetworzone",
      "text-order-status-returned": "Zwrócone",
      "text-order-status-sent": "Wysłane",
      "text-order-status-undelivered": "Niedostarczone",
      "text-order-status-canceled": "Anulowane",
      "text-order-status-completed": "Zakończone",
      "text-order-status-deleted": "Usunięte",
      "text-accept-order": "Przyjmij zamówienie",
      "text-accept-order-number": "Przyjmij zamówienie #{number}",
      "text-confirm-accept-order-title": "Przyjmując zamówienie, potwierdzasz, że zamówienie zostanie zrealizowane i towar zostanie wysłany zgodnie z zamówieniem lub jak ustalono z klientem.",
      "text-refuse": "Odrzuć",
      "text-refuse-order": "Odrzuć zamówienie",
      "text-refuse-order-number": "Odrzuć zamówienie #{number}",
      "text-confirm-refuse-order-title": "Czy na pewno chcesz odrzucić zamówienie?",
      "text-block-user": "Zablokuj kupującego",
      "text-block-user-name": "Zablokuj kupującego {name}",
      "text-confirm-block-user-title": "Czy na pewno chcesz zablokować kupującego?",
      "text-blocked-user": "Zablokowany użytkownik",
      "text-blocked-users": "Zablokowani użytkownicy",
      "text-blocked-no-users": "Nie zablokowałeś jeszcze żadnych użytkowników",
      "text-add-to-cart-blocked-notice": "Nie można kupować od tego sprzedawcy, ponieważ cię zablokował.",
      "text-rate-order-blocked-notice": "Nie można ocenić tego sprzedawcy, ponieważ cię zablokował.",
      "text-add-message-blocked-notice": "Nie można dodać komentarza do tego sprzedawcy, ponieważ cię zablokował.",
      "text-cart-status-unfinished": "Niedokończony",
      "common:text-unread-message": "wiadomości,wiadomość,wiadomości",
      "error-some-fields-required": "Proszę wypełnić wymagane pola",
      "text-status-draft": "Szkic",
      "text-status-waiting": "Oczekujący",
      "text-status-active": "Aktywny",
      "text-status-hidden": "Ukryty",
      "text-hide": "Ukryj",
      "text-distance-from-location": "Odległość od miejsca",
      "text-distance-name-or-zip": "nazwa miejsca lub kod pocztowy",
      "text-distance-name-or-zip-note": "Zacznij pisać i wybierz z podpowiedzi:",
      "text-distance-name-or-zip-error": "Wpisz część nazwy miejsca, a następnie wybierz z podpowiedzi.",
      "text-distance-select": "wybierz dystans",
      "text-distance-near-km": "w odległości do {number} km",
      "text-distance-from-my-location": "Odległość od twojej lokalizacji",
      "text-max-distance-in-km": "Maks. odległość w km",
      "text-notifications": "Powiadomienia",
      "text-application": "Aplikacja",
      "text-unverified-user": "Niezweryfikowany e-mail",
      "text-unverified-buyer": "Niezweryfikowany e-mail",
      "text-unverified-seller": "Niezweryfikowany e-mail",
      "text-verified-user": "Zweryfikowany e-mail",
      "text-verified-buyer": "Zweryfikowany e-mail",
      "text-verified-seller": "Zweryfikowany e-mail",
      "text-unknown-user": "Nieznany e-mail",
      "text-unknown-buyer": "Nieznany e-mail",
      "text-unknown-seller": "Nieznany e-mail",
      "text-verified-user-required-for-ad-publishing": "Aby opublikować ogłoszenie, konieczne jest aktywowanie konta użytkownika za pomocą linku z e-maila.",
      "text-save-ad-and-publish-later": "Zapisz ogłoszenie na później i opublikuj ogłoszenie po aktywacji konta użytkownika.",
      "text-one-active-cart-hint-title": "Kliknij Zakończ zamówienie i przejdź do kolejnego kroku, aby sprecyzować i wysłać zamówienie.",
      "text-more-active-carts-hint-title": "Masz wybrane produkty od wielu sprzedawców, dlatego poniżej widzisz więcej koszyków. Kliknij Zakończ zamówienie i przejdź do kolejnego kroku, aby sprecyzować i wysłać zamówienie.",
      "text-checkout-address-hint-title": "Podaj swoje dane kontaktowe do dostawy zamówienia.",
      "text-checkout-messages-hint-title": "Dodając komentarz, możesz zaproponować pożądany sposób dostawy i płatności, lub inaczej sprecyzować zamówienie lub zadać pytanie.",
      "text-orders-hint-title": "Uzgodnij z sprzedawcą sposób dostawy i płatności w konwersacji pod pozycjami zamówienia. Nie zapomnij na koniec ocenić sprzedawcy.",
      "text-orders-hint-subtitle": "Gdy sprzedawca wyśle ci nową wiadomość, wyślemy ci powiadomienie e-mailem i powiadomieniem przeglądarki.",
      "text-sales-hint-title": "Uzgodnij z kupującym sposób dostawy i płatności w konwersacji pod pozycjami zamówienia. Jeśli się zgodzisz i będziesz realizować zamówienie, kliknij przycisk Zaakceptuj zamówienie. Jeśli się nie zgadzasz, wybierz Odrzuć zamówienie. Nie zapomnij na koniec ocenić kupującego.",
      "text-sales-hint-subtitle": "Gdy kupujący napisze do ciebie nową wiadomość, wyślemy ci powiadomienie e-mailem i powiadomieniem przeglądarki.",
      "text-chat-with-seller": "Napisz do sprzedawcy",
      "text-chat-with-buyer": "Napisz do kupującego",
      "text-chat-hint-title": "Uzgodnij sposób przekazania i płatności. Po prawej stronie linii konwersacji znajduje się ikona do wstawiania zdjęcia lub twoich danych kontaktowych.",
      "text-order-list-hint-title": "Aby zaktualizować liczbę wiadomości i znacznik przeczytania, należy ponownie załadować stronę (na przykład klawiszem F5 lub ikoną odświeżania w przeglądarce).",
      "text-is-read": "Czytać",
      "text-cookie-consent-popup": "Ta strona używa plików cookie do świadczenia usług, personalizacji reklam i analizy ruchu.",
      "text-cookie-consent-button-accept": "Zezwól na wszystko",
      "text-cookie-consent-button-reject": "Odrzuć",
      "text-cookie-consent-button-configure": "Konfiguruj opcje",
      "text-cookie-consent-button-save": "Zapisz opcje",
      "text-cookie-consent-authorization-default": "Funkcjonalne (niezbędne) pliki cookie są wymagane do prawidłowego funkcjonowania stron i usług na naszych stronach internetowych.",
      "text-cookie-consent-authorization-analytics": "Analityczne pliki cookie są używane do analizy i statystyki ruchu na stronach internetowych i nie służą do identyfikacji użytkowników.",
      "text-cookie-consent-authorization-advertising": "Marketingowe pliki cookie do celowanej reklamy i marketingu rejestrują twoją wizytę na stronach internetowych i linkach, których użyłeś. Pozwala to zaoferować komunikaty marketingowe, które mogą cię zainteresować.",
      "text-rules-of-use": "Zasady użytkowania",
      "text-share": "Udział",
      "text-share-ad": "Udostępnij ogłoszenie",
      "text-copy-to-clipboard": "Skopiuj do schowka",
      "text-copied-to-clipboard": "Link skopiowany do schowka",
      "text-email-notification-settings": "Ustawienia powiadomień e-mail",
      "text-send-notifications-by-email": "Chcę otrzymywać e-maile informujące mnie o następujących zdarzeniach:",
      "text-if-seller": "Jeśli sprzedam",
      "text-if-buyer": "Jeśli zrobię zakupy",
      "text-if-other": "Ogólnie",
      "text-settings-send-eshop-order-created": "nowe zamówienie moich ogłoszonych towarów",
      "text-settings-send-eshop-cart-message-buyer": "nowa wiadomość od kupującego",
      "text-settings-send-eshop-cart-message-supplier": "nowa wiadomość od sprzedawcy",
      "text-settings-send-eshop-product-follower": "nowe ogłoszenie od śledzonego użytkownika",
      "text-settings-send-eshop-product-variant-message": "nowy komentarz do ogłoszenia",
      "text-settings-send-eshop-order-rating-created": "nowa ocena od innego użytkownika",
      "text-settings-send-eshop-product-variant-auction-created": "obserwowany użytkownik utworzył nową aukcję",
      "text-settings-send-eshop-product-variant-auction-started": "aukcja rozpoczęła się od obserwowanego użytkownika",
      "text-settings-send-eshop-product-variant-auction-new-bid": "dodano ofertę do aukcji, w której bierzesz udział",
      "text-settings-send-eshop-product-variant-auction-finished": "aukcja, którą utworzyłeś, została zakończona",
      "text-settings-send-eshop-product-variant-auction-winner": "zostałeś wybrany zwycięzcą aukcji",
      "text-follow-user": "Obserwuj użytkowników",
      "text-follow-user-hint": "Po włączeniu Śledzenia użytkownika będziesz otrzymywać powiadomienia o jego nowych ogłoszeniach.",
      "text-following-user": "Obserwujesz użytkownika",
      "text-following-users": "Obserwowani użytkownicy",
      "text-following-no-users": "Jeszcze nikogo nie obserwujesz.",
      "text-starting-bid": "Cena wywoławcza",
      "text-start-at": "Rozpocznij od",
      "text-finish-at": "Zakończ o",
      "text-auction-starting-in": "Aukcja zaczyna za",
      "text-auction-finishing-in": "Aukcja kończy za",
      "text-reserve-price": "Minimalna cena sprzedaży",
      "text-min-increment": "Minimalna podwyżka",
      "text-pick-winner-type": "Sposób wyboru zwycięzcy",
      "text-automatically": "Automatycznie",
      "text-manually": "Ręcznie",
      "error-auction-starting-bid-required": "Cena wywoławcza jest wymagana",
      "error-auction-start-at-required": "Rozpocznij o jest wymagane",
      "error-auction-finish-at-required": "Zakończ o jest wymagane",
      "error-auction-finish-at-less-than-start-at": "Data zakończenia musi być późniejsza niż data rozpoczęcia",
      "text-countdown-days": "D",
      "text-countdown-hours": "G",
      "text-countdown-minutes": "M",
      "text-countdown-seconds": "S",
      "text-auction-finished": "Aukcja zakończona",
      "text-placing-bid": "Moja oferta",
      "text-place-bid": "Złóż ofertę",
      "success.auction-bid.create": "Oferta {amount} {sign} została pomyślnie złożona",
      "error.auction-bid.create": "Nie udało się złożyć oferty {amount} {sign}",
      "error.auction-bid.minimal": "Oferta musi być wyższa niż {amount} {sign}",
      "error.auction-bid.load": "Nie udało się załadować ofert",
      "text-last-auction-bids": "Ostatnie oferty",
      "text-pick-auction-winner": "Wybór zwycięzcy aukcji",
      "text-auction-winner": "Zwycięzca aukcji",
      "text-auction-closed-duplicate-to-edit": "Aukcja została zakończona, nie można wprowadzać dalszych zmian. Użyj przycisku Kopiuj ogłoszenie w prawym górnym rogu, aby utworzyć nową aukcję.",
      "text-emoji-group-smileys-people": "Uśmiechy i ludzie",
      "text-emoji-group-animals-nature": "Zwierzęta i natura",
      "text-emoji-group-food-drink": "Jedzenie i picie",
      "text-emoji-group-activities": "Aktywności",
      "text-emoji-group-travel-places": "Podróże i miejsca",
      "text-emoji-group-objects": "Obiekty",
      "text-emoji-group-symbols": "Symbole",
      "text-emoji-group-flags": "Flagi"
    },
    "de": {
      "text-logo": "Logo",
      "text-heading": "Überschrift",
      "404-heading": "Fehlercode: {code}",
      "404-sub-heading": "Hoppla! Sieht so aus, als wäre dies keine Seite",
      "404-back-home": "Bring mich nach Hause",
      "nav-menu-category": "Kategorie",
      "nav-menu-offers": "Angebote",
      "nav-menu-requests": "Anfragen",
      "nav-menu-auctions": "Auktionen",
      "nav-menu-inspiration": "Inspiration",
      "nav-menu-news": "Magazin",
      "nav-menu-my-favorites": "Meine Favoriten",
      "nav-menu-faq": "FAQ",
      "nav-menu-contact": "Kontakt",
      "nav-home": "Start",
      "text-homepage": "Startseite",
      "text-all-ads": "Alle Anzeigen",
      "text-all-inspirations": "Alle Inspirationen",
      "text-all-news": "Alle Artikel",
      "text-faq": "Hilfe",
      "text-read-news": "Magazin",
      "text-why-submit-ad": "Warum bei uns werben",
      "text-in-progress": "In Bearbeitung...",
      "text-contact": "Kontakt",
      "user-avatar": "Benutzeravatar",
      "text-edit-profile": "Profil bearbeiten",
      "text-edit-main-image": "Hauptbild bearbeiten",
      "auth-menu-profile": "Profil bearbeiten",
      "auth-menu-settings-notifications": "Benachrichtigung",
      "auth-menu-my-orders": "Meine Bestellungen",
      "auth-menu-my-sales": "Meine Verkäufe",
      "auth-menu-waiting-orders": "Wartende Bestellungen",
      "auth-menu-my-ads": "Meine Anzeigen",
      "auth-menu-logout": "Abmelden",
      "join-button": "Beitreten",
      "text-log-in-to-show-page": "Melden Sie sich an, um den Seiteninhalt anzuzeigen",
      "change-locale": "Sprache wechseln",
      "text-fish": "Fisch",
      "text-ads": "Anzeigen",
      "text-browse-ads": "Anzeigen durchsuchen",
      "text-report-ad": "Anzeige melden",
      "text-reporting-ad": "Gemeldete Anzeige",
      "text-reporting-details": "Grund für die Meldung",
      "text-report-inspiration": "Inspiration melden",
      "text-reporting-inspiration": "Gemeldete Inspiration",
      "text-submit-report": "Schicken",
      "text-my-ads": "Meine aktiven Anzeigen",
      "text-my-offers": "Meine aktiven Angebote",
      "text-my-requests": "Meine aktiven Anfragen",
      "text-my-auctions": "Meine aktiven Auktionen",
      "text-my-inspiration": "Meine Inspiration",
      "text-my-favorites": "Meine Favoriten",
      "text-inspiration": "Inspiration",
      "text-google-play": "Google Play",
      "text-apple-store": "Apple Store",
      "text-favorite": "Lieblings",
      "text-favorite-ads": "Lieblingsanzeigen",
      "text-favorite-inspirations": "Lieblingsinspirationen",
      "text-favorite-news": "Lieblingsartikel",
      "text-latest-inspiration": "Neueste Inspiration",
      "text-latest-inspiration-subtitle": "Zeigen Sie Ihre Fotos und inspirieren Sie andere!",
      "text-latest-news": "Artikeln",
      "text-latest-news-subtitle": "Wissenswertes aus der Welt der Aquarien und Wassertiere",
      "text-latest-ads": "Neueste Anzeigen",
      "text-latest-ads-subtitle": "Tauchen Sie in Aquarien ein und sehen Sie, was es Neues bei uns gibt!",
      "text-latest-reference": "Erfahrungen unserer Nutzer",
      "text-latest-reference-subtitle": "Was sagen Aquarienbegeisterte über ForAquarist?",
      "text-recommend": "Empfehlen",
      "text-recommendation-message": "Sie könnten an der Anzeige interessiert sein:",
      "text-recommended-ads": "Empfohlene Anzeigen",
      "text-recommended-ads-subtitle": "Wir haben die interessantesten Angebote ausgewählt",
      "text-recommended-my-product": "Bieten Sie Ihr beworbenes Produkt an",
      "text-recommended-my-product-subtitle-1": "Sie können eines Ihrer Anzeigen als Antwort auf eine Anfrage anbieten. Wenn Sie keine haben, erstellen Sie eine neue",
      "text-recommended-my-product-subtitle-2": "hier",
      "text-recommended-my-product-subtitle-3": "und dann auf die Anfrage antworten.",
      "text-recommended-my-product-result-1": "Zur Anzeigenanfrage",
      "text-recommended-my-product-result-2": "wurde ein Kommentar mit Ihrem Angebot hinzugefügt",
      "text-show-message": "Kommentar anzeigen",
      "text-mobile-app": "Mobile App",
      "text-mobile-app-title": "Probieren Sie die ForAquarist-App aus!",
      "text-mobile-app-subtitle": "Ein moderner Marktplatz zum Verkaufen, Kaufen und Bewerben von Aquarien immer zur Hand",
      "text-mobile-app-instructions": "Sie können die ForAquarist-App von diesen Links herunterladen",
      "text-could-interest-you": "Könnte Sie interessieren",
      "text-for-aquarist": "For Aquarist",
      "text-search": "Suche",
      "common:text-search": "Suche",
      "text-burger-menu": "Burger-Menü",
      "text-close": "Schließen",
      "text-clear": "Löschen",
      "text-or": "Oder",
      "text-allow-geolocation-here": "Erlauben Sie hier die Freigabe Ihres aktuellen Standorts",
      "text-allow-geolocation": "Bitte aktivieren Sie die Freigabe Ihres aktuellen Standorts, um die Entfernung anzuzeigen",
      "text-gdpr": "DSGVO",
      "text-privacy-policy": "Datenschutzrichtlinie",
      "text-login-auth-message": "Um zum Warenkorb hinzuzufügen, bitte melden Sie sich an oder",
      "text-login-auth-message-link-title": "registrieren",
      "text-register-auth-message": "Um zum Warenkorb hinzuzufügen, bitte geben Sie Ihren Namen und Ihre E-Mail-Adresse ein, damit wir Sie über neue Produkte informieren können.",
      "text-register-succes-message": "Danke für Ihre Registrierung. Bitte überprüfen Sie Ihren E-Mail-Posteingang, wo wir Ihnen eine E-Mail mit einem Bestätigungslink gesendet haben.",
      "text-add-supplier-info": "Zusätzliche Kontaktinformationen hinzufügen",
      "text-offer": "Angebot",
      "text-request": "Anfrage",
      "text-auction": "Auktion",
      "text-added-to-cart": "Zum Warenkorb hinzugefügt",
      "text-in-cart": "Im Warenkorb",
      "text-minus": "minus",
      "text-plus": "plus",
      "common:text-read-more": "Mehr lesen",
      "text-show-more": "Mehr anzeigen",
      "text-show-all": "Alle anzeigen",
      "text-previous": "Vorherige",
      "text-next": "Nächste",
      "common:text-less": "Weniger",
      "common:text-item": "Artikel,Artikel,Artikel",
      "text-item-header": "Artikel",
      "text-quantity-header": "Anzahl",
      "text-price-header": "Preis",
      "text-vat-rate": "Mehrwertsteuersatz",
      "text-stock-count": "Lagerbestand",
      "text-gtin": "EAN",
      "text-code-internal": "Interner Code",
      "text-code-supplier": "Lieferantencode",
      "text-add-ad": "Anzeige hinzufügen",
      "text-add-ad-hint-title": "Wenn Sie mehr zu bieten haben, erstellen Sie mehr Anzeigen – dann werden andere sie bei der Suche nach Kategorien besser finden. Sie können die gespeicherte Anzeige nicht nur bearbeiten, sondern über den Button oben rechts im Anzeigendetail auch ganz einfach kopieren.",
      "text-ad-name-placeholder": "Meist ein deutscher oder lateinischer Name oder der Name des Produkts",
      "text-ad-content-placeholder": "Beschreibung des Angebots, einschließlich Informationen zum möglichen Transport. Sie können auch zusätzliche Informationen zur Zucht, zu den angebotenen Arten usw. bereitstellen.",
      "text-add-new-ad": "Neue Anzeige hinzufügen",
      "text-add-photo": "Foto hinzufügen",
      "text-add-inspiration": "Inspiration hinzufügen",
      "text-copy-ad": "Anzeige kopieren",
      "text-ad-delete-title": "Die Anzeige wirklich löschen?",
      "text-want-to-buy": "Möchte kaufen",
      "text-to-offer": "Anbieten",
      "text-cart": "Warenkorb",
      "text-shopping-cart": "Einkaufswagen",
      "text-add-to-favorites": "Zu Meinen Favoriten hinzufügen",
      "text-remove-from-favorites": "Aus Meinen Favoriten entfernen",
      "text-pieces-available": "Stücke verfügbar,Stück verfügbar,Stücke verfügbar",
      "text-pieces-required": "Stücke erforderlich,Stück erforderlich,Stücke erforderlich",
      "text-details": "Details",
      "text-comments": "Kommentare",
      "text-order": "Bestellung",
      "text-order-detail": "Bestelldetails",
      "text-rating": "Auswertung",
      "text-added-rating": "Hinzugefügte Bewertung:",
      "text-order-buyer-rating": "Ihre Bestellbewertung",
      "text-order-seller-rating": "Ihre Bestellbewertung",
      "text-order-review": "Textbewertung",
      "text-update-order-review": "Bewertung bearbeiten",
      "text-order-review-waiting": "Benutzer {name} hat diese Bestellung bereits bewertet. Bewerten Sie diese Bestellung und die Bewertung wird angezeigt.",
      "text-phone": "Telefon",
      "text-cart-date": "Warenkorb-Datum",
      "text-order-date": "Bestelldatum",
      "text-order-details": "Bestelldetails",
      "text-unfinished-cart": "Unfertiger Warenkorb",
      "text-unfinished-cart-details": "Details zum unfertigen Warenkorb",
      "text-location": "Lokalität",
      "text-distance": "Distanz",
      "text-address": "Adresse",
      "text-insert-my-info": "Meine Infos einfügen",
      "text-insert-billing-address": "Rechnungsadresse einfügen",
      "text-insert-delivery-address": "Lieferadresse einfügen",
      "text-insert-email-address": "Meine E-Mail-Adresse einfügen",
      "text-insert-phone-number": "Meine Telefonnummer einfügen",
      "text-upload-highlight": "Bilder hochladen",
      "text-upload-message": "durch Klicken oder Ziehen und Ablegen",
      "text-img-format": "PNG, JPG",
      "text-delivery-address": "Lieferadresse",
      "text-submit-order": "Bestellung senden",
      "text-submit-order-note-1": "Das Absenden der Bestellung ist unverbindlich, es ist jedoch erforderlich, dass Sie anschließend im Chat mit dem Verkäufer die Zahlung, Lieferung usw. besprechen können.",
      "text-submit-order-note-2": "Sollten Sie damit nicht einverstanden sein, hat er die Möglichkeit, Ihre Bestellung abzulehnen.",
      "text-submit-order-note-3": "Die Bestellung wird für beide Seiten erst mit der Annahme der Bestellung durch den Verkäufer verbindlich.",
      "text-cancel-order": "Bestellung stornieren",
      "text-abandonment-reason": "Grund für den Abbruch",
      "placeholder-abandonment-note": "Wir freuen uns, wenn Sie uns den Grund für die Stornierung der Bestellung mitteilen.",
      "text-shipping": "Versand",
      "text-billing-data": "Rechnungsdaten",
      "text-billing-address": "Rechnungsadresse",
      "text-billing-and-delivery-address": "Rechnungs- und Lieferadresse",
      "text-payment-fee": "Zahlungsgebühr",
      "text-delivery-fee": "Liefergebühr",
      "text-items-count": "Artikelanzahl",
      "text-total-price": "Gesamtpreis",
      "text-total": "Gesamt",
      "text-out-stock": "Nicht auf Lager",
      "text-product-status-closed": "Die Anzeige ist beendet",
      "text-insert-image": "Bild einfügen",
      "text-insert-profile-image": "Profilbild hochladen",
      "text-product-unavailable-title": "Die Details können nicht angezeigt werden, die Anzeige ist nicht verfügbar.",
      "text-product-unavailable-subtitle": "Die Anzeige wurde entweder gelöscht, ausgeblendet, das Produkt ist nicht mehr verfügbar oder der Link enthält einen Fehler.",
      "text-product-image": "Bild",
      "text-product-image-edit": "Bild bearbeiten",
      "text-supplier-unavailable-title": "Händlerdetails können nicht angezeigt werden.",
      "text-supplier-unavailable-subtitle": "Das Profil des Händlers wurde entweder gelöscht, verborgen oder es liegt ein Fehler im Link vor.",
      "text-supplier-image-edit": "Bild bearbeiten",
      "text-supplier-image-delete-title": "Dieses Bild wirklich löschen?",
      "text-inspiration-unavailable-title": "Detail kann nicht angezeigt werden, Inspiration ist nicht verfügbar.",
      "text-inspiration-unavailable-subtitle": "Die Inspiration wurde entweder gelöscht, ausgeblendet oder der Link enthält einen Fehler.",
      "text-inspiration-edit": "Inspiration bearbeiten",
      "text-news-unavailable-title": "Das Detail kann nicht angezeigt werden, der Artikel ist nicht verfügbar.",
      "text-news-unavailable-subtitle": "Der Artikel wurde entweder gelöscht, ausgeblendet oder der Link enthält einen Fehler.",
      "text-reference-unavailable-title": "Detail kann nicht angezeigt werden, Referenz ist nicht verfügbar.",
      "text-reference-unavailable-subtitle": "Die Referenz wurde entweder gelöscht, ausgeblendet oder der Link enthält einen Fehler.",
      "text-ad-edit": "Anzeige bearbeiten",
      "text-load-more": "Mehr laden",
      "text-availability": "Verfügbarkeit",
      "text-category": "Kategorie",
      "text-category-subtitle": "Wonach suchen Sie?",
      "text-home": "Startseite",
      "text-user": "Benutzer",
      "text-edit": "Bearbeiten",
      "text-ad-title": "Anzeigenname",
      "text-title": "Titel",
      "text-firstName": "Vorname",
      "text-lastName": "Nachname",
      "text-namePrefix": "Anrede",
      "text-nameSuffix": "Namenszusatz",
      "text-company": "Firmenname",
      "text-companyPerson": "Ansprechpartner",
      "text-companyNo": "Firmennummer",
      "text-companyVatNo": "USt-IdNr.",
      "text-street": "Straße",
      "text-streetNo": "Hausnummer",
      "text-houseNo": "Hausnummer",
      "text-city": "Stadt",
      "text-zip": "PLZ",
      "text-state": "Bundesland",
      "text-country": "Land",
      "text-load-from-ares": "Aus dem ARES-Register lesen",
      "text-add-company-info": "Firmeninformationen hinzufügen",
      "text-use-delivery-address": "Rechnungsadresse als Lieferadresse verwenden",
      "text-gallery": "Galerie",
      "text-gallery-info-title": "Die Bilder, die Sie in die Galerie hochladen, werden in den Details Ihres Profils angezeigt, wo sie auch von anderen Benutzern eingesehen werden können.",
      "error-ad-type-required": "Anzeigentyp ist erforderlich",
      "error-ad-name-required": "Anzeigenname ist erforderlich",
      "error-ad-description-required": "Anzeigenbeschreibung ist erforderlich",
      "error-ad-category-required": "Anzeigenkategorie ist erforderlich",
      "error-ad-availability-required": "Verfügbarkeit der Anzeige ist erforderlich",
      "error-ad-price-required": "Anzeigenpreis ist erforderlich",
      "error-stock-count-required": "Die Stückzahl ist erforderlich",
      "error-in-stock-count-required": "Die Verfügbarkeit lautet „{name}“, daher stellen Sie bitte die Stückzahl größer als 0 ein.",
      "error-ad-vat-rate-required": "Mehrwertsteuersatz ist erforderlich",
      "error-ad-parameter-required": "Dieser Parameter ist erforderlich",
      "error-content-image-required": "Foto ist erforderlich",
      "error-content-name-required": "Fotoname ist erforderlich",
      "text-add-new-photo": "Neues Foto hinzufügen",
      "text-new-variant": "Neue Variante",
      "text-add-new-variant": "Neue Variante hinzufügen",
      "text-add-copy-variant": "Variantenkopie hinzufügen",
      "text-add-parameter": "Parameter hinzufügen",
      "text-parameters": "Parameter",
      "text-edit-ad": "Anzeige bearbeiten",
      "text-edit-photo": "Foto bearbeiten",
      "text-edit-variant": "Variante bearbeiten",
      "text-delete-variant": "Variante löschen",
      "text-set-as-main-image": "Als Hauptbild festlegen",
      "text-change-password": "Passwort ändern",
      "profile-sidebar-profile": "Profil",
      "profile-sidebar-settings-notifications": "Benachrichtigung",
      "profile-sidebar-my-orders": "Meine Bestellungen",
      "profile-sidebar-my-sales": "Meine Verkäufe",
      "profile-sidebar-waiting-orders": "Wartende Bestellungen",
      "profile-sidebar-help": "Hilfe",
      "profile-sidebar-contact": "Kontaktiere uns",
      "profile-sidebar-account-delete": "Konto löschen",
      "text-contact-us": "Kontaktiere uns",
      "password-successful": "Passwort erfolgreich geändert",
      "password-successful-please-login": "Das Passwort wurde erfolgreich geändert, Sie können sich anmelden",
      "error-credential-wrong": "Die Anmeldeinformationen sind falsch!",
      "login-helper": "Mit Ihrer E-Mail & Passwort anmelden",
      "error-email-format": "Das Format der angegebenen E-Mail-Adresse ist nicht gültig",
      "error-email-required": "Sie müssen Ihre E-Mail-Adresse angeben",
      "error-phone-required": "Sie müssen Ihre Telefonnummer angeben",
      "error-phone-numeric": "Geben Sie die Telefonnummer numerisch ein",
      "error-password-required": "Sie müssen Ihr Passwort angeben",
      "error-subject-required": "Über welches Thema möchten Sie mit uns sprechen?",
      "error-description-required": "Erzählen Sie uns mehr darüber",
      "text-in-progress-now": "Jetzt in Bearbeitung...",
      "text-activate-account": "Benutzerkonto aktivieren",
      "text-account-activated": "Benutzerkonto wurde aktiviert",
      "text-account-not-activated": "Das Benutzerkonto wurde noch nicht aktiviert.",
      "text-activate-account-sent": "Ein Link zur Aktivierung Ihres Benutzerkontos wurde an Ihre E-Mail gesendet.",
      "text-send-activate-account": "Einen Aktivierungslink per E-Mail senden",
      "text-delete-account-title": "Benutzerkonto löschen?",
      "text-confirm-delete-account": "Es tut uns leid, sind Sie sicher, dass Sie Ihr Benutzerkonto löschen möchten? Wenn ja, werden wir Ihnen einen Link zur Bestätigung der Löschung des Benutzerkontos an Ihre E-Mail-Adresse senden.",
      "text-send-email-link": "Link an E-Mail senden",
      "text-confirm-delete-account-title": "Löschung des Benutzerkontos bestätigen",
      "text-confirm-delete-account-button": "Ja, ich möchte mein Benutzerkonto löschen",
      "text-account-deleted": "Benutzerkonto wurde gelöscht",
      "text-login": "Anmelden",
      "text-no-account": "Kein Konto vorhanden?",
      "text-already-account": "Bereits ein Konto vorhanden?",
      "text-register": "Registrieren",
      "text-register-here": "Hier registrieren",
      "text-old-password": "Altes Passwort",
      "text-new-password": "Neues Passwort",
      "text-confirm-password": "Passwort bestätigen",
      "error.old-password.invalid": "Ungültiges altes Passwort",
      "error-old-password-required": "Altes Passwort ist erforderlich!",
      "error-new-password-required": "Passwort ist erforderlich!",
      "error-confirm-password": "Bitte Passwort bestätigen!",
      "error-match-passwords": "Passwörter müssen übereinstimmen!",
      "error-name-required": "Sie müssen Ihren Namen angeben",
      "error-surname-required": "Sie müssen Ihren Nachnamen angeben",
      "forgot-password-email-helper": "Geben Sie Ihre E-Mail-Adresse ein, an die wir Ihnen einen Link zum Zurücksetzen Ihres Passworts senden werden",
      "forgot-password-token-helper": "Geben Sie das neue Passwort ein, das Sie zum Einloggen verwenden möchten",
      "text-check-email-for-token": "Klicken Sie auf den Link, den wir Ihnen per E-Mail gesendet haben",
      "text-reset-password": "Passwort zurücksetzen",
      "text-back": "Zurück",
      "text-back-to": "Zurück zu",
      "text-forgot-password": "Passwort vergessen?",
      "text-follow-us": "Folgen Sie uns",
      "text-questions-comments": "Fragen, Kommentare oder Bedenken?",
      "text-questions-and-comments": "Fragen und Kommentare",
      "text-name": "Name",
      "text-surname": "Nachname",
      "text-password": "Passwort",
      "text-write-email": "Eine E-Mail schreiben",
      "text-email": "E-Mail",
      "text-subject": "Betreff",
      "text-description": "Beschreibung",
      "text-about": "Info über Sie",
      "text-about-info": "Stellen Sie sich anderen vor, Sie können schreiben, was Sie tun, auf welche Spezies Sie sich konzentrieren, woran Sie interessiert sind.",
      "text-delivery": "Informationen über das angebotene Transportmittel",
      "text-delivery-info": "Welche Form der Übergabe oder des Versands bieten Sie an? Z.B. persönliche Lieferung, Postpaket, etc.",
      "text-offered-delivery": "Angebotene Lieferung",
      "text-variant-content": "Produktvariantenbeschreibung",
      "text-variant-discount": "Rabattbeschreibung",
      "text-variant-package": "Paketbeschreibung",
      "text-use": "Verwenden",
      "text-save": "Speichern",
      "text-save-for-later": "Für später speichern",
      "text-save-and-publish": "Speichern und veröffentlichen",
      "text-submit-email": "E-Mail einreichen",
      "text-loading": "Lädt...",
      "common:text-loading": "Lädt...",
      "text-signing-out": "Abmelden...",
      "text-no-result-found": "Kein Ergebnis gefunden",
      "text-not-found": "Entschuldigung, kein Produkt gefunden",
      "text-no-option-found": "Option nicht gefunden",
      "text-add-option": "Option hinzufügen",
      "text-no-filtered-found": "Für den angegebenen Filter wurde nichts gefunden. Passen Sie den Filter an und versuchen Sie es erneut.",
      "text-no-my-ads": "Sie haben noch keine Anzeige geschaltet, aber das können Sie leicht ändern.",
      "text-continue": "Fortsetzen",
      "text-continue-to-homepage": "Zur Startseite weitergehen",
      "text-continue-in-mobile-app": "In der mobilen Anwendung fortfahren",
      "text-continue-shopping": "Einkaufen fortsetzen",
      "text-proceed-order": "Zur Bestellung fortfahren",
      "text-unavailable": "Nicht verfügbar",
      "text-do-not-remove": "Nicht entfernen",
      "text-remove-item": "Artikel entfernen",
      "text-remove-item-from-cart": "\"{name}\" aus dem Warenkorb entfernen?",
      "text-cancel": "Abbrechen",
      "text-create-copy": "Kopie erstellen",
      "text-delete": "Löschen",
      "text-delete-account": "Konto löschen",
      "text-delete-avatar": "Profilfoto löschen",
      "text-filter": "Filter",
      "text-clear-filters": "Filter löschen",
      "text-voice-search": "Sprachsuche",
      "text-sort-by": "Sortieren nach",
      "text-sort-by-newest": "Neueste",
      "text-sort-by-oldest": "Älteste",
      "text-sort-by-cheapest": "Günstigste",
      "text-sort-by-expensive": "Teuerste",
      "text-sort-by-position": "Empfohlen",
      "text-your-order": "Ihre Bestellung",
      "text-no-products": "Es ist noch nichts im Warenkorb",
      "text-search-label": "Suchen...",
      "common:text-search-placeholder": "Suchen Sie hier Ihre Produkte",
      "common:text-search-ad-placeholder": "Schreiben Sie, welche Anzeige Sie suchen",
      "error-country-required": "Land ist erforderlich",
      "error-city-required": "Stadt ist erforderlich",
      "error-valid-city-required": "Der Ort muss sich vom Passwort unterscheiden",
      "error-zip-required": "PLZ ist erforderlich",
      "error-street-required": "Straßenadresse ist erforderlich",
      "error-street-no-required": "Straßen-Nr. ist erforderlich",
      "error-no-orders": "Sie haben noch nichts bestellt",
      "error-no-sales": "Niemand hat etwas von Ihnen bestellt",
      "error-no-carts": "Niemand hat Ihrem Warenkorb etwas hinzugefügt",
      "change-password": "Passwort ändern",
      "text-submit": "Einreichen",
      "text-show-profile": "Verkäuferprofil anzeigen",
      "profile-update-successful": "Profil erfolgreich aktualisiert",
      "profile-update-failed": "Profil-/Fotoaktualisierung fehlgeschlagen",
      "profile-address-update-failed": "Aktualisierung der Profiladresse fehlgeschlagen",
      "text-no-contact": "Kein Kontakt gefunden",
      "text-status": "Status",
      "text-view": "Ansehen",
      "text-supplier": "Lieferant",
      "text-requester": "Anforderer",
      "text-safe-shopping-with-us": "Bei uns einzukaufen ist sicher",
      "text-facebook": "Facebook",
      "text-x-twitter": "X (Twitter)",
      "text-more-info": "Mehr Infos",
      "text-price": "Preis",
      "text-price-note": "(wenn nicht ausgefüllt, wird es als verhandelbar angesehen)",
      "text-negotiable": "Verhandelbar",
      "text-price-negotiable": "Verhandelbarer Preis",
      "text-some-items-have-price-negotiable": "Einige Artikel haben einen verhandelbaren Preis",
      "text-add-more-ads-link": "Mehr Anzeigen hinzufügen",
      "text-add-more-ads-title": "und sicher wird bald jemand anrufen.",
      "text-no-orders-title-1": "Du hast noch nichts bestellt, prüfe ob du etwas",
      "text-no-orders-title-2": "in deinem Warenkorb hast.",
      "text-no-orders-subtitle-1": "Oder sieh dir",
      "text-no-orders-subtitle-2": "alle Anzeigen an,",
      "text-no-orders-subtitle-3": "wo du sicher etwas Interessantes finden wirst.",
      "text-no-order-selected": "Bitte wähle die Bestellung aus, die du ansehen möchtest",
      "text-no-order-details": "Entschuldigung, diese Bestellung wurde nicht gefunden :(",
      "text-no-unfinished-found": "Entschuldigung, keine unvollständige Bestellung gefunden :(",
      "text-no-unfinished-selected": "Bitte wähle die unvollständige Bestellung aus, die du ansehen möchtest",
      "text-no-unfinished-details": "Entschuldigung, diese unvollständige Bestellung wurde nicht gefunden :(",
      "heading-aquarist-main": "Community-Marktplatz für Aquarianer",
      "subheading-aquarist-main": "Anzeigen, Inspiration, Kommentare und interessante Artikel",
      "heading-aquarist-main-2": "Alles für Ihr Aquarium",
      "subheading-aquarist-main-2": "Finden Sie einen Züchter in Ihrer Nähe und kaufen Sie wie in einem E-Shop ein",
      "heading-aquarist-main-5": "Zu lesen",
      "subheading-aquarist-main-5": "Neuigkeiten und interessante Artikel aus der Welt der Aquarien",
      "heading-aquarist-category": "Angebote für Ihr Aquarium",
      "subheading-aquarist-category": "Finden Sie einen Züchter in Ihrer Nähe<br>und kaufen Sie wie in einem E-Shop ein",
      "heading-aquarist-ads": "Angebote für Ihr Aquarium",
      "subheading-aquarist-ads": "Finden Sie einen Züchter in Ihrer Nähe<br>und kaufen Sie wie in einem E-Shop ein",
      "heading-aquarist-my-ads": "Meine Anzeigen",
      "subheading-aquarist-my-ads": "Bearbeiten, veröffentlichen oder verbergen Sie Ihre Anzeigen",
      "heading-aquarist-offers": "Angebote für Ihr Aquarium",
      "subheading-aquarist-offers": "Finden Sie einen Züchter in Ihrer Nähe<br>und kaufen Sie wie in einem E-Shop ein",
      "heading-aquarist-requests": "Anfragen",
      "subheading-aquarist-requests": "Geben Sie Ihre Anfrage ein und warten Sie auf das Angebot",
      "heading-aquarist-auctions": "Auktionen",
      "subheading-aquarist-auctions": "Kaufen Sie in Auktionen und erhalten Sie den besten Preis",
      "heading-aquarist-favorites": "Meine Lieblingsanzeigen und Inspiration",
      "subheading-aquarist-favorites": "Schauen Sie sich Ihre Lieblingsanzeigen und Inspirationen an",
      "heading-aquarist-inspiration": "Inspiration",
      "subheading-aquarist-inspiration": "Zeigen Sie Ihre Fotos und inspirieren Sie andere",
      "heading-aquarist-news": "Zu lesen",
      "subheading-aquarist-news": "Neuigkeiten und interessante Artikel<br>aus der Welt der Aquarien",
      "heading-aquarist-reference": "Referenzen",
      "subheading-aquarist-reference": "Benutzerreferenzen",
      "button-cancel": "Abbrechen",
      "button-delete": "Löschen",
      "delete-item-confirm": "Bestätige das Löschen dieses Artikels",
      "common:text-forgot-password": "Passwort vergessen?",
      "see-more": "Mehr sehen",
      "text-more": "Mehr",
      "activate-account.error.unknown": "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.",
      "activate-account.error.unauthorized": "Ein Authentifizierungsfehler ist aufgetreten.",
      "activate-account.error.already-set": "Das Konto wurde bereits über diesen Link aktiviert.",
      "activate-account.error.forbidden": "Bitte loggen Sie sich aus, um das Konto zu aktivieren.",
      "activate-account.error.not-found": "Benutzerkonto nicht gefunden.",
      "delete-account.error.unknown": "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.",
      "delete-account.error.already-set": "Das Konto wurde bereits über diesen Link gelöscht.",
      "delete-account.error.not-found": "Benutzerkonto nicht gefunden.",
      "forgot-password.error.unknown": "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.",
      "forgot-password.error.already-set": "Der Link zum Ändern Ihres Passworts wurde bereits verwendet, wenn Sie Ihr Passwort nicht kennen, verwenden Sie ",
      "forgot-password.error.already-set.link.label": "Passwort vergessen",
      "forgot-password.error.forbidden": "Passwortänderung für Besucher nicht erlaubt.",
      "forgot-password.error.not-found": "Es wurde kein Benutzer für die angegebene E-Mail gefunden.",
      "error.unauthorized": "Nicht verfügbar für nicht eingeloggte Benutzer.",
      "error.customer-detail.load": "Benutzerdetails konnten nicht geladen werden.",
      "error.register.unknown": "Ein Fehler ist aufgetreten. Bitte versuchen Sie es ein anderes Mal.",
      "error.register.user-exists": "Der Benutzer mit dieser E-Mail ist bereits registriert. Wenn Sie das sind und Sie Ihr Passwort nicht erinnern, verwenden Sie ",
      "error.register.user-exists.link.label": "Passwort vergessen",
      "error.cart-detail.load": "Laden der Warenkorbdetails fehlgeschlagen",
      "error.cart-detail.load.comments": "Laden der Warenkorbkommentare fehlgeschlagen",
      "error.cart-detail.add.comment": "Hinzufügen des Kommentars fehlgeschlagen",
      "error.news-detail.load": "Laden der Inhaltedetails fehlgeschlagen",
      "error.reference-detail.load": "Laden der Inhaltedetails fehlgeschlagen",
      "error.content-detail.load": "Laden der Inhaltedetails fehlgeschlagen",
      "error.content-detail.likes": "Änderung des Inhaltslikes fehlgeschlagen",
      "error.content-detail.load.comments": "Laden der Inhaltkommentare fehlgeschlagen",
      "error.content-detail.add.comment": "Hinzufügen des Kommentars fehlgeschlagen",
      "error.product-detail.load": "Laden der Produktdetails fehlgeschlagen",
      "error.product-detail.favorite": "Änderung des Produktfavoriten fehlgeschlagen",
      "error.product-detail.likes": "Produktlikeänderung fehlgeschlagen",
      "error.product-detail.load.comments": "Laden der Produktkommentare fehlgeschlagen",
      "error.product-detail.add.comment": "Hinzufügen des Kommentars fehlgeschlagen",
      "error.product-list.load": "Produktliste konnte nicht geladen werden",
      "error.recommend-product.add.comment": "Hinzufügen der Empfehlung fehlgeschlagen",
      "error.carts.load": "Laden der Warenkörbe fehlgeschlagen",
      "error.cart.add.item": "Hinzufügen zum Einkaufswagen fehlgeschlagen",
      "error.cart.update.item": "Aktualisierung des Einkaufswagens fehlgeschlagen",
      "error.cart.remove.item": "Entfernen aus dem Einkaufswagen fehlgeschlagen",
      "error.cart.load": "Laden des Einkaufswagens fehlgeschlagen",
      "error.cart.checkout.order": "Checkout des Warenkorbauftrags fehlgeschlagen",
      "error.cart.cancel.order": "Stornierung des Warenkorbauftrags fehlgeschlagen",
      "error.cart.consent.false": "Bitte stimmen Sie den Allgemeinen Geschäftsbedingungen zu",
      "error.register.consent.conditions.false": "Bitte stimmen Sie den Allgemeinen Geschäftsbedingungen zu, um sich zu registrieren",
      "error.register.consent.privacy.false": "Bitte stimmen Sie der Datenschutzrichtlinie zu, um sich zu registrieren",
      "error.orders.load": "Laden der Bestellungen fehlgeschlagen",
      "error.order-detail.load": "Laden der Bestelldetails fehlgeschlagen",
      "error.supplier.create": "Lieferantenerstellung fehlgeschlagen",
      "error.auth.me.load": "Benutzerinformationen laden fehlgeschlagen",
      "error.product.create": "Produkterstellung fehlgeschlagen",
      "error.product.update": "Produktaktualisierung fehlgeschlagen",
      "error.product.update.status": "Produktstatusaktualisierung fehlgeschlagen",
      "error.product.delete": "Produktlöschung fehlgeschlagen",
      "error.variant.image.create": "Anzeigenbild konnte nicht erstellt werden",
      "error.variant.image.update": "Aktualisierung des Produktvariantenbildes fehlgeschlagen",
      "error.variant.image.delete": "Löschung des Produktvariantenbildes fehlgeschlagen",
      "error.inspiration.create": "Inspirationserstellung fehlgeschlagen",
      "error.inspiration.update": "Inspirationsaktualisierung fehlgeschlagen",
      "error.inspiration.update.status": "Inspirationsstatusaktualisierung fehlgeschlagen",
      "error.inspiration.delete": "Inspirationslöschung fehlgeschlagen",
      "error.supplier.image.update": "Lieferantenbildaktualisierung fehlgeschlagen",
      "error.supplier.image.delete": "Lieferantenbildlöschung fehlgeschlagen",
      "error.supplier.rating.load": "Verkäuferbewertung konnte nicht geladen werden",
      "error.contact-form.submit": "Senden des Kontaktformulars fehlgeschlagen",
      "error.report-ad-form.submit": "Melden eines verdächtigen Inserats fehlgeschlagen",
      "error.report-content-form.submit": "Melden einer verdächtigen Inspiration fehlgeschlagen",
      "error.filter.maxKmFromHome.check": "Bitte geben Sie auch eine Stadt oder Postleitzahl ein oder aktivieren Sie die Standortfreigabe.",
      "error.filter.maxKmFromLocation.check": "Bitte geben Sie auch eine Stadt oder Postleitzahl ein oder aktivieren Sie die Standortfreigabe.",
      "error.filter.maxKm.check": "Bitte geben Sie auch eine Stadt oder Postleitzahl ein oder aktivieren Sie die Standortfreigabe.",
      "error.geolocation.denied": "Die Standortfreigabe wurde abgelehnt. Um diesen Filter zu verwenden, müssen Sie die Standortfreigabe in Ihrem Browser erlauben.",
      "error.order.rating.create": "Erstellen der Bewertung fehlgeschlagen",
      "error.setting.notification.email.update": "Die Einstellungen für die Benachrichtigungs-E-Mail konnten nicht gespeichert werden",
      "success.setting.notification.email.update": "Die E-Mail-Benachrichtigungseinstellungen wurden geändert",
      "success.geolocation.accepted": "Die Freigabe Ihres aktuellen Standorts wurde aktiviert",
      "success.delete-account-link.sent": "Der Link zum Löschen des Benutzerkontos wurde per E-Mail gesendet",
      "success.cart.checkout.order": "Warenkorb-Bestellung erstellt",
      "success.cart.cancel.order": "Warenkorb-Bestellung storniert",
      "success.product.create": "Anzeige wurde erstellt",
      "success.product.update": "Anzeige wurde aktualisiert",
      "success.product.delete": "Anzeige wurde gelöscht",
      "success.variant.image.update": "Produktvariantenbild wurde aktualisiert",
      "success.variant.image.delete": "Produktvariantenbild wurde gelöscht",
      "success.inspiration.create": "Inspiration wurde erstellt",
      "success.inspiration.update": "Inspiration wurde aktualisiert",
      "success.inspiration.delete": "Inspiration wurde gelöscht",
      "success.supplier.image.update": "Bild des Produktanbieters wurde aktualisiert",
      "success.supplier.image.delete": "Bild des Produktanbieters wurde gelöscht",
      "success.supplier.blocked.user": "Der Benutzer wurde blockiert",
      "success.contact-form.submit": "Nachricht des Kontaktformulars wurde gesendet",
      "success.report-ad-form.submit": "Verdächtiges Inserat wurde gemeldet",
      "success.report-content-form.submit": "Verdächtige Inspiration wurde gemeldet",
      "success.recommend-product.submit": "Angebot wurde empfohlen",
      "success.order.rating.create": "Bewertung wurde gespeichert",
      "success.order.rating.update": "Bewertung wurde aktualisiert",
      "success.order.status.update": "Bestellstatus wurde aktualisiert",
      "text-likes": "Gefällt mir",
      "text-views": "Ansichten",
      "text-unit-km": "km",
      "text-unit-pc": "St",
      "text-add-comment": "Kommentar hinzufügen",
      "text-ask-or-add-comment": "Stellen Sie eine Frage oder fügen Sie einen Kommentar zur Anzeige hinzu",
      "text-send": "Senden",
      "text-agree-with": "Ich stimme zu",
      "text-agree-withs": "Ich stimme zu",
      "text-terms-privacy-policy": "Datenschutzrichtlinie",
      "text-terms-and-conditions": "Allgemeine Geschäftsbedingungen",
      "text-with-terms-and-conditions": "Allgemeinen Geschäftsbedingungen",
      "text-receiving-marketing": "Marketing erhalten",
      "text-order-status-new": "Wartet auf Annahme durch den Verkäufer",
      "text-order-status-accepted": "Bestellt",
      "text-order-status-refused": "Abgelehnt",
      "text-order-status-waiting": "Wartet auf Bearbeitung",
      "text-order-status-paid": "Bezahlt",
      "text-order-status-pickup": "Abholung",
      "text-order-status-processed": "Bearbeitet",
      "text-order-status-returned": "Zurückgegeben",
      "text-order-status-sent": "Gesendet",
      "text-order-status-undelivered": "Nicht zugestellt",
      "text-order-status-canceled": "Storniert",
      "text-order-status-completed": "Abgeschlossen",
      "text-order-status-deleted": "Gelöscht",
      "text-accept-order": "Bestellung annehmen",
      "text-accept-order-number": "Bestellung #{number} annehmen",
      "text-confirm-accept-order-title": "Durch die Annahme der Bestellung bestätigen Sie, dass Sie die Bestellung bearbeiten und die Waren wie bestellt oder wie mit dem Kunden vereinbart versenden werden.",
      "text-refuse": "Ablehnen",
      "text-refuse-order": "Bestellung ablehnen",
      "text-refuse-order-number": "Bestellung #{number} ablehnen",
      "text-confirm-refuse-order-title": "Sind Sie sicher, dass Sie die Bestellung ablehnen möchten?",
      "text-block-user": "Käufer sperren",
      "text-block-user-name": "Käufer {name} sperren",
      "text-confirm-block-user-title": "Sind Sie sicher, dass Sie den Käufer blockieren möchten?",
      "text-blocked-user": "Benutzer blockiert",
      "text-blocked-users": "Blockierte Benutzer",
      "text-blocked-no-users": "Sie haben noch keine Benutzer blockiert",
      "text-add-to-cart-blocked-notice": "Es ist nicht möglich, bei diesem Verkäufer einzukaufen, da er Sie blockiert hat.",
      "text-rate-order-blocked-notice": "Es ist nicht möglich, diesen Verkäufer zu bewerten, da er Sie blockiert hat.",
      "text-add-message-blocked-notice": "Es ist nicht möglich, diesem Verkäufer einen Kommentar hinzuzufügen, da er Sie blockiert hat.",
      "text-cart-status-unfinished": "Unfertig",
      "common:text-unread-message": "Neuigkeiten,Neuigkeit,Neuigkeiten",
      "error-some-fields-required": "Bitte füllen Sie die erforderlichen Felder aus",
      "text-status-draft": "Entwurf",
      "text-status-waiting": "Wartend",
      "text-status-active": "Aktiv",
      "text-status-hidden": "Versteckt",
      "text-hide": "Verbergen",
      "text-distance-from-location": "Entfernung vom Ort",
      "text-distance-name-or-zip": "Ortsname oder Postleitzahl",
      "text-distance-name-or-zip-note": "Beginnen Sie mit der Eingabe und wählen Sie aus den Vorschlägen:",
      "text-distance-name-or-zip-error": "Geben Sie einen Teil des Ortsnamens ein und wählen Sie dann aus den Vorschlägen.",
      "text-distance-select": "Wählen Sie den Abstand aus",
      "text-distance-near-km": "Fläche bis zu {Anzahl} km",
      "text-distance-from-my-location": "Entfernung von Ihrem Standort",
      "text-max-distance-in-km": "Max. Entfernung in km",
      "text-notifications": "Benachrichtigungen",
      "text-application": "Anwendung",
      "text-unverified-user": "Unverifizierte E-Mail",
      "text-unverified-buyer": "Unverifizierte E-Mail",
      "text-unverified-seller": "Unverifizierte E-Mail",
      "text-verified-user": "Verifizierte E-Mail",
      "text-verified-buyer": "Verifizierte E-Mail",
      "text-verified-seller": "Verifizierte E-Mail",
      "text-unknown-user": "Unbekannte E-Mail",
      "text-unknown-buyer": "Unbekannte E-Mail",
      "text-unknown-seller": "Unbekannte E-Mail",
      "text-verified-user-required-for-ad-publishing": "Um eine Anzeige zu veröffentlichen, müssen Sie Ihr Benutzerkonto mit einem Link aus Ihrer E-Mail aktivieren.",
      "text-save-ad-and-publish-later": "Speichern Sie die Anzeige für später und veröffentlichen Sie, nachdem Sie das Benutzerkonto aktiviert haben.",
      "text-one-active-cart-hint-title": "Klicken Sie auf Weiter zur Bestellung, um zum nächsten Schritt zu gelangen und Ihre Bestellung abzuschließen und einzureichen.",
      "text-more-active-carts-hint-title": "Sie haben Waren von mehreren Verkäufern ausgewählt, daher sehen Sie unten mehrere Körbe. Klicken Sie auf Weiter zur Bestellung, um zum nächsten Schritt zu gelangen und Ihre Bestellung abzuschließen und einzureichen.",
      "text-checkout-address-hint-title": "Geben Sie Ihre Kontaktdaten für die Lieferung der Bestellung ein.",
      "text-checkout-messages-hint-title": "Durch Hinzufügen eines Kommentars können Sie die gewünschte Versand- und Zahlungsmethode vorschlagen oder die Bestellung sonst spezifizieren oder eine Frage stellen.",
      "text-orders-hint-title": "Stimmen Sie im Gespräch unter den Bestellpositionen mit dem Verkäufer die Liefer- und Zahlungsmethode ab. Vergessen Sie nicht, am Ende den Verkäufer zu bewerten.",
      "text-orders-hint-subtitle": "Sobald der Verkäufer Ihnen eine neue Nachricht schreibt, senden wir Ihnen eine Benachrichtigung per E-Mail und Browser-Benachrichtigung.",
      "text-sales-hint-title": "Stimmen Sie im Gespräch unter den Bestellpositionen mit dem Käufer die Liefer- und Zahlungsmethode ab. Wenn Sie einverstanden sind und die Bestellung aufgeben, klicken Sie auf die Schaltfläche Bestellung akzeptieren. Wenn Sie nicht einverstanden sind, wählen Sie Bestellung ablehnen. Vergessen Sie nicht, am Ende den Käufer zu bewerten.",
      "text-sales-hint-subtitle": "Sobald ein Käufer Ihnen eine neue Nachricht schreibt, senden wir Ihnen eine E-Mail und Browser-Benachrichtigung.",
      "text-chat-with-seller": "Schreiben Sie dem Verkäufer",
      "text-chat-with-buyer": "Schreiben Sie dem Käufer",
      "text-chat-hint-title": "Stimmen Sie die Liefer- und Zahlungsmethode ab. Rechts von der Gesprächszeile befindet sich ein Symbol zum Einfügen eines Bildes oder Ihrer Kontaktinformationen.",
      "text-order-list-hint-title": "Um die Anzahl der Neuigkeiten und die Lesemarkierungen zu aktualisieren, müssen Sie die Seite neu laden (z. B. mit der F5-Taste oder dem Aktualisierungssymbol im Browser).",
      "text-is-read": "Lesen",
      "text-cookie-consent-popup": "Diese Website verwendet Cookies, um Dienste bereitzustellen, Anzeigen zu personalisieren und den Verkehr zu analysieren.",
      "text-cookie-consent-button-accept": "Alle erlauben",
      "text-cookie-consent-button-reject": "Ablehnen",
      "text-cookie-consent-button-configure": "Optionen einstellen",
      "text-cookie-consent-button-save": "Optionen speichern",
      "text-cookie-consent-authorization-default": "Funktionale (notwendige) Cookies sind für das ordnungsgemäße Funktionieren der Seiten und Dienste auf unserer Website notwendig.",
      "text-cookie-consent-authorization-analytics": "Analytische Cookies werden für die Analyse und Statistik des Verkehrs auf der Website verwendet und dienen nicht zur Identifizierung von Benutzern.",
      "text-cookie-consent-authorization-advertising": "Marketing-Cookies für gezielte Werbung und Marketing zeichnen Ihren Besuch auf der Website und die von Ihnen verwendeten Links auf. Dies ermöglicht es Ihnen, kommerzielle Kommunikationen anzubieten, die für Sie von Interesse sein könnten.",
      "text-rules-of-use": "Nutzungsregeln",
      "text-share": "Teilen",
      "text-share-ad": "Anzeige teilen",
      "text-copy-to-clipboard": "Kopieren in die Zwischenablage",
      "text-copied-to-clipboard": "Link in die Zwischenablage kopiert",
      "text-email-notification-settings": "E-Mail-Benachrichtigungseinstellungen",
      "text-send-notifications-by-email": "Ich möchte E-Mails erhalten, die mich über die folgenden Ereignisse informieren:",
      "text-if-seller": "Wenn ich verkaufe",
      "text-if-buyer": "Wenn ich einkaufe",
      "text-if-other": "Allgemein",
      "text-settings-send-eshop-order-created": "neubestellung meines beworbenen Artikels",
      "text-settings-send-eshop-cart-message-buyer": "neue Nachricht vom Käufer",
      "text-settings-send-eshop-cart-message-supplier": "neue Nachricht vom Verkäufer",
      "text-settings-send-eshop-product-follower": "neue Anzeige von einem verfolgten Benutzer",
      "text-settings-send-eshop-product-variant-message": "neuer Kommentar zur Anzeige",
      "text-settings-send-eshop-order-rating-created": "neue Bewertung von einem anderen Benutzer",
      "text-settings-send-eshop-product-variant-auction-created": "ein beobachteter Benutzer hat eine neue Auktion erstellt",
      "text-settings-send-eshop-product-variant-auction-started": "eine Auktion eines beobachteten Benutzers hat begonnen",
      "text-settings-send-eshop-product-variant-auction-new-bid": "ein Gebot wurde zu der Auktion hinzugefügt, an der Sie teilnehmen",
      "text-settings-send-eshop-product-variant-auction-finished": "die von Ihnen erstellte Auktion wurde abgeschlossen",
      "text-settings-send-eshop-product-variant-auction-winner": "sie wurden als Gewinner der Auktion ausgewählt",
      "text-follow-user": "Folgen Sie Benutzern",
      "text-follow-user-hint": "Nach dem Aktivieren der Benutzerverfolgung erhalten Sie Benachrichtigungen über seine neuen Anzeigen.",
      "text-following-user": "Sie folgen einem Benutzer",
      "text-following-users": "Gefolgte Benutzer",
      "text-following-no-users": "Du folgst noch niemandem.",
      "text-starting-bid": "Startgebot",
      "text-start-at": "Startzeit",
      "text-finish-at": "Endzeit",
      "text-auction-starting-in": "Auktion beginnt in",
      "text-auction-finishing-in": "Auktion endet in",
      "text-reserve-price": "Mindestverkaufspreis",
      "text-min-increment": "Mindeststeigerung",
      "text-pick-winner-type": "Gewinner auswählen",
      "text-automatically": "Automatisch",
      "text-manually": "Manuell",
      "error-auction-starting-bid-required": "Startgebot ist erforderlich",
      "error-auction-start-at-required": "Startzeit ist erforderlich",
      "error-auction-finish-at-required": "Endzeit ist erforderlich",
      "error-auction-finish-at-less-than-start-at": "Das Enddatum muss später als das Startdatum sein",
      "text-countdown-days": "T",
      "text-countdown-hours": "S",
      "text-countdown-minutes": "M",
      "text-countdown-seconds": "S",
      "text-auction-finished": "Auktion beendet",
      "text-placing-bid": "Mein Gebot",
      "text-place-bid": "Gebot abgeben",
      "success.auction-bid.create": "Gebot von {amount} {sign} wurde erfolgreich abgegeben",
      "error.auction-bid.create": "Das Gebot von {amount} {sign} konnte nicht abgegeben werden",
      "error.auction-bid.minimal": "Das Gebot muss größer als {amount} {sign} sein",
      "error.auction-bid.load": "Laden der Gebote fehlgeschlagen",
      "text-last-auction-bids": "Letzte Gebote",
      "text-pick-auction-winner": "Auswahl des Auktionsgewinners",
      "text-auction-winner": "Auktionsgewinner",
      "text-auction-closed-duplicate-to-edit": "Die Auktion ist beendet, es können keine weiteren Änderungen vorgenommen werden. Verwenden Sie die Schaltfläche Anzeige kopieren in der oberen rechten Ecke, um eine neue Auktion zu erstellen.",
      "text-emoji-group-smileys-people": "Lächeln & Menschen",
      "text-emoji-group-animals-nature": "Tiere & Natur",
      "text-emoji-group-food-drink": "Essen & Trinken",
      "text-emoji-group-activities": "Aktivitäten",
      "text-emoji-group-travel-places": "Reisen & Orte",
      "text-emoji-group-objects": "Objekte",
      "text-emoji-group-symbols": "Symbole",
      "text-emoji-group-flags": "Flaggen"
    }
  }
